import { Button, Box } from "@mui/material";
import { useAuth } from "../../hooks/useAuth"
import { useLocation, useNavigate } from "react-router-dom";

const LoginButton: React.FC = () => {
  const { authUser, authLoading } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const routesWithoutLoginButton = [
    '/signin',
    '/signin-email',
    '/verify-email',
    '/forgot-password',
    '/signup',
    '/'
  ]

  if (authUser || authLoading) return <></>

  if (routesWithoutLoginButton.includes(location.pathname)) return <></>

  const handleLogin = (event: any) => {
    event.preventDefault();

    navigate('/signin', {
      state: {
        redirectURL: location.pathname,
      }
    })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Button
        id={`button-login`}
        variant="contained"
        sx={{
          height: '32px !important',
          padding: '6px 12px !important',
          borderRadius:'6px',
          flex: 1,
          mr: 5
        }}
        fullWidth
        onClick={handleLogin}
      >
        Log In
      </Button>
    </Box>
  )
}

export default LoginButton
