import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { CONTACT_US_MAIL } from '../../config/const';

interface ContactUsModalType {
  handleContactModal: boolean;
  handleContactUsModalClose: () => void;
}

const ContactUsModal = (props: ContactUsModalType) => {
  const { handleContactModal, handleContactUsModalClose } = props;

  return (
    <Dialog
      open={Boolean(handleContactModal)}
      onClose={handleContactUsModalClose}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '340px',
          },
        },
      }}
    >
      <DialogContent>
        <Typography fontSize={'1.1rem'} fontWeight={700} mb={1}>
          {'Contact Us'}
        </Typography>

        <Typography fontSize={'1rem'} fontWeight={500} mb={1}>
          {'Reach out to support any time for help, feedback, and suggestions!'}
        </Typography>
        <Typography fontSize={'1rem'} fontWeight={500} mb={1}>
          <a
            style={{ color: 'blue' }}
            href={`mailto:${CONTACT_US_MAIL}`}
            target='_self'
          >
            {CONTACT_US_MAIL}
          </a>
        </Typography>
        <Typography fontSize={'1rem'} fontWeight={500} mb={1}>
          {'Or find your answer here:'}
        </Typography>

        <Typography fontSize={'1rem'} fontWeight={500} mb={1}>
          <a id='href-faq' style={{ color: 'blue' }} href='/faq'>
            {'Frequently Asked Questions'}
          </a>
        </Typography>

        <Box
          mt={1.2}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            id='button-close-contact-us'
            color='primary'
            variant='contained'
            type='submit'
            onClick={() => handleContactUsModalClose()}
          >
            Close
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ContactUsModal;
