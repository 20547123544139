import * as React from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useAuth } from '../../hooks/useAuth';
import CreateMimioItem from '../fanCreatorList/createMimioItem';
import UserCourseData from './userCourseData';

export default function FanProfile() {
  const { authUser, authLoading } = useAuth();

  const {
    fullName = '',
    profilePhotoURL = '',
    userName = '',
    index = '',
    shortBio = '',
  } = authUser || {};

  //create mimio feature to show/hide create my mimio section
  const createMIMIOFeature =
    process.env.REACT_APP_ENABLE_CREATE_MIMIO_FEATURE === "true";

  return (
    <Box
      flex={1}
      display='flex'
      alignItems='center'
      flexDirection='column'
      mt={3}
    >
      {authLoading ? (
        <Box
          mt={2}
          sx={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={30} />
        </Box>
      ) : (
        <Box display='flex' flexDirection='column' width='100%'>
          <Box height='100%' width='100%'>
            <Box display='flex' px={2.5}>
              <Box mt={-4}>
                <Avatar
                  src={profilePhotoURL}
                  alt={fullName}
                  sx={{
                    width: 116,
                    height: 116,
                    borderRadius: '100px',
                    border: '2px solid #fff',
                  }}
                ></Avatar>
              </Box>
            </Box>
          </Box>

          <Box width='100%' mt={1}>
            <Box
              display='flex'
              justifyContent='flex-start'
              textAlign='left'
              flexDirection='column'
              px={2.5}
            >
              <Typography component='h6' variant='h6' fontWeight={600}>
                {fullName}
              </Typography>
              <Typography
                component='h6'
                variant='body1'
                fontWeight={400}
                color={'#000'}
              >
                {`@${userName}`}
              </Typography>
              <Typography
                component='p'
                variant='body2'
                color={'#000'}
                sx={{ mt: 1 }}
              >
                {shortBio}
              </Typography>
            </Box>

            <Box width='100%' mt={2} px={2.5}>
              <Link
                to={'/update-profile'}
                style={{
                  display: 'flex',
                  cursor: 'pointer',
                }}
              >
                <Button
                  variant='outlined'
                  sx={{
                    py: 1,
                    width: '100%',
                  }}
                  disabled={!index}
                  size='small'
                  startIcon={<EditOutlinedIcon />}
                  component='div'
                >
                  Update My Information
                </Button>
              </Link>
            </Box>
            {createMIMIOFeature && (
              <Box
                width='100%'
                display='flex'
                flexDirection='column'
                alignItems='center'
                textAlign='center'
                p={3}
                position='relative'
              >
                <CreateMimioItem />
              </Box>
            )}
            <UserCourseData authUser={authUser} profileData={authUser}/>
          </Box>
        </Box>
      )}
    </Box>
  );
}
