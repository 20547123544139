import * as React from 'react';
import { SVGProps, memo } from 'react';
const BadgeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 31 32'
    {...props}
  >
    <path
      fill='#E1E1E1'
      fillRule='evenodd'
      d='M21.312 12.124a.634.634 0 0 1-.058.894l-7.55 6.645a.633.633 0 0 1-.893-.056L9.69 16.084a.634.634 0 1 1 .949-.84l2.702 3.05 7.076-6.228a.634.634 0 0 1 .895.058ZM15.5 24.896c4.98 0 9.031-4.051 9.031-9.032 0-4.98-4.051-9.03-9.031-9.03s-9.032 4.05-9.032 9.03c0 4.98 4.051 9.032 9.032 9.032Zm0-19.33c-5.68 0-10.3 4.62-10.3 10.299 0 5.678 4.62 10.299 10.3 10.299 5.678 0 10.3-4.62 10.3-10.3 0-5.679-4.622-10.299-10.3-10.299Zm13.66 13.727c2.453-1.628 2.453-5.229 0-6.857a3.631 3.631 0 0 1-1.576-3.807c.582-2.884-1.964-5.43-4.849-4.848a3.631 3.631 0 0 1-3.807-1.577C17.3-.25 13.7-.248 12.072 2.204A3.632 3.632 0 0 1 8.265 3.78C5.38 3.198 2.833 5.744 3.416 8.629a3.632 3.632 0 0 1-1.577 3.807c-2.452 1.627-2.452 5.23 0 6.857A3.632 3.632 0 0 1 3.416 23.1c-.583 2.885 1.963 5.431 4.848 4.848 1.475-.298 2.947.28 3.808 1.577 1.629 2.454 5.229 2.452 6.856 0a3.632 3.632 0 0 1 3.807-1.577c2.885.583 5.431-1.964 4.849-4.848a3.631 3.631 0 0 1 1.577-3.807Z'
      clipRule='evenodd'
    />
  </svg>
);

export default memo(BadgeIcon);
