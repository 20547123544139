import React, { createContext, useEffect, useState, memo } from 'react';
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithPopup,
  fetchSignInMethodsForEmail,
  signInWithCredential,
  FacebookAuthProvider,
  sendEmailVerification,
} from 'firebase/auth';
import {
  deleteObject,
  ref,
  uploadBytes,
  getDownloadURL,
} from 'firebase/storage';
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { firestorage, firestore, googleAuthProvider } from '../utils/firebase';
import {
  FormMode,
  UserCreateProfileFormType,
  UserFormType,
  UserSignUpFormType,
} from '../views/creatorOnBoarding/signup.types';
import { modifyUserData } from '../utils/helper';

import {
  cloneVoice,
  deleteVoice,
  updatePersona,
  uploadFacebookAuthResponse,
} from '../utils/api';
import { USER_TYPE } from '../config/const';
import useAnalyticsEventTracker from '../hooks/useAnalyticsEventTracker';
import { sendEmailWithTemplate } from '../utils/api';

const enableIntroMessages = process.env.REACT_APP_USE_INTRO_MESSAGES === 'true';

// Initialize Firebase Authentication and get a reference to the service
interface AuthCtxType {
  authUser: any;
  setAuthUser: (userData: any) => void;
  token: string | null;
  updateUserProfileData: (
    userData: UserFormType,
    formMode: FormMode
  ) => Promise<any>;
  signInWithEmailPassword: (email: string, password: string) => Promise<any>;
  checkUserExistWithUsername: (userName: string) => Promise<boolean>;
  checkUserExistWithEmail: (email: string) => Promise<boolean>;
  logoutUser: () => Promise<void>;
  handleSendResetPasswordEmail: (email: string) => Promise<void>;
  checkUserProfileActivation: (documentId: string) => Promise<any>;
  signInSignUpWithGoogle: (userData: any, messages?: any) => Promise<any>;
  signInSignUpWithFacebook: (userData: any, messages?: any) => Promise<any>;
  authLoading: boolean;
  isAnonymousUser: boolean;
  setIsAnonymousUser: (value: boolean) => void;
  anonymousChat: any;
  setAnonymousChat: (value: any) => void;
  resetAnonymousData: any;
  setResetAnonymousData: (value: any) => void;
  generateUserName: (email: string) => Promise<string>;
  createUserAccountWithEmailPassword: (
    userData: UserSignUpFormType
  ) => Promise<any>;
  updateCreatedProfileData: (
    userData: UserCreateProfileFormType,
    formMode: FormMode
  ) => Promise<any>;
  showProfileCreated: any;
  setShowProfileCreated: (value: any) => void;
  updateVerifiedStatus: (userId: string, verified: boolean) => Promise<void>;
  fetchVerifiedStatus: (userId: string) => Promise<boolean>;
  fetchAndSetAuthUserData: (userId: string, accessToken: string) => void;
}

export const AuthContext = createContext<AuthCtxType>({
  authUser: null,
  setAuthUser: () => {
    return {} as any;
  },
  token: null,
  authLoading: false,
  updateUserProfileData: async () => {
    return {} as any;
  },
  signInWithEmailPassword: async () => {
    return {} as any;
  },
  checkUserExistWithUsername: async () => {
    return {} as boolean;
  },
  checkUserExistWithEmail: async () => {
    return {} as boolean;
  },
  logoutUser: async () => {
    return;
  },
  handleSendResetPasswordEmail: () => {
    return {} as any;
  },
  checkUserProfileActivation: () => {
    return {} as any;
  },
  signInSignUpWithGoogle: () => {
    return {} as any;
  },
  signInSignUpWithFacebook: () => {
    return {} as any;
  },
  isAnonymousUser: null,
  setIsAnonymousUser: () => {
    return {} as any;
  },
  anonymousChat: null,
  setAnonymousChat: () => {
    return {} as any;
  },
  resetAnonymousData: null,
  setResetAnonymousData: () => {
    return {} as any;
  },
  generateUserName: async () => {
    return {} as string;
  },
  createUserAccountWithEmailPassword: async () => {
    return {} as any;
  },
  updateCreatedProfileData: async () => {
    return {} as any;
  },
  showProfileCreated: null,
  setShowProfileCreated: () => {
    return {} as any;
  },
  updateVerifiedStatus: async (userId: string, verified: boolean) => {
    return {} as any;
  },
  fetchVerifiedStatus: async (userId: string) => {
    return {} as any;
  },
  fetchAndSetAuthUserData: async (userId: string, accessToken: string) => {
    return {} as any;
  }
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const auth = getAuth();
  const [token, setToken] = useState<string | null>();
  const [authUser, setAuthUser] = useState<any>(null);
  const [authLoading, setAuthLoading] = useState<boolean>(true);
  const [isAnonymousUser, setIsAnonymousUser] = useState<boolean>();
  const [anonymousChat, setAnonymousChat] = useState<any>(null);
  const [resetAnonymousData, setResetAnonymousData] = useState<boolean>(false);
  const [showProfileCreated, setShowProfileCreated] = useState<boolean>(false);
  const eventTracker = useAnalyticsEventTracker();

  useEffect(() => {
    // onAuthStateChanged listener called when any changes noticed in auth provider
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        const uid = user?.uid;
        const accessToken = await user?.getIdToken();
        setToken(accessToken);
        await fetchAndSetAuthUserData(uid, accessToken);
        setAuthLoading(false);
      } else {
        // User is signed out
        setAuthLoading(false);
        setToken(null);
        setAuthUser(null);
      }
    });
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  // code commented: As per new flow we will not store chat history and streaks data when user signup/signin from chat without login
  // useEffect(() => {
  //   if (authUser && isAnonymousUser && anonymousChat) {
  //     handleAnonymousChatData();
  //   }
  // }, [authUser?.documentId, anonymousChat]); //eslint-disable-line react-hooks/exhaustive-deps

  // function used to fetch user data from userId and set into the auth state
  const fetchAndSetAuthUserData = async (
    userId: string,
    accessToken: string
  ) => {
    if (userId) {
      const docRef = doc(firestore, 'users', userId);
      const result = await getDoc(docRef);
      if (result?.data()) {
        const {
          fullName,
          userName,
          shortBio,
          email,
          profilePhoto,
          profilePicUrl,
          userType,
          index,
          last_indexed_on,
          credits,
          customerId,
          isSubscribed,
          premiumPurchases,
          beta_tester,
          subscription
        } = result?.data();
        const authUserData = {
          uid: result?.id,
          documentId: result?.id,
          fullName: fullName,
          normalizedFullName: fullName?.toLowerCase(),
          userName: userName,
          normalizedUserName: userName?.toLowerCase(),
          shortBio: shortBio,
          email: email?.toLowerCase(),
          profilePhoto: profilePhoto,
          profilePicUrl: profilePicUrl,
          userType: userType,
          index: index,
          lastIndexedOn: last_indexed_on?.toDate().toLocaleString(),
          credits: credits,
          customerId: customerId,
          isSubscribed: isSubscribed,
          premiumPurchases: premiumPurchases,
          beta_tester: beta_tester,
          subscription: subscription,
        };
        const userData = modifyUserData(authUserData);
        handleTokenChange(accessToken, userData);
      }
    }
  };

  //function used to sign in with email and password
  const signInWithEmailPassword = (
    email: string,
    password: string
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(auth, email, password)
        .then(async (userResponse) => {
          eventTracker('signin-success-email');
          setAuthLoading(false);
          //set anonymous user false if user logged in from chat screen
          if (isAnonymousUser) {
            setIsAnonymousUser(false);
          }
          // Check if welcomeEmailSent is false or not set, and then send the welcome email
        const userDocRef = doc(firestore, "users", userResponse.user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists() && userDoc.data().welcomeEmailSent === false) {
          sendEmailWithTemplate(email).then(async () => {
            console.log("Welcome email sent successfully.");
            // Don't forget to update the flag in your database to indicate the email has been sent.
            await updateDoc(userDocRef, { welcomeEmailSent: true });
        }).catch((error) => {
            console.error("Error sending welcome email:", error);
        });
        }
          resolve(userResponse?.user);
        })
        .catch((error) => {
          eventTracker('signin-failed-email');
          reject(error);
        });
    });
  };

  //function used to create a new user account and signin into the system
  const createUserAccountWithEmailPassword = (userData: UserSignUpFormType) => {
    const { email, password } = userData;

    return new Promise<void>((resolve, reject) => {
      createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          // Set anonymous user false if user logged in from chat screen
          if (isAnonymousUser) {
            setIsAnonymousUser(false);
          }

          // Include welcomeEmailSent: false in the userSubmitData object
          const userSubmitData: any = {
            email,
            userType: USER_TYPE.FOLLOWER,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
            welcomeEmailSent: false, // Add this line
            credits: [{
              remaining: 10,
              maxAmount: 10,
            }],
          };

          // Create or update the user document in Firestore
          const userDocRef = doc(firestore, "users", userCredential.user.uid);
          await setDoc(userDocRef, userSubmitData);

          if (auth?.currentUser?.emailVerified) {
            const accessToken = await userCredential.user.getIdToken();
            await fetchAndSetAuthUserData(userCredential.user.uid, accessToken);
            eventTracker('signup-success-email');
          } else {
            // If email is not verified, possibly trigger verification email flow
            eventTracker('signup-awaiting-verification-email');
            try {
              await sendEmailVerification(auth.currentUser);
            } catch (error) {
              eventTracker('Error sending verification email during account creation');
            }
          }
          resolve();
        })
        .catch(error => {
          eventTracker('Error creating user account with email and password');
          reject(error);
        });
    });
  };


  // function create the user and build user persona
  const createCreatorUserAndBuildPersonality = async (
    userData,
    userCredential,
    resolve,
    reject
  ) => {
    const userResponse = await createUpdateUserDataFromUserId(
      userData,
      userCredential?.user?.uid
    );
    // TODO : Set item to be indexed as true only if there are any elements in it
    const itemsToBeIndexed = {
      index_documents: true,
      index_webpage: true,
      index_twitter: true,
      index_yt: true,
    };
    if (
      auth?.currentUser?.emailVerified !== undefined &&
      auth.currentUser.emailVerified === false
    ) {
      try {
        await sendEmailVerification(auth.currentUser);
        eventTracker('signup-awaiting_verification-email');
      } catch (error) {
        // Handle error
        console.log('Error sending verification email during profile creation');
        console.log(error);
      }
    }

    try { //Calling the index_all api end point after all documents upload/delete is completed
      updatePersona(userCredential?.user?.uid, itemsToBeIndexed);
      eventTracker('create-user');
    } catch (e) {
      eventTracker('create-user failed');
      throw e;
    }

    if (userData?.voiceSamplePath) {
      try { // there is new voice sample
        cloneVoice(userCredential?.user?.uid);
        eventTracker('clone-voice');
      } catch (e) {
        eventTracker('clone-voice failed');
        throw e;
      }
    }

    // //Calling the index_all api end point after all documents upload/delete is completed
    // updatePersona(userCredential?.user?.uid, itemsToBeIndexed);

    if (userResponse) {
      const accessToken = await userCredential?.user?.getIdToken();
      await fetchAndSetAuthUserData(userCredential?.user?.uid, accessToken);
      resolve();
    } else {
      reject();
    }
  };

  // updateProfileData function used to update the creators profile data when a creator user create/update MIMIO profile
  const updateUserProfileData = async (
    userData: UserFormType,
    formMode: FormMode = 'create'
  ) => {
    const {
      profilePhoto,
      existingProfilePhoto,
      voiceSample,
      existingVoiceSample,
      existingProfilePicUrl,
      userDocument,
      existingUserDocument,
      deletedUserDocument,
      documentId,
      bannerPhoto,
      existingBannerPhoto,
      existingBannerUrl,
    } = userData;

    if (documentId) {
      // set indexed initial value to false
      const itemsToBeIndexed: { [key: string]: boolean } = {};
      itemsToBeIndexed['index_documents'] = false;
      itemsToBeIndexed['index_webpage'] = false;
      itemsToBeIndexed['index_twitter'] = false;

      let profilePhotoPath = existingProfilePhoto;
      let profilePicUrl = existingProfilePicUrl;
      let userDocumentsPath = existingUserDocument;
      let voiceSamplePath = existingVoiceSample;
      let bannerPhotoPath = existingBannerPhoto;
      let bannerPhotoUrl = existingBannerUrl;

      //check profile photo is uploaded or not, if uploaded save it to cloud storage and store file path in collection
      if (typeof profilePhoto === 'object' && profilePhoto?.name) {
        if (existingProfilePhoto) {
          try {
            const deletedMediaRef = ref(firestorage, existingProfilePhoto);
            await deleteObject(deletedMediaRef);
          } catch (e) {
            console.log(e);
          }
        }

        const uploadResult = await uploadProfilePhoto(documentId, profilePhoto);
        profilePhotoPath = uploadResult.profilePhotoPath;
        profilePicUrl = uploadResult.profilePicUrl;
      }

      //check banner photo is uploaded or not, if uploaded save it to cloud storage and store file path in collection
      if (typeof bannerPhoto === 'object' && bannerPhoto?.name) {
        const uploadResult = await uploadBannerPhoto(documentId, bannerPhoto);
        bannerPhotoPath = uploadResult.bannerPhotoPath;
        bannerPhotoUrl = uploadResult.bannerPhotoUrl;
      }

      if (
        (typeof voiceSample === 'object' && voiceSample?.name) ||
        voiceSample === null
      ) {
        if (existingVoiceSample) {
          // voice sample file changed, need to remove the old existing file
          try {
            const deletedMediaRef = ref(firestorage, existingVoiceSample);
            await deleteObject(deletedMediaRef);
          } catch (e) {
            console.log(e);
          }
        }
        voiceSamplePath = null;
      }
      if (typeof voiceSample === 'object' && voiceSample?.name) {
        voiceSamplePath = await uploadVoiceSample(documentId, voiceSample);
      }

      //Deleting the documents from firebase storage
      if (deletedUserDocument?.length >= 1) {
        await Promise.all<boolean>(
          deletedUserDocument.map(async (documentItem: any) => {
            try {
              const deletedMediaRef = ref(firestorage, documentItem?.path);
              await deleteObject(deletedMediaRef);
              return true;
            } catch (err) {
              console.error(err);
              return false;
            }
          })
        );
      }

      //Uploading the new documents. This also takes care of documents that are replaced.
      let documentsPath: Record<any, any>[] | boolean = [];
      if (userDocument && userDocument?.length >= 1) {
        documentsPath = await uploadUserDocuments(userDocument, documentId);
        if (!documentsPath) {
          return;
        }
      }

      //update user data from user id
      const updatedUserData: any = await updateUserDataFromUserId(
        {
          ...userData,
          profilePhotoPath,
          voiceSamplePath,
          profilePicUrl: profilePicUrl,
          documentPath: [...userDocumentsPath, ...documentsPath],
          updatedAt: serverTimestamp(),
          bannerPhotoPath,
          bannerPhotoUrl: bannerPhotoUrl,
        },
        documentId,
        formMode
      );

      // set index_twitter flag to when twitter URL is present
      if (updatedUserData?.userDocument?.length >= 1) {
        itemsToBeIndexed['index_documents'] = true;
      }
      // set index_twitter flag to when twitter URL is present
      if (updatedUserData?.twitterURL) {
        itemsToBeIndexed['index_twitter'] = true;
      }
      // set index_webpage flag to when link to content data / user web page data is present
      if (updatedUserData?.userWebPage?.length >= 1) {
        itemsToBeIndexed['index_webpage'] = true;
      }
      // Set index_yt flag when youtube_links are present
      if (updatedUserData?.youtube_links) {
        itemsToBeIndexed['index_yt'] = true;
      }

      if (formMode === 'profileComplete' || formMode === 'create') {
        itemsToBeIndexed['index_messages'] = true;
      }

      //Calling the index_all api end point after all documents upload/delete is completed
      updatePersona(documentId, itemsToBeIndexed);

      if (voiceSample) {
        // there is new voice sample
        cloneVoice(documentId);
      }
      if (voiceSample === null && existingVoiceSample) {
        try { // need to remove the existing clone voice
          eventTracker('delete-voice');
          deleteVoice(documentId)
            .then(async (resp) => {
              if (resp.status > 204) {
                eventTracker('delete-voice failed');
              }
            }).catch(() =>{
              eventTracker('delete-voice failed');
            })
        } catch (e) {
          eventTracker('delete-voice failed');
          throw e;
        }
      }

      // get the updated user data after saving from the collection and update the local auth state to set latest data
      if (updatedUserData) {
        const docRef = doc(firestore, 'users', documentId);
        const result = await getDoc(docRef);
        let authUserData = {
          uid: documentId,
          documentId: documentId,
          fullName: updatedUserData?.fullName?.trim(),
          normalizedFullName: updatedUserData?.fullName?.toLowerCase()?.trim(),
          shortBio: updatedUserData?.shortBio,
          profilePhoto: updatedUserData?.profilePhoto,
          profilePicUrl: updatedUserData?.profilePicUrl,
          profilePhotoUrl: updatedUserData?.profilePicUrl,
          voiceSample: updatedUserData?.voiceSample,
          userName: userData?.userName?.trim(),
          normalizedUserName: userData?.userName?.toLowerCase()?.trim(),
          email: userData?.email?.toLowerCase()?.trim(),
          userType: userData?.userType,
          index: userData?.index,
          occupation: userData?.occupation,
          gender: userData?.gender,
          age: userData?.age,
          birthPlace: userData?.birthPlace,
          residencePlace: userData?.residencePlace,
          introMessage: userData?.introMessage,
          lastIndexedOn: result?.data()?.last_indexed_on?.toDate().toLocaleString()
        };
        if (formMode === 'create'){
          eventTracker('creator-account-creation');
        } else if (formMode === 'update'){
          eventTracker('creator-account-update');
        }
        setAuthUser(modifyUserData(authUserData));
        return updatedUserData;
      }
    }
  };

  //function used to upload profile picture to cloud storage
  const uploadProfilePhoto = async (userId: string, profilePhoto: any) => {
    const fileExtension = profilePhoto.name.split('.').pop(); // Extracting the file extension
    const profilePhotoPath = `images/${userId}/profile_pic.${fileExtension}`;
    const profilePhotoStorageRef = ref(firestorage, profilePhotoPath);
    await uploadBytes(profilePhotoStorageRef, profilePhoto);
    // Retrieve the download URL
    const profilePicUrl = await getDownloadURL(profilePhotoStorageRef);
    return { profilePhotoPath, profilePicUrl };
  };

  //function used to upload banner picture to cloud storage
  const uploadBannerPhoto = async (userId: string, bannerPhoto: any) => {
    const fileExtension = bannerPhoto.name.split('.').pop(); // Extracting the file extension
    const bannerPhotoPath = `images/${userId}/banner_pic.${fileExtension}`;
    const bannerPhotoStorageRef = ref(firestorage, bannerPhotoPath);
    await uploadBytes(bannerPhotoStorageRef, bannerPhoto);
    // Retrieve the download URL
    const bannerPhotoUrl = await getDownloadURL(bannerPhotoStorageRef);
    return { bannerPhotoPath, bannerPhotoUrl };
  };

  //function used to upload voice samples to cloud storage
  const uploadVoiceSample = async (userId: string, voiceSample: any) => {
    const path = `files/${userId}/voices/${voiceSample.name}`;
    const voiceSampleStorageRef = ref(firestorage, path);
    await uploadBytes(voiceSampleStorageRef, voiceSample);
    return path;
  };

  // upload user selected documents to the firebase cloud and return document array with path
  const uploadUserDocuments = async (userDocument: any, userId: string) => {
    const documentsPath: Record<any, any>[] = [];
    //check user document array has data
    if (userDocument && userDocument?.length >= 1) {
      const timeStamp = Timestamp.fromDate(new Date());
      // get uploaded documents upload status from firebase
      const documentsUploadStatus = await Promise.all<boolean>(
        userDocument.map(async (documentItem: any) => {
          const documentOrgName = documentItem?.name;
          const documentPath = `files/${userId}/${new Date().getTime()}_${
            documentItem?.name
          }`;
          const documentStorageRef = ref(firestorage, documentPath);
          try {
            await uploadBytes(documentStorageRef, documentItem);
            documentsPath.push({
              name: documentOrgName,
              path: documentPath,
              uploadedAt: timeStamp,
            });
            return true;
          } catch (err) {
            console.error(err);
            return false;
          }
        })
      );
      const rejectedIndex = documentsUploadStatus.findIndex(
        (item: boolean) => !item
      );
      if (rejectedIndex !== -1) {
        return false;
      }
    }
    return documentsPath;
  };

  // create or update creator user profile data from the user id and based on form mode it will create or update the records in collection
  const createUpdateUserDataFromUserId = async (
    userData: Partial<UserFormType> & Partial<UserCreateProfileFormType>,
    userId: string,
    mode: 'create' | 'update' = 'create'
  ) => {
    const {
      fullName,
      userName,
      shortBio,
      email,
      twitterURL,
      linkedInURL,
      instagramURL,
      youtubeURL,
      tiktokURL,
      userWebPage,
      youtube_links,
      profilePhotoPath,
      profilePicUrl,
      voiceSamplePath,
      documentPath,
      anythingElse,
      createdAt,
      updatedAt,
      userType,
      occupation,
      gender,
      birthPlace,
      residencePlace,
      age,
    } = userData;

    try {
      const userFormData = {
        ...(fullName && { fullName: fullName?.trim() }),
        ...(fullName && {
          normalizedFullName: fullName?.toLowerCase()?.trim(),
        }),
        ...(userName && { userName: userName?.trim() }),
        ...(userName && {
          normalizedUserName: userName?.toLowerCase()?.trim(),
        }),
        ...(shortBio && { shortBio: shortBio }),
        ...(email && { email: email?.toLowerCase()?.trim() }),
        ...(twitterURL && { twitterURL: twitterURL }),
        ...(linkedInURL && { linkedInURL: linkedInURL }),
        ...(instagramURL && { instagramURL: instagramURL }),
        ...(youtubeURL && { youtubeURL: youtubeURL }),
        ...(tiktokURL && { tiktokURL: tiktokURL }),
        ...(userWebPage && { userWebPage: userWebPage }),
        ...(youtube_links && { youtube_links: youtube_links }),
        ...(profilePhotoPath && { profilePhoto: profilePhotoPath }),
        ...(voiceSamplePath && { voiceSample: voiceSamplePath }),
        ...(profilePicUrl && { profilePicUrl: profilePicUrl }),
        ...(documentPath && { userDocument: documentPath }),
        ...(anythingElse && { anythingElse: anythingElse }),
        ...(occupation && { occupation }),
        ...(gender && { gender }),
        ...(birthPlace && { birthPlace }),
        ...(residencePlace && { residencePlace }),
        ...(age && { age: age }),
        ...(createdAt && { createdAt: createdAt }),
        ...(updatedAt && { updatedAt: updatedAt }),
        ...(userType && { userType: userType }),
      };
      if (mode === 'update') {
        await updateDoc(doc(firestore, 'users', userId), userFormData);
      } else {
        await setDoc(doc(firestore, 'users', userId), userFormData);
      }
      return userFormData;
    } catch (error) {
      console.log('create-update user from id error', error);
    }
  };

  // update user details from user document id
  const updateUserDataFromUserId = async (
    userData: UserFormType,
    userId: string,
    formMode: FormMode
  ) => {
    const {
      fullName,
      userName,
      email,
      shortBio,
      twitterURL,
      linkedInURL,
      instagramURL,
      youtubeURL,
      tiktokURL,
      userWebPage,
      youtube_links,
      profilePhotoPath,
      voiceSamplePath,
      profilePicUrl,
      documentPath,
      anythingElse,
      updatedAt,
      userType,
      occupation,
      gender,
      birthPlace,
      residencePlace,
      age,
      bannerPhotoPath,
      bannerPhotoUrl,
    } = userData;

    try {
      let userFormData: any = {
        fullName: fullName?.trim(),
        normalizedFullName: fullName?.toLowerCase()?.trim(),
        shortBio: shortBio,
        twitterURL: twitterURL,
        linkedInURL: linkedInURL,
        instagramURL: instagramURL,
        youtubeURL: youtubeURL,
        tiktokURL: tiktokURL,
        userWebPage: userWebPage,
        youtube_links: youtube_links,
        profilePhoto: profilePhotoPath,
        voiceSample: voiceSamplePath,
        profilePicUrl: profilePicUrl,
        userDocument: documentPath,
        anythingElse: anythingElse,
        updatedAt: updatedAt,
        userType: userType,
        occupation,
        gender,
        birthPlace,
        residencePlace,
        age: age,
        bannerPhoto: bannerPhotoPath,
        bannerPhotoUrl: bannerPhotoUrl,
      };

      if (enableIntroMessages && userData?.introMessage){
        userFormData.introMessage = userData?.introMessage;
      }

      if (formMode === 'profileComplete' || formMode === 'create') {
        userFormData = {
          userName: userName,
          normalizedUserName: userName?.toLowerCase(),
          ...userFormData,
        };
      }
      await updateDoc(doc(firestore, 'users', userId), userFormData);
      if (formMode === 'profileComplete' || formMode === 'create') {
        userFormData = {
          email: email?.toLowerCase(),
          ...userFormData,
        };
      }
      return userFormData;
    } catch (error) {
      console.log(error);
    }
  };

  // check user exists with username from provided username
  const checkUserExistWithUsername = async (userName: string) => {
    const result = await getDocs(
      query(
        collection(firestore, 'users'),
        where('normalizedUserName', '==', userName.toLocaleLowerCase())
      )
    );
    if (result?.docs.length >= 1) {
      return true;
    }
    return false;
  };

  // check user exists with email from provided email
  const checkUserExistWithEmail = async (email: string) => {
    const result = await getDocs(
      query(
        collection(firestore, 'users'),
        where('email', '==', email.toLowerCase())
      )
    );
    if (result?.docs.length >= 1) {
      return true;
    }
    return false;
  };

  // on token change call the function to update auth user state
  const handleTokenChange = (authToken: string, authUserData: any) => {
    if (authToken) {
      setToken(authToken);
      setAuthUser(authUserData);
    } else {
      setToken(null);
      setAuthUser(null);
    }
  };

  // logout/expire the local and firebase user session
  const logoutUser = () => {
    return new Promise<void>((resolve, reject) => {
      signOut(auth)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // send reset password link on email to the provided email if that user registered with email/password auth method only
  const handleSendResetPasswordEmail = (email: string) => {
    return new Promise<void>((resolve, reject) => {
      fetchSignInMethodsForEmail(auth, email)
        .then((response) => {
          if (!response || response.length === 0) {
            reject({
              code: 'auth/user-not-found',
            });
          }
          if (response.includes('password')) {
            sendPasswordResetEmail(auth, email, { url: window.location.origin }) //url: redirect url after password reset
              .then(resolve)
              .catch(reject);
          } else {
            reject({
              code: 'custom-error/user-auth-not-allowed-reset-password',
            });
          }
        })
        .catch(reject);
    });
  };

  // check creator linked with fan or not
  const checkUserProfileActivation = async (documentId: string) => {
    return onSnapshot(
      doc(firestore, 'users', documentId),
      (snapshot) => {
        const userData = snapshot.data();
        const { index } = userData || {};
        if (userData && authUser?.index !== index) {
          const updatedUserData = {
            ...authUser,
            index: index,
          };
          //show a dialog box once the profile is created
          if ((!authUser?.index || authUser?.index === undefined) && index) {
            setShowProfileCreated(true);
          }
          handleTokenChange(token, updatedUserData);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const generateUserName = async (email: string) => {
    let userName = email.slice(0, email.indexOf('@'));
    while (await checkUserExistWithUsername(userName)) {
      userName = userName + Math.floor(Math.random() * 10) + 1;
    }

    return userName;
  };

  // function used to fetch and upload google profile photo to firebase cloud storage and return profile photo path once uploaded
  const uploadImageFromUrl = async (photoURL: string, userId: string) => {
    const defaultReturn = { profilePhotoPath: '', profilePicUrl: '' };
    try {
      const imgData = await fetch(photoURL);
      if (!imgData) return defaultReturn;

      const imgBlob = await imgData.blob();
      if (!imgBlob) return defaultReturn;

      const fileExtension = imgBlob?.type?.split('/').pop(); // Extracting the file extension
      const profilePhotoPath = `images/${userId}/profile_pic.${fileExtension}`;
      const profilePhotoStorageRef = ref(firestorage, profilePhotoPath);
      await uploadBytes(profilePhotoStorageRef, imgBlob);

      // Retrieve the download URL
      const profilePicUrl = await getDownloadURL(profilePhotoStorageRef);
      return { profilePhotoPath, profilePicUrl };
    } catch (err) {
      console.log(err);
      return defaultReturn;
    }
  };

  // open signin with google popup with proceed login with google
  const signInSignUpWithGoogle = (userData: any): Promise<any> => {
    return new Promise<void>((resolve, reject) => {
      signInWithPopup(auth, googleAuthProvider)
        .then(async (result) => {
          const { displayName, email, photoURL, uid: userId } = result.user;
          const userDocRef = doc(firestore, "users", userId);
          const userDocSnap = await getDoc(userDocRef);

          if (!userDocSnap.exists()) {
            // New user logic
            let userDataToUpdate = {
              fullName: displayName,
              normalizedFullName: displayName?.toLowerCase(),
              email: email,
              createdAt: serverTimestamp(),
              updatedAt: serverTimestamp(),
              welcomeEmailSent: true, // Marking that welcome email is sent
              ...userData,
              credits: [{
                remaining: 10,
                maxAmount: 10,
              }],
            };

            // Handle profile picture from Google
            let profileUrl = photoURL ? await uploadImageFromUrl(photoURL, userId) : { profilePhotoPath: '', profilePicUrl: '' };
            userDataToUpdate.profilePhoto = profileUrl.profilePhotoPath;
            userDataToUpdate.profilePhotoPath = profileUrl.profilePhotoPath;
            userDataToUpdate.profilePicUrl = profileUrl.profilePicUrl;

            // Create user document in Firestore
            await setDoc(userDocRef, userDataToUpdate);

            // Send welcome email
            sendEmailWithTemplate(email).then(() => {
              console.log("Welcome email sent successfully to", email);
            }).catch((error) => {
              console.error("Error sending welcome email:", error);
            });

            setAuthUser(modifyUserData(userDataToUpdate));
            eventTracker('signup-success-google');
            window.location.reload()
          } else {
            // Existing user logic
            // No need to send welcome email or update welcomeEmailSent field
            eventTracker('signup-success-google');
          }
          resolve();
        })
        .catch(err => {
          eventTracker('signin-failed-google');
          reject(err);
        });
    });
  };

  useEffect(() => {
    // Initializing Facebook SDK
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: process.env.REACT_APP_GRAPH_VERSION,
      });
    };

    (function (d: Document, s: string, id: string) {
      const element = d.getElementsByTagName(s)[0];
      const fjs = element as Element;
      let js = element as any;
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode!.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }, [auth]);

  // Function to check Facebook login status
  const checkFacebookLoginStatus = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      window.FB.getLoginStatus((response) => {
        console.log(response);
        if (response.status === 'connected') {
          // User is already logged into Facebook, proceed to Firebase authentication
          const { accessToken } = response.authResponse;

          // Sign in to Firebase using the Facebook access token
          signInWithCredential(
            auth,
            FacebookAuthProvider.credential(accessToken)
          )
            .then(async (userCredential) => {
              const user = userCredential.user;
              console.log('Successfully signed in with Facebook:', user);
              resolve();
            })
            .catch(reject);
        } else {
          resolve(); // Resolve when not connected or user cancels login
        }
      });
    });
  };

  const signInSignUpWithFacebook = (
    userData: any,
    messages: any
  ): Promise<any> => {
    return new Promise<void>((resolve, reject) => {
      checkFacebookLoginStatus();

      // Initiate Facebook login
      window.FB.login(
        (response) => {
          if (response.authResponse) {
            console.log(response);
            // User is logged in with Facebook, now authenticate with Firebase
            const { accessToken } = response.authResponse;

            // Sign in to Firebase using the Facebook access token
            signInWithCredential(
              auth,
              FacebookAuthProvider.credential(accessToken)
            )
              .then(async (userCredential) => {
                const user = userCredential.user;
                console.log('Successfully signed in with Facebook: ', user);

                // Send Facebook accesss token and other data to server
                const { displayName, email, uid } = user;
                try {
                  uploadFacebookAuthResponse({
                    ...response.authResponse,
                    since: null,
                    until: null,
                    displayName: displayName,
                    email: email,
                    uid: uid,
                  });
                  eventTracker('sign-in-with-facebook');
                } catch (e) {
                  eventTracker('sign-in-with-facebook failed');
                  throw e;
                }

                const isEmailExists = await checkUserExistWithEmail(email);
                if (!isEmailExists) {
                  if (userData?.userType === USER_TYPE.FOLLOWER) {
                    const userName = await generateUserName(email);
                    userData = {
                      ...userData,
                      userName: userName,
                      normalizedUserName: userName?.toLowerCase(),
                    };
                  }
                  userData = {
                    fullName: displayName,
                    normalizedFullName: displayName?.toLowerCase(),
                    email: email,
                    createdAt: serverTimestamp(),
                    updatedAt: serverTimestamp(),
                    facebookAuthorised: true,
                    ...userData,
                  };
                  createCreatorUserAndBuildPersonality(
                    userData,
                    userCredential,
                    resolve,
                    reject
                  );
                }
              })
              .catch(reject);
          } else {
            // User canceled Facebook login
            console.log('Facebook login canceled by user');
            reject();
          }
        },
        {
          scope:
            'public_profile,email,pages_show_list,pages_read_engagement,pages_read_user_content,instagram_basic,pages_manage_metadata,instagram_manage_comments,instagram_manage_insights',
        }
      );

      // Once Facebok permissions resolved we can use method similar to google sign in
    });
  };

  // code commented: As per new flow we will not store chat history and streaks data when user signup/signin from chat without login
  // store chat and manage streaks when any anonymous user signin/signup from chat screen
  // const handleAnonymousChatData = async () => {
  //   if (anonymousChat?.messages) {
  //     try {
  //       anonymousChat?.messages?.forEach(async (messageItem) => {
  //         const {
  //           direction,
  //           likeDislike,
  //           message,
  //           sentTime,
  //           userType,
  //           feedback = '',
  //         } = messageItem;
  //         const tempMsgData = {
  //           ...(userType !== 'bot'
  //             ? {
  //                 senderId: authUser?.documentId,
  //                 userType: authUser?.userType,
  //               }
  //             : {
  //                 senderId: anonymousChat?.creatorId,
  //                 userType: userType,
  //               }),
  //           ...(anonymousChat?.creatorId === authUser?.documentId &&
  //           authUser.userType === USER_TYPE.CREATOR
  //             ? {
  //                 roomId: `${authUser?.documentId}.${USER_TYPE.BOT}`,
  //               }
  //             : {
  //                 roomId: `${anonymousChat?.creatorId}.${authUser?.documentId}`,
  //               }),
  //           direction,
  //           likeDislike,
  //           message,
  //           sentTime,
  //           feedback,
  //         };
  //         // await saveMessagesData(tempMsgData);
  //       });
  //       // if (anonymousChat?.creatorId !== authUser?.documentId) {
  //       //   await handleUserStreaks(
  //       //     authUser?.documentId,
  //       //     anonymousChat?.creatorId,
  //       //     null
  //       //   );
  //       // }
  //       setResetAnonymousData(true);
  //     } catch (error) {
  //       console.log('error saving messages data', error);
  //     }
  //   }
  // };


  const updateVerifiedStatus = async (userId: string, verified: boolean) => {
    const userDocRef = doc(firestore, 'users', userId);
    try {
      await updateDoc(userDocRef, { verified });
      // Update local authUser state with new verified status
      setAuthUser((currentUser) => ({ ...currentUser, verified }));
    } catch (error) {
      console.error('Error updating verified status:', error);
    }
  };

  const fetchVerifiedStatus = async (userId) => {
    const userDocRef = doc(firestore, "users", userId);
    const docSnap = await getDoc(userDocRef);
    if (docSnap.exists()) {
      const userData = docSnap.data();
      // Return true if the verified field doesn't exist or if it's true
      return userData.verified !== false;
    }
    return true; // Default to true if document doesn't exist
  };

  const updateCreatedProfileData = async (
    userData: UserCreateProfileFormType,
    formMode: FormMode,
  ) => {
    const { documentId, profilePhoto } = userData;

    if (documentId) {
      let profilePhotoPath = '';
      let profilePicUrl = '';

      if (typeof profilePhoto === 'object' && profilePhoto?.name) {
        const uploadResult = await uploadProfilePhoto(documentId, profilePhoto);
        profilePhotoPath = uploadResult.profilePhotoPath;
        profilePicUrl = uploadResult.profilePicUrl;
      }

      const updatedUserData: any = await createUpdateUserDataFromUserId(
        {
          ...userData,
          profilePhotoPath,
          profilePicUrl: profilePicUrl,
          updatedAt: serverTimestamp(),
        },
        documentId,
        'update'
      );

      if (updatedUserData) {
        const authUserData = {
          ...authUser,
          ...updatedUserData,
        };
        if (formMode === 'create'){
          eventTracker('fan-account-creation');
        } else if (formMode === 'update'){
          eventTracker('fan-account-update');
        }
        setAuthUser(modifyUserData(authUserData));
        return updatedUserData;
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{
        signInWithEmailPassword,
        checkUserExistWithUsername,
        checkUserExistWithEmail,
        logoutUser,
        setAuthUser,
        handleSendResetPasswordEmail,
        checkUserProfileActivation,
        signInSignUpWithGoogle,
        signInSignUpWithFacebook,
        token,
        authUser,
        authLoading,
        updateUserProfileData,
        isAnonymousUser,
        setIsAnonymousUser,
        anonymousChat,
        setAnonymousChat,
        resetAnonymousData,
        setResetAnonymousData,
        generateUserName,
        createUserAccountWithEmailPassword,
        updateCreatedProfileData,
        showProfileCreated,
        setShowProfileCreated,
        updateVerifiedStatus,
        fetchVerifiedStatus,
        fetchAndSetAuthUserData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default memo(AuthProvider);
