import * as React from 'react';
import { SVGProps, memo } from 'react';
const VideoChatIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1_24_5769" fill="white">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0.766602C0.895431 0.766602 0 1.66203 0 2.7666V12.2326C0 13.3372 0.895429 14.2326 2 14.2326H11.466C12.5706 14.2326 13.466 13.3372 13.466 12.2326V10.0878L16.1977 11.9727C17.5244 12.8881 19.3335 11.9384 19.3335 10.3265L19.3335 4.67268C19.3335 3.06078 17.5244 2.11108 16.1977 3.02652L13.466 4.91138V2.7666C13.466 1.66203 12.5706 0.766602 11.466 0.766602H2Z"/>
        </mask>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0.766602C0.895431 0.766602 0 1.66203 0 2.7666V12.2326C0 13.3372 0.895429 14.2326 2 14.2326H11.466C12.5706 14.2326 13.466 13.3372 13.466 12.2326V10.0878L16.1977 11.9727C17.5244 12.8881 19.3335 11.9384 19.3335 10.3265L19.3335 4.67268C19.3335 3.06078 17.5244 2.11108 16.1977 3.02652L13.466 4.91138V2.7666C13.466 1.66203 12.5706 0.766602 11.466 0.766602H2Z" fill={props.color || '#6C7072'}/>
        <path d="M13.466 10.0878L14.034 9.26476L12.466 8.18289V10.0878H13.466ZM16.1977 11.9727L15.6298 12.7958L16.1977 11.9727ZM19.3335 10.3265H18.3335H19.3335ZM19.3335 4.67268L20.3335 4.67268V4.67268H19.3335ZM16.1977 3.02652L15.6298 2.20344V2.20344L16.1977 3.02652ZM13.466 4.91138H12.466V6.81632L14.034 5.73446L13.466 4.91138ZM1 2.7666C1 2.21432 1.44771 1.7666 2 1.7666V-0.233398C0.343147 -0.233398 -1 1.10975 -1 2.7666H1ZM1 12.2326V2.7666H-1V12.2326H1ZM2 13.2326C1.44771 13.2326 1 12.7849 1 12.2326H-1C-1 13.8895 0.343144 15.2326 2 15.2326V13.2326ZM11.466 13.2326H2V15.2326H11.466V13.2326ZM12.466 12.2326C12.466 12.7849 12.0183 13.2326 11.466 13.2326V15.2326C13.1229 15.2326 14.466 13.8895 14.466 12.2326H12.466ZM12.466 10.0878V12.2326H14.466V10.0878H12.466ZM16.7656 11.1496L14.034 9.26476L12.8981 10.9109L15.6298 12.7958L16.7656 11.1496ZM18.3335 10.3265C18.3335 11.1325 17.429 11.6073 16.7656 11.1496L15.6298 12.7958C17.6199 14.1689 20.3335 12.7444 20.3335 10.3265H18.3335ZM18.3335 4.67268L18.3335 10.3265H20.3335L20.3335 4.67268L18.3335 4.67268ZM16.7656 3.8496C17.429 3.39188 18.3335 3.86673 18.3335 4.67268H20.3335C20.3335 2.25483 17.6199 0.830279 15.6298 2.20344L16.7656 3.8496ZM14.034 5.73446L16.7656 3.8496L15.6298 2.20344L12.8981 4.0883L14.034 5.73446ZM12.466 2.7666V4.91138H14.466V2.7666H12.466ZM11.466 1.7666C12.0183 1.7666 12.466 2.21432 12.466 2.7666H14.466C14.466 1.10975 13.1229 -0.233398 11.466 -0.233398V1.7666ZM2 1.7666H11.466V-0.233398H2V1.7666Z" fill="#E1E1E1" mask="url(#path-1-inside-1_24_5769)"/>
    </svg>
);

export default memo(VideoChatIcon);
