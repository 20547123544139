import { Box, Button, Dialog, DialogContent, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import TiersCard from "./TiersCard";
import CloseIcon from '../../svg/CloseIcon';

const TiersCardModal: React.FC = () => {
  const [ showModal, setShowModal ] = useState<boolean>(false);

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      pt={2}
      pb={2}
    >
      <Typography
        variant='subtitle2'
        fontSize='1.1rem'
        color='#000'
        fontWeight={600}
        pb={0.5}
      >
        Tier Card
      </Typography>

      <Button
        fullWidth
        variant="outlined"
        onClick={() => setShowModal(true)}
      >
        Show Pricing Tiers
      </Button>
      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        fullWidth
        maxWidth='lg'
      >
        <DialogContent>
          <IconButton
            id='button-close-tiers-card-modal'
            onClick={() => setShowModal(false)}
            color='primary'
            sx={{
              position: 'absolute',
              alignSelf: 'flex-end',
              minWidth: '36px',
              minHeight: '36px',
              height: '36px',
              width: '36px',
              top: '16px',
              right: '16px',
            }}
          >
            <CloseIcon height='30px' width='30px' />
          </IconButton>
          <TiersCard />
        </DialogContent>
      </Dialog>
    </Box>
  )

}

export default TiersCardModal;
