import * as React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Typography, { TypographyProps } from '@mui/material/Typography';

interface TopImageSectionProps {
  mainBoxProps?: BoxProps;
  contentBoxProps?: BoxProps;
  image?: string;
  imageProps?: any;
  title?: string;
  titleProps?: TypographyProps;
  description?: string;
  descriptionProps?: TypographyProps;
}

const TopImageSection = (props: TopImageSectionProps) => {
  const {
    image,
    title,
    description,
    imageProps,
    mainBoxProps,
    contentBoxProps,
    titleProps,
    descriptionProps,
  } = props;

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      mb={4}
      {...mainBoxProps}
    >
      {image && (
        <img
          src={image}
          alt='logo'
          width='100%'
          style={{
            maxWidth: '350px',
          }}
          {...imageProps}
        />
      )}
      <Box
        display='flex'
        flexDirection='column'
        gap={2}
        mt={-2}
        alignItems='center'
        textAlign='center'
        justifyContent='center'
        {...contentBoxProps}
      >
        {title && (
          <Typography
            variant='subtitle2'
            fontWeight='600'
            fontSize={'20px'}
            {...titleProps}
          >
            {title}
          </Typography>
        )}
        {description && (
          <Typography
            variant='body2'
            color='#000'
            fontWeight='400'
            {...descriptionProps}
          >
            {description}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
export default React.memo(TopImageSection);
