import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material"
import TrialModal from "./trialModal";

interface TrialButtonProps {
  loadedData: React.MutableRefObject<any>;
  requiredPremiumKey: string;
  setTrialPeriod: React.Dispatch<React.SetStateAction<number>>;
  setStripeItem: React.Dispatch<React.SetStateAction<string>>;
  setStripeOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const TrialButton: React.FC<TrialButtonProps> = (props) => {
  const { loadedData, requiredPremiumKey, setTrialPeriod, setStripeItem, setStripeOpen } = props;

  const [ showTrialModal, setShowTrialModal ] = useState<boolean>(false);

  const checkTrialCode = (code: string, setInputError: any) => {
    if (code.toLowerCase() === loadedData.current.metadata.trial_code.toLowerCase()) {
      setTrialPeriod(Number(loadedData.current.metadata.trial_period))
      setStripeItem(requiredPremiumKey)
      setStripeOpen(true)
      setShowTrialModal(false)
      setInputError(false);
    } else {
      setInputError(true)
    }
  }

  return (
    <>
      <Button
        id={`button-trial-period`}
        variant="contained"
        sx={{
          height: '32px !important',
          padding: '6px 12px !important',
          borderRadius:'6px',
          fontSize: '14px',
          mb: 2,
          width: {xs: '100%', sm: 'auto'},
        }}
        onClick={() => {
          setShowTrialModal(true)
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            ml: 2,
            mr: 2
          }}
        >
          <Typography color='white' fontWeight={700} >I have a student code</Typography>
        </Box>
      </Button>
      <TrialModal
        showTrialModal={showTrialModal}
        setShowTrialModal={setShowTrialModal}
        checkTrialCode={checkTrialCode}
      />
    </>
  )
}

export default TrialButton;
