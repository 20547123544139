const FlameIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 30'
    {...props}
  >
    <path
      fill='#EF5F5F'
      stroke='#fff'
      strokeWidth={0.981}
      d='M19.24 8.414c.224.24.471.563.73.976.43.688.871 1.589 1.268 2.677.793 2.175 1.4 5.073 1.4 8.448 0 3.162-1.397 5.344-3.452 6.754-2.076 1.425-4.846 2.074-7.562 2.074-2.99 0-5.757-.833-7.767-2.484C1.86 25.22.57 22.74.57 19.34c0-3.336 2.052-6.18 4.245-8.594A75.19 75.19 0 0 1 6.427 9.04l.034-.034c.506-.524.99-1.025 1.411-1.495.43-.48.81-.942 1.09-1.382.279-.434.488-.888.527-1.345.13-1.514.12-2.763.074-3.588 1.123.517 2.49 1.248 3.681 2.471 1.462 1.502 2.687 3.779 2.778 7.4l.032 1.262.828-.952c1.039-1.192 1.695-2.022 2.095-2.58.103-.143.19-.27.264-.383Z'
    />
  </svg>
);
export default FlameIcon;
