import * as React from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import CreateAccountImage from '../../assets/images/create-account.svg';
import { useAuth } from '../../hooks/useAuth';
import { toast } from 'react-toastify';
import { FormMode } from '../creatorOnBoarding/signup.types';
import TopImageSection from '../../component/TopImageSection';
import { USER_TYPE } from '../../config/const';
import CreateUpdateProfileForm from './createUpdateProfileForm';

export default function CreateProfile() {
  const formMode: FormMode = 'create';

  const { authUser } = useAuth();

  const navigate = useNavigate();

  React.useEffect(() => {
    if (authUser?.documentId) {
      if (authUser?.userName || authUser?.userType !== USER_TYPE.FOLLOWER) {
        navigate(`/creators`);
        toast.error('You are not authorized to access this location');
        return;
      }
    }
  }, [authUser]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      display='flex'
      flex={1}
      flexDirection='column'
      alignItems='center'
      justifyContent='flex-start'
      justifyItems='center'
      textAlign='center'
      width='100%'
      sx={{
        px: '2rem',
      }}
    >
      <Box
        display='flex'
        justifyContent='flex-start'
        alignItems='center'
        flexDirection='column'
        flex={0.5}
        width='100%'
        sx={{
          '@media (min-width: 768px)': {
            width: '50%',
          },
          '@media (min-width: 1440px)': {
            px: '5rem',
          },
        }}
      >
        <TopImageSection
          image={CreateAccountImage}
          title='Welcome'
          description='Create your profile'
        />

        <CreateUpdateProfileForm formMode={formMode} />
      </Box>
    </Box>
  );
}
