import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { RouterProvider } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthProvider } from './context/AuthContext';
import { ProfileProvider } from './context/ProfileContext';
import { TrainSectionProvider } from './context/TrainSectionContext';
import { SortByProvider } from './context/SortByContext';
import { VolumeProvider } from './context/VolumeContext';
import { SimpleDialogProvider } from './context/SimpleDialogContext';
import MaintenancePage from './maintenance';
import routes from './routes';
import theme from './theme';
import './App.css';
import ReactGA from 'react-ga';
import { QueryClient, QueryClientProvider } from 'react-query';
import SimpleDialog from './component/SimpleDialog';
import { StripeProvider } from './context/StripeContext';
import { MathJaxContext } from 'better-react-mathjax';

const TRACKING_ID = process.env.REACT_APP_ANALYTICS_TRACKING_ID; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

function App() {
  // const [, setInnerHeightWidth] = React.useState({
  //   width: window?.visualViewport
  //     ? (window?.visualViewport as any)?.width
  //     : window?.innerWidth,
  //   height: window?.visualViewport
  //     ? (window?.visualViewport as any)?.height
  //     : window?.innerHeight,
  // });
  // React.useEffect(() => {
  //   window?.visualViewport?.addEventListener('resize', updateSize);
  //   updateSize();
  //   return () =>
  //     window?.visualViewport?.removeEventListener('resize', updateSize);
  // }, []);

  // function updateSize() {
  //   setInnerHeightWidth({
  //     width: window?.visualViewport
  //       ? (window?.visualViewport as any)?.width
  //       : window?.innerWidth,
  //     height: window?.visualViewport
  //       ? (window?.visualViewport as any)?.height
  //       : window?.innerHeight,
  //   });
  // }

  const MathJaxConfig = {
    loader: { load: ["[tex]/html"] },
    tex: {
      packages: { "[+]": ["html"] },
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"],
      ],
      displayMath: [
        ["$$", "$$"],
        ["\\[", "\\]"],
      ],
    },
  };

  React.useEffect(() => {
    if (window.location.hostname === 'stanford.soopra.ai') {
      document.body.classList.add('stanford-soopra-ai');
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <ProfileProvider>
            <StripeProvider>
              <VolumeProvider>
                <SortByProvider>
                  <TrainSectionProvider>
                    <SimpleDialogProvider>
                      <ToastContainer
                        limit={1}
                        theme='colored'
                        position='top-center'
                        autoClose={3000}
                        style={{
                          fontSize: '14px',
                          width: 'auto',
                          fontWeight: '600',
                        }}
                      />
                      <CssBaseline />
                      <MathJaxContext version={3} config={MathJaxConfig}>
                        <div
                          className='app'
                          style={{
                            // maxHeight: innerHeightWidth?.height || "100%",
                            margin: '0 auto',
                            width: '80%',
                          }}
                        >
                          <div className='app-main' id='app-main'>
                            {process.env.REACT_APP_MAINTENANCE_MODE === 'true' ? (
                              <MaintenancePage />
                            ) : (
                              <RouterProvider router={routes}/>
                            )}
                          </div>
                        </div>
                      </MathJaxContext>
                      <SimpleDialog />
                    </SimpleDialogProvider>
                  </TrainSectionProvider>
                </SortByProvider>
              </VolumeProvider>
            </StripeProvider>
          </ProfileProvider>
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
