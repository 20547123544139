import React, { createContext, useState, memo } from 'react';
import { firestore } from '../utils/firebase';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import { toast } from 'react-toastify';
import { modifyUserData } from '../utils/helper';
import { UserFormType } from '../views/creatorOnBoarding/signup.types';
import { USER_TYPE } from '../config/const';

interface ProfileContextType {
  authUserProfileData: UserFormType | null;
  setAuthUserProfileData: (userData: UserFormType) => void;
  creatorProfileData: UserFormType | null;
  setCreatorProfileData: (userData: UserFormType) => void;
  fetchProfileDataFromUserName: (
    userName: string,
    fetchCreatorData?: boolean,
    checkIsCreatorActive?: boolean,
    navigate?: (path: string) => void
  ) => Promise<void>;
  fetchProfileDataFromUserId: (userId: string) => Promise<any>;
  fetchCreatorDataFromUserId: (userId: string) => Promise<any>;
  setUserDataToContext: (userData: any) => Promise<any>;
  isLoadingProfile: boolean;
}

export const ProfileContext = createContext<ProfileContextType>({
  authUserProfileData: null,
  creatorProfileData: null,
  isLoadingProfile: false,
  setAuthUserProfileData: async () => {
    return;
  },
  setCreatorProfileData: async () => {
    return;
  },
  fetchProfileDataFromUserName: async () => {
    return;
  },
  fetchProfileDataFromUserId: async () => {
    return;
  },
  fetchCreatorDataFromUserId: async () => {
    return;
  },
  setUserDataToContext: async () => {
    return;
  },
});

export const ProfileProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [authUserProfileData, setAuthUserProfileData] =
    useState<UserFormType | null>(null);
  const [creatorProfileData, setCreatorProfileData] =
    useState<UserFormType | null>(null);
  const [isLoadingProfile, setIsLoadingProfile] =
    React.useState<boolean>(false);

  // fetch user data from the username
  const fetchProfileDataFromUserName = async (
    username: string,
    fetchCreatorData: boolean = false,
    checkIsCreatorActive: boolean = false,
    navigate?: (path: string) => void
  ) => {
    try {
      setIsLoadingProfile(true);

      let fetchQuery = query(
        collection(firestore, 'users'),
        where('normalizedUserName', '==', username?.toLowerCase())
      );

      //while fetching data for creator check the user is also a creator
      if (fetchCreatorData) {
        fetchQuery = query(
          collection(firestore, 'users'),
          where('normalizedUserName', '==', username?.toLowerCase()),
          where('userType', '==', USER_TYPE.CREATOR)
        );
        // temporarily clear existing creator
        setCreatorDataToContext({
          userName: username,
          normalizedFullName: username.toLowerCase(),
          fullName: username
        });
      }

      const result = await getDocs(fetchQuery);
      if (result?.empty) {
        // on empty result return with not exist profile
        toast.error('The profile does not exist');
        navigate?.(`/creators`);
        return;
      } else if (
        // check creator profile is active or not if not show error message
        checkIsCreatorActive &&
        result?.docs.length >= 1 &&
        result?.docs?.[0]?.data() &&
        !result?.docs?.[0]?.data()?.index
      ) {
        toast.error('The profile is not active. Please try again later.');
        navigate?.(`/creators`);
        return;
      }
      // set valid users data to the context state
      if (result?.docs.length >= 1 && result?.docs?.[0]?.data()) {
        if (fetchCreatorData) {
          setCreatorDataToContext({
            ...result?.docs?.[0]?.data(),
            documentId: result?.docs?.[0]?.id,
          });
        } else {
          setUserDataToContext({
            ...result?.docs?.[0]?.data(),
            documentId: result?.docs?.[0]?.id,
          });
        }
      }
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong');
    } finally {
      setIsLoadingProfile(false);
    }
  };

  // fetch user data from user document Id
  const fetchProfileDataFromUserId = async (userId: string) => {
    if (!authUserProfileData && userId) {
      const docRef = doc(firestore, 'users', userId);
      const result = await getDoc(docRef);
      if (result?.data()) {
        setUserDataToContext({
          ...result?.data(),
          documentId: result?.id,
        });
        return result?.data();
      }
    }
  };

  // fetch creator data from userId
  const fetchCreatorDataFromUserId = async (
    userId: string
  ) => {
    try {
      setIsLoadingProfile(true);

      const docRef = doc(firestore, 'users', userId);
      const result = await getDoc(docRef);
      if (result?.data()) {
        let creatorInfo = result?.data();
        setCreatorDataToContext({
          ...creatorInfo,
          documentId: creatorInfo?.user_id,
        });
        return result?.data();
      } else {
        toast.error(`Creator does not exist, try again`)
      }

    } catch (err) {
      console.log(err);
      // toast.error('Something went wrong');
    } finally {
      setIsLoadingProfile(false);
    }
  };

  // set data into the context
  const setUserDataToContext = async (userProfileData: any) => {
    const userData = modifyUserData(userProfileData);
    setAuthUserProfileData(userData);
  };

  // set data into the context
  const setCreatorDataToContext = async (userProfileData: any) => {
    const userData = modifyUserData(userProfileData);
    setCreatorProfileData(userData);
  };

  return (
    <ProfileContext.Provider
      value={{
        authUserProfileData,
        setAuthUserProfileData,
        creatorProfileData,
        setCreatorProfileData,
        fetchProfileDataFromUserName,
        fetchProfileDataFromUserId,
        fetchCreatorDataFromUserId,
        setUserDataToContext,
        isLoadingProfile,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export default memo(ProfileProvider);
