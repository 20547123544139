import * as React from 'react';
import { SVGProps, memo } from 'react';
const SoopraSmile = (props: SVGProps<SVGSVGElement>) => (
    <svg width={props.width || "69"} height={props.height || "55"} viewBox="0 0 69 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_921_2477)">
    <path d="M45.9358 33.6573C38.7109 33.6573 34.6489 28.8629 34.6489 21.6712C34.6489 14.0134 39.177 9.68512 45.9358 9.68512C53.1608 9.68512 57.2227 14.4796 57.2227 21.6712C57.2227 29.329 52.6946 33.6573 45.9358 33.6573ZM45.9358 28.3301C49.4651 28.3301 51.4627 25.9329 51.4627 21.6712C51.4627 17.4095 49.4651 15.0123 45.9358 15.0123C42.4066 15.0123 40.4089 17.4095 40.4089 21.6712C40.4089 25.9329 42.4066 28.3301 45.9358 28.3301Z" fill={props.color || '#00A3FF'}/>
    <path d="M20.8997 33.6573C13.6747 33.6573 9.61279 28.8629 9.61279 21.6712C9.61279 14.0134 14.1409 9.68512 20.8997 9.68512C28.1246 9.68512 32.1866 14.4796 32.1866 21.6712C32.1866 29.329 27.6585 33.6573 20.8997 33.6573ZM20.8997 28.3301C24.4289 28.3301 26.4266 25.9329 26.4266 21.6712C26.4266 17.4095 24.4289 15.0123 20.8997 15.0123C17.3705 15.0123 15.3728 17.4095 15.3728 21.6712C15.3728 25.9329 17.3705 28.3301 20.8997 28.3301Z" fill={props.color || '#00A3FF'}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M35.4657 44.8434C26.3458 45.0734 16.5872 43.7681 8.1545 40.1947C7.45727 39.8992 7.15592 39.0837 7.47143 38.3953L8.614 35.9024C8.92951 35.2141 9.74227 34.9137 10.4406 35.2065C17.9319 38.3483 26.8057 39.5756 35.3274 39.3607C43.6941 39.1497 51.4842 37.5551 57.0419 35.2225C57.7402 34.9294 58.5549 35.2224 58.8768 35.9078L60.0426 38.3899C60.3646 39.0753 60.0707 39.8939 59.3741 40.1909C53.0127 42.9028 44.4268 44.6175 35.4657 44.8434Z" fill={props.color || '#00A3FF'}/>
    </g>
    <defs>
    <clipPath id="clip0_921_2477">
    <rect width="69" height="55" fill="white"/>
    </clipPath>
    </defs>
    </svg>


)


export default memo(SoopraSmile);