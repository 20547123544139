import * as React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '../../svg/TwitterIcon';
import TikTokIcon from '../../svg/TiktokIcon';
import InstagramIcon from '../../svg/InstagramIcon';
import { UserFormType } from '../creatorOnBoarding/signup.types';
import styles from './user-profile.module.css';
import { fetchTwitterData} from '../../utils/api';
import { useAuth } from '../../hooks/useAuth';

interface SocialMediaLinksType {
  profileData: UserFormType;
  boxContainerProps?: BoxProps;
}

function SocialMediaLinks(props: SocialMediaLinksType) {
  const { profileData, boxContainerProps } = props;
  const { authUser } = useAuth(); 
  const user_id = authUser?.documentId;
  const { linkedInURL, instagramURL, youtubeURL, tiktokURL, userName } =
    profileData || {};
    

    const [twitterURL, setTwitterURL] = React.useState<string | undefined>(undefined);
    const [isTwitterAuthenticated, setIsTwitterAuthenticated] = React.useState<boolean>(false);
  
    React.useEffect(() => {
      const fetchTwitterInfo = async () => {
        if (user_id) {
          try {
            const data = await fetchTwitterData(user_id);
            if (data.is_twitter_authenticated) {
              setTwitterURL(data.profile_url);
              setIsTwitterAuthenticated(true);
            }
          } catch (error) {
            console.error('Error fetching Twitter data:', error);
          }
        }
      };
  
      fetchTwitterInfo();
    }, [user_id]);

    const renderLinks = React.useCallback((link: string | undefined, platform: string) => {
      // Define the base URL for each platform
      const platformUrls = {
        linkedin: 'https://www.linkedin.com/in/',
        twitter: 'https://twitter.com/',
        youtube: 'https://www.youtube.com/@',
        tiktok: 'https://www.tiktok.com/@',
        instagram: 'https://www.instagram.com/',
      };
    
      if (link) {

        // Special handling for YouTube links
        if (platform === 'youtube') {
          // Check if the link is a full YouTube URL
          if (link.includes('youtube.com')) {
            return link;
          } else {
            return `${platformUrls.youtube}${link}`;
          }
        }
        // Check if the link already contains a full URL for the platform
        if (link.includes(platformUrls[platform])) {
          // Extract the user identifier from the link
          const userId = link.split(platformUrls[platform])[1];
          return `${platformUrls[platform]}${userId}`;
        } else {
          // If not a full URL, construct it using the base URL and provided user identifier
          switch (platform) {
            case 'linkedin':
              return `${platformUrls.linkedin}${link}`;
            case 'twitter':
              return `${platformUrls.twitter}${link}`;
            case 'youtube':
              return `${platformUrls.youtube}${link}`;
            case 'tiktok':
              return `${platformUrls.tiktok}${link}`;
            case 'instagram':
              return `${platformUrls.instagram}${link}`;
            default:
              return link;
          }
        }
      }
    
      return undefined;
    }, []);
    

  return (
    <>
      {!instagramURL &&
      !linkedInURL &&
      !tiktokURL &&
      !twitterURL &&
      !youtubeURL ? (
        <></>
      ) : (
        <Grid width='100%' item display='flex' xs={12}>
          <Box
            display='flex'
            justifyContent='flex-start'
            alignItems='center'
            columnGap={2}
            width='100%'
            flexWrap='wrap'
            {...boxContainerProps}
          >
            {instagramURL && (
              <a
                id={`href-instagram-${userName}`}
                href={renderLinks(instagramURL, 'instagram')}
                target='_blank'
                rel='noopener noreferrer ext'
              >
                <InstagramIcon
                  className={styles.socialMediaIcons}
                  color={'instagramIcon'}
                />
              </a>
            )}
            {linkedInURL && (
              <a
                id={`href-linkedin-${userName}`}
                href={renderLinks(linkedInURL, 'linkedin')}
                target='_blank'
                rel='noopener noreferrer'
              >
                <LinkedInIcon
                  className={styles.socialMediaIcons}
                  color={'linkedinIcon'}
                />
              </a>
            )}
            {tiktokURL && (
              <a
                id={`href-tiktok-${userName}`}
                href={renderLinks(tiktokURL, 'tiktok')}
                target='_blank'
                rel='noopener noreferrer'
              >
                <TikTokIcon
                  className={styles.socialMediaIcons}
                  color={'tiktokIcon'}
                />
              </a>
            )}
            {isTwitterAuthenticated && twitterURL && (
              <a
                id={`href-twitter-${userName}`}
                href={twitterURL}
                target='_blank'
                rel='noopener noreferrer'
              >
                <TwitterIcon
                  className={styles.socialMediaIcons}
                  color={'twitterIcon'}
                />
              </a>
            )}
            {youtubeURL && (
              <a
                id={`href-youtube-${userName}`}
                href={renderLinks(youtubeURL, 'youtube')}
                target='_blank'
                rel='noopener noreferrer'
              >
                <YouTubeIcon
                  className={styles.socialMediaIcons}
                  color={'youtubeIcon'}
                />
              </a>
            )}
          </Box>
        </Grid>
      )}
    </>
  );
}

export default React.memo(SocialMediaLinks);
