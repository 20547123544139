import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    Radio,
    FormControlLabel,
    Divider,
    Paper,
    Card,
    CardContent
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { handleDeleteCourse } from '../../utils/helper';

const AssessmentComponent = () => {
    const location = useLocation();
    const { assignments = [], courseId, courseData } = location.state || {};
    const totalModules = assignments.length;
    const [currentModuleIndex, setCurrentModuleIndex] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const shouldDeleteCourseRef = useRef(true);
    const scrollRef = useRef(null);

    useEffect(() => {
        if (assignments && assignments[currentModuleIndex]) {
            let cleanQuestions = assignments[currentModuleIndex].questions.slice();
            for (let i = 0; i < cleanQuestions.length; i++){
              for (let j = 0; j < cleanQuestions[i].options.length; j++) {
                if ((typeof cleanQuestions[i].options[j].isCorrect) !== 'boolean'){
                  cleanQuestions[i].options[j].isCorrect = String(cleanQuestions[i].options[j].isCorrect).toLowerCase() === "true";
                }
              }
            }
            setQuestions(cleanQuestions);
            setErrors(assignments[currentModuleIndex].questions?.map(() => []))
        }
    }, [assignments, currentModuleIndex]);

    useEffect(() => {
      return () => {
        if (shouldDeleteCourseRef.current) {
          handleDeleteCourse(courseData, authUser)
        }
      };
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    const { authUser } = useAuth();
    const navigate = useNavigate();

    const handleTextChange = (text, questionIndex, answerIndex = null) => {
        setQuestions(questions.map((q, idx) => {
            if (idx === questionIndex) {
                if (answerIndex !== null) {
                    q.options[answerIndex].option = text;
                } else {
                    q.question = text;
                }
            }
            return q;
        }));
    };

    const handleSelectCorrect = (questionIndex, answerIndex) => {
        setQuestions(questions.map((q, idx) => {
            if (idx === questionIndex) {
                q.options = q.options.map((a, ai) => ({
                    ...a,
                    isCorrect: ai === answerIndex
                }));
            }
            return q;
        }));
    };

    const saveCurrentModuleQuestions = () => {
        assignments[currentModuleIndex].questions = questions;
    };

    const validateQuestions = () => {
      // should ensure questions are unique and exist
      // should ensure answers are unique and exist
      // should ensure only one answer is marked as correct
      // returns boolean and populates error array with error messages
      let firstError;
      let isValid = true;
      let currErrors = questions.map(() => new Set());
      let questStrs = {};
      for (let i = 0; i < questions.length; i++){
        let curQuestion = questions[i];
        if (curQuestion.question.length){
          if (questStrs[curQuestion.question]) {
            currErrors[i].add("Questions must be unique")
            isValid = false;
            if (!firstError) firstError = i+1;
          } else {
            questStrs[curQuestion.question] = true;
          }
        } else {
          currErrors[i].add("Question cannot be blank")
          isValid = false;
          if (!firstError) firstError = i+1;
        }

        let numCorrect = 0;
        let optionStrs = {};
        for (let option of curQuestion.options){
          if (option.isCorrect) { numCorrect++ }
          if (option.option.length) {
            if (optionStrs[option.option]) {
              currErrors[i].add("Answers must be unique")
              isValid = false;
              if (!firstError) firstError = i+1;
            } else {
              optionStrs[option.option] = true;
            }
          } else {
            currErrors[i].add("Answers cannot be blank")
            isValid = false;
            if (!firstError) firstError = i+1;
          }
        }
        if (numCorrect !== 1) {
          currErrors[i].add("Exactly one answer must be chosen per question")
          isValid = false;
          if (!firstError) firstError = i+1;
        }
      }
      let currErrorsArray = currErrors.map((set) => Array.from(set))
      setErrors(currErrorsArray);
      if (firstError) {
        // scroll question with error into view
        let scrollTarget = document.getElementById(`question-${firstError-1}`)
        setTimeout(() => scrollTarget.scrollIntoView({behavior: 'smooth'}), 250);
      }
      return isValid;
    }

    const handleNext = () => {
        saveCurrentModuleQuestions();
        if (validateQuestions()) {
          if (currentModuleIndex < totalModules - 1) {
              setCurrentModuleIndex(currentModuleIndex + 1);
              setTimeout(() => scrollRef.current?.scrollIntoView({behavior: 'smooth'}), 250);
          } else {
              handleContinue();
          }
        }
    };

    const handlePrevious = () => {
        saveCurrentModuleQuestions();
        if (currentModuleIndex > 0) {
            setCurrentModuleIndex(currentModuleIndex - 1);
            setTimeout(() => scrollRef.current?.scrollIntoView({behavior: 'smooth'}), 250);
        }
        window.scrollTo(0, 0);
    };

    const addQuestion = () => {
        setQuestions([
            ...questions,
            {
                question: '',
                options: Array.from({ length: 4 }, () => ({ option: '', isCorrect: false }))
            }
        ]);
    };

    const handleContinue = async () => {
      setLoading(true);
      shouldDeleteCourseRef.current = false; // Set the flag to false to prevent deletion
      const updatedCourseInfo = {
        ...courseData,
        course_id: courseId,
        modules: assignments
      };
      try {
        // await updateCurriculum(courseId, updatedCourseInfo);
        navigate(`/CreateCourseBanner/${courseId}`, {state: {courseId, courseData: updatedCourseInfo}});
      } catch (error) {
          console.error('Error finalizing course creation:', error);
      } finally {
          setLoading(false);
      }
    }

    return (
        <Box width="100%" maxWidth={800} mx="auto" px={2} py={4} ref={scrollRef} sx={{scrollMarginTop: '50px',}}>
            <Typography variant="h5" gutterBottom >
                3: Assessments
            </Typography>

            <Paper elevation={2} sx={{ p: 2, mb: 4 }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {`Module ${currentModuleIndex + 1} Assessment`}
                    </Typography>
                    <Button
                        startIcon={<AddIcon />}
                        sx={{
                            backgroundColor: 'black !important',
                            color: 'white',
                            fontWeight: 600,
                            whiteSpace: 'nowrap',
                            width: '150px',
                            maxHeight: '35px'
                        }}
                        onClick={addQuestion}
                    >
                        Question
                    </Button>
                </Box>

                {questions.map((question, index) => (
                    <Card
                      id={`question-${index}`}
                      key={index}
                      sx={{
                        mb: 4,
                        border: ((errors.length && errors[index].length) ? '2px solid red' : '2px solid transparent'),
                        scrollMarginTop: '50px',
                        overflow: 'clip'
                      }}>
                        <CardContent>
                            <TextField
                                fullWidth
                                label={`Question ${index + 1}`}
                                variant="outlined"
                                margin="normal"
                                value={question.question}
                                onChange={(e) => handleTextChange(e.target.value, index)}
                                error={!!(errors.length && errors[index].length)}
                                helperText={(errors.length && errors[index].length) ? errors[index][0] : null}
                            />
                            <Divider sx={{ my: 2 }} />
                            {question.options.map((answer, ansIndex) => (
                                <Box key={ansIndex} display="flex" alignItems="center" sx={{ mb: 2 }}>
                                    <Typography
                                        sx={{
                                            ml: 1,
                                            mr: 2,
                                            fontWeight: 'bold',
                                            fontSize: '1.6rem',
                                            lineHeight: '1rem'
                                        }}
                                    >
                                        •
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        label={`Answer Option ${ansIndex + 1}: Details`}
                                        variant="outlined"
                                        size="small"
                                        value={answer.option}
                                        onChange={(e) => handleTextChange(e.target.value, index, ansIndex)}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={answer.isCorrect}
                                                onChange={() => handleSelectCorrect(index, ansIndex)}
                                                sx={{ ml: 2 }}
                                            />
                                        }
                                        label="Correct Answer"
                                    />
                                </Box>
                            ))}
                        </CardContent>
                    </Card>
                ))}
            </Paper>

            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<ArrowBackIosIcon />}
                    onClick={handlePrevious}
                    disabled={currentModuleIndex === 0}
                >
                    Previous
                </Button>

                <Typography variant="h6">{`${currentModuleIndex + 1} / ${totalModules}`}</Typography>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    disabled={loading}
                    endIcon={<ArrowForwardIcon/>}
                    sx={{
                        '&.Mui-disabled': {
                            backgroundColor: '#009CDB !important',
                            color: 'white',
                        },
                    }}
                >
                    {currentModuleIndex < totalModules - 1 ? 'Next' : loading ? 'Saving...' : 'Next'}
                </Button>
            </Box>
        </Box>
    );
};

export default AssessmentComponent;
