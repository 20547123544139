import { useState, useEffect, useRef } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Paper,
    TextField,
    Typography,
    List,
    ListItem,
    ListItemText
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { deleteCourse } from '../../utils/api';
import { useAuth } from '../../hooks/useAuth';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const CurriculumModules = ({ curriculum, courseId }) => {
    const navigate = useNavigate();
    const [modules, setModules] = useState([]);
    const shouldDeleteCourseRef = useRef(true);
    const { authUser } = useAuth();
    const scrollRef = useRef(null);
    const initialLoad = useRef<boolean>(true);

    useEffect(() => {
        if (curriculum && curriculum.modules) {
            const initialModules = curriculum.modules.map((mod, index) => ({
                id: index + 1,
                title: mod.title,
                topics: mod.topics.map(topic => topic.name)
            }));
            setModules(initialModules);
        }
    }, [curriculum]);

    useEffect(() => {
        return () => {
            if (shouldDeleteCourseRef.current) {
                deleteCourse(courseId).catch(error => {
                    console.error('Error deleting course on unmount:', error);
                });
            }
        };
    }, [courseId]);

    useEffect(() => {
      if (modules && initialLoad.current) {
        initialLoad.current = false;
        setTimeout(() => scrollRef.current?.scrollIntoView(true, {behavior: 'smooth'}), 250);
      }
    }, [modules])

    const handleModuleTitleChange = (value, index) => {
        const newModules = [...modules];
        newModules[index].title = value;
        setModules(newModules);
    };

    const handleTopicChange = (value, moduleIndex, topicIndex) => {
        const newModules = [...modules];
        newModules[moduleIndex].topics[topicIndex] = value;
        setModules(newModules);
    };

    const addTopic = (moduleIndex) => {
        const newModules = [...modules];
        newModules[moduleIndex].topics.push('');
        setModules(newModules);
    };

    const handleNext = () => {
      shouldDeleteCourseRef.current = false; // Set the flag to false to prevent deletion
      const updatedCourseInfo = {
        ...curriculum,
        creator_name: authUser.fullName,
        modules: modules.map((module) => ({
          title: module.title,
          topics: module.topics.map((topic) => ({
            name: topic,
            description: '' // Add appropriate description if needed
          }))
        }))
      };

      navigate(`/CreateCourseIntro/${courseId}`, {state: {courseId, courseData: updatedCourseInfo}})
    }

    return (
        <Box width="100%" mx="auto" px={0} py={4} ref={scrollRef} sx={{scrollMarginTop: '50px', scrollBehavior: 'smooth'}}>
            <Typography variant="h6" sx={{ marginBottom: '20px' }}>
                2: Curriculum
            </Typography>
            <Box px={2}>
                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" sx={{ backgroundColor: '#EFEFEF', maxHeight: '50px' }}>
                    <Typography variant="h6" gutterBottom sx={{ paddingLeft: '10px', paddingTop: '5px' }}>
                        Modules
                    </Typography>
                    {/*
                    <Button
                        startIcon={<AddIcon />}
                        onClick={addModule}
                        sx={{
                            whiteSpace: 'nowrap',
                            fontWeight: 800,
                            backgroundColor: 'black !important',
                            color: 'white',
                            width: '150px',
                            mr: '10px',
                            maxHeight: '35px'
                        }}
                    >
                        Module
                    </Button>
                    */}
                </Box>
                <Paper elevation={2}>
                    {modules.map((module, moduleIndex) => (
                        <Card key={module.id} sx={{ mb: 4 }}>
                            <CardContent>
                                <Box display="flex" alignItems="center" gap={2} mb={2}>
                                    <TextField
                                        fullWidth
                                        label={`Module ${moduleIndex + 1}: Title`}
                                        variant="outlined"
                                        value={module.title}
                                        onChange={(e) => handleModuleTitleChange(e.target.value, moduleIndex)}
                                    />
                                    <Button
                                        startIcon={<AddIcon />}
                                        onClick={() => addTopic(moduleIndex)}
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            fontWeight: 400,
                                            backgroundColor: 'black !important',
                                            color: 'white',
                                            width: '250px'
                                        }}
                                    >
                                        Topic
                                    </Button>
                                </Box>
                                <List sx={{ mt: 2 }}>
                                    {module.topics.map((topic, topicIndex) => (
                                        <ListItem key={topicIndex} disablePadding sx={{ mb: 1, alignItems: 'center' }}>
                                            <Typography
                                                sx={{
                                                    ml: 1,
                                                    mr: 2,
                                                    fontWeight: 'bold',
                                                    fontSize: '1.6rem',
                                                    lineHeight: '1rem'
                                                }}
                                            >
                                                •
                                            </Typography>
                                            <ListItemText
                                                primary={
                                                    <TextField
                                                        label={`Topic ${topicIndex + 1}: Name`}
                                                        fullWidth
                                                        value={topic}
                                                        onChange={(e) => handleTopicChange(e.target.value, moduleIndex, topicIndex)}
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                                }
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </CardContent>
                        </Card>
                    ))}
                </Paper>
                <Box display="flex" justifyContent="flex-end">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        endIcon={<ArrowForwardIcon />}
                        sx={{
                            '&.Mui-disabled': {
                                backgroundColor: '#009CDB !important',
                                color: 'white',
                            },
                        }}
                    >
                        Next
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default CurriculumModules;
