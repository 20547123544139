import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { COURSE_PRICES } from "../../config/const";
import { Button, Grid, TextField } from "@mui/material";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { fetchCourseIntroMessage, getCourseInfo, setCourseIntroMessage, updateCurriculum } from "../../utils/api";
import { useAuth } from "../../hooks/useAuth";
import { handleDeleteCourse } from "../../utils/helper";
import { toast } from 'react-toastify';

const CourseIntro = () => {
  const location = useLocation();
  const { courseId } = useParams();
  const [courseData, setCourseData] = useState(null);
  const [assignments, setAssignments] = useState(null);
  const [introMessage, setIntroMessage] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [savedIntroMessage, setSavedIntroMessage] = useState(null);
  const tmpCourseData = useRef(null);
  const navigate = useNavigate();
  const { authUser } = useAuth();
  const shouldDeleteCourseRef = useRef(true);

  useEffect(() => {
    loadCourseData();
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (courseId && courseData){
      // load assessments while user is working on intro message
      if (!assignments) {
        updateCurriculum(courseId, courseData)
          .then((resp) => {
            setAssignments(resp.modules);
          })
          .catch((error) => {
            console.log(error)
            loadCourseDataFromId();
          })
      }
    }

    return () => {
      if (shouldDeleteCourseRef.current) {
        handleDeleteCourse(courseData, authUser)
      }
    };
  }, [courseId, courseData]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (assignments && savedIntroMessage){
      shouldDeleteCourseRef.current = false;
      let updatedCurriculum = {
        ...courseData,
        intro: introMessage,
      };
      // this step is complete if assignments have been generated and intro message saved
      navigate(`/CreateAssessments/${courseId}`, { state: { assignments: assignments, courseId, courseData: updatedCurriculum } });
    }
  }, [assignments, savedIntroMessage]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (assignments && tmpCourseData.current) {
      setCourseData(tmpCourseData.current);
      setIntroMessage(tmpCourseData.current?.intro || "")
    }
  }, [assignments])

  let loadCourseData = async () => {
    if (location?.state?.courseData) {
      setCourseData(location?.state?.courseData)
    } else {
      loadCourseDataFromId();
    }
  }

  let loadCourseDataFromId = async () => {
    let resp = await getCourseInfo(courseId);
    if (resp.ok) {
      let data = await resp.json();
      if (authUser?.documentId === data.creator_id) {
        delete data.creator_avatar;
        if (data?.creation_done) {
          shouldDeleteCourseRef.current = false;
        }
        if (data?.modules && data.modules[0].questions) {
          tmpCourseData.current = data;
          setAssignments(data.modules)
        } else {
          setCourseData(data);
          setIntroMessage(data?.intro || "")
        }
      } else {
        // user is not instructor
        console.log('User is not instructor')
        navigate(-1);
      }
    } else {
      // course not found
      console.log('Course not found')
      navigate(-1);
    }
  }

  let handleGenerateIntro = async () => {
    setLoading(true);
    try {
      let resp = await fetchCourseIntroMessage(courseId, true);
      if (!resp.ok) {
        throw new Error(`HTTP error! Status: ${resp.status}`);
      }
      let output = await resp.json();
      setIntroMessage(output.response)
    } catch (error) {
      console.error('Error generating course introduction: ', error)
    } finally {
      setLoading(false);
    }
  }

  let handleSaveIntro = async () => {
    try {
      if (introMessage.length > 0) {
        setIsSaving(true);
        setCourseIntroMessage(courseId, introMessage)
          .then(() => {
            setSavedIntroMessage(introMessage);
          })
      } else {
        toast.error('Please provide an introduction message to continue')
      }
    } catch (error) {
      console.error('Error saving course introduction: ', error)
    }
  }

  return (
    courseData ?
      <Grid
        container
        spacing={2}
        sx={{mt: 0}}
      >
        <div style={{ width: '100%' }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ backgroundColor: '#EFEFEF', py: 1, width: '100%'}}
          >
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Create New Course
            </Typography>
          </Box>
        </div>
        <Grid
          item
          xs={12}
          sx={{
            p: 2,
            ml: {xs: 2, md: 0},
            mr: {xs: 2, md: 0},
            pb: {xs: 0, md: 2},
            borderBottom: {md: '1px solid lightgray'}
          }}
        >
          <Typography fontSize={'1.25rem'} fontWeight={700} align='left' color={'black'} >
            Course Information
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mb: {xs: 1, md: 4},
            pt: {xs: 0, md: 2}
          }}
        >
          <Box
            className="course-details-description-box"
            sx={{
              mt: {xs: 0, md: 1},
              ml: {xs: 2, md: 0},
              mr: {xs: 2, md: 0},
            }}
          >
            <Box sx={{display: 'flex'}}>
              <Typography fontSize={'1rem'} fontWeight={700} align='left' color={'black'} >
                Subject:
              </Typography>
              <Typography fontSize={'1rem'} fontWeight={400} align='left' color={'black'} sx={{ml: 1}}>
                {courseData?.subject}
              </Typography>
            </Box>

            <Box sx={{display: 'flex'}}>
              <Typography fontSize={'1rem'} fontWeight={700} align='left' color={'black'} >
                Course:
              </Typography>
              <Typography fontSize={'1rem'} fontWeight={400} align='left' color={'black'} sx={{ml: 1}}>
                {courseData?.name}
              </Typography>
            </Box>

            <Box sx={{display: 'flex'}}>
              <Typography fontSize={'1rem'} fontWeight={700} align='left' color={'black'} >
                Instructor:
              </Typography>
              <Typography fontSize={'1rem'} fontWeight={400} align='left' color={'black'} sx={{ml: 1}}>
                {courseData?.creator_name}
              </Typography>
            </Box>

            <Box sx={{display: 'flex'}}>
              <Typography fontSize={'1rem'} fontWeight={700} align='left' color={'black'} >
                Grade:
              </Typography>
              <Typography fontSize={'1rem'} fontWeight={400} align='left' color={'black'} sx={{ml: 1}}>
                {courseData?.grade}
              </Typography>
            </Box>

            <Box sx={{display: 'flex'}}>
              <Typography fontSize={'1rem'} fontWeight={700} align='left' color={'black'} >
                Duration:
              </Typography>
              <Typography fontSize={'1rem'} fontWeight={400} align='left' color={'black'} sx={{ml: 1}}>
                {courseData?.module_count + " Modules"}
              </Typography>
            </Box>

            <Box sx={{display: 'flex'}}>
              <Typography fontSize={'1rem'} fontWeight={700} align='left' color={'black'} >
                Price:
              </Typography>
              <Typography fontSize={'1rem'} fontWeight={400} align='left' color={'black'} sx={{ml: 1}}>
                {COURSE_PRICES[courseData?.module_count]}
              </Typography>
            </Box>

            <Box className={"course-details-overview-box"} sx={{display: 'flex', mt: 4, mb: 2}}>
              <Typography fontSize={'1rem'} fontWeight={700} align='left' color={'black'} >
                Overview:
              </Typography>
              <Typography fontSize={'1rem'} fontWeight={400} align='left' color={'black'} sx={{ml: 1}}>
                {courseData?.overview}
              </Typography>
            </Box>
          </Box>
          {
            courseData?.modules.map((mod: any, index: number) => {
              return <Box
                sx={{
                  mt: 2,
                  ml: {xs: 2, md: 0},
                  mr: {xs: 2, md: 0},
                }}
                key={`module-${index}`}
              >
                  <Typography
                    fontSize={'1rem'}
                    fontWeight={700}
                    align='left'
                    color={'black'}
                  >
                    {`Module ${index+1}: ${mod.title}`}
                  </Typography>
                  {mod?.topics.map((topic: any, top_index: number) => {
                    return <Typography
                      fontSize={'0.9rem'}
                      fontWeight={400}
                      align='left'
                      color={'black'}
                      sx={{
                        ml: 2
                      }}
                      key={`module-${index}-topic-${top_index}`}
                    >
                      {`${top_index+1}. ${topic.name}`}
                    </Typography>
                  })}
                </Box>
            })
          }
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mt: 1,
              ml: {xs: 2, md: 0},
              mr: 2,
            }}
          >
            <Typography fontSize={'1rem'} fontWeight={700} align='left' color={'black'} >
              Introduction Message
            </Typography>
            <Typography fontSize={'1rem'} fontWeight={400} align='left' color={'black'} sx={{mt: 2, mb: 2}}>
              This message will be shown to students at the beginning of your
              course. Please give a warm welcome and include anything else you
              wish to share with your students to start them on their journey.
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={6}
              variant="outlined"
              value={introMessage}
              onChange={(e) => setIntroMessage(e.target.value)}

            />
            <Grid
              container
              spacing={2}
              pt={2}
            >
              <Grid
                item
                xs={12}
                md={6}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleGenerateIntro}
                  startIcon={loading ? <CircularProgress size={22}/> : <AutoAwesomeIcon />}
                  disabled={loading || isSaving}
                  fullWidth
                  sx={{
                    '&.Mui-disabled': {
                      backgroundColor: '#8ad5f8 !important',
                    },
                  }}
                >
                  {loading ? 'Generating...' : 'Generate New Introduction'}
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                pb={2}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleSaveIntro}
                  startIcon={isSaving ? <CircularProgress size={22}/> : <></>}
                  endIcon={isSaving ? <></> : <ArrowForwardIcon />}
                  disabled={loading || isSaving || !introMessage.length}
                  fullWidth
                  sx={{
                    '&.Mui-disabled': {
                      backgroundColor: '#8ad5f8 !important',
                    },
                  }}
                >
                  {isSaving ? "Saving..." : "Next"}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid> :
    <Box
      sx={{
        display: 'flex',
        p: 2,
        justifyContent: 'center',
      }}
    >
      <CircularProgress/>
    </Box>
  )
}

export default CourseIntro;
