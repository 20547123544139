import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import React, { useState } from 'react';

interface GenericModalProps {
  showGenericModal: boolean,
  setShowGenericModal: (val: boolean) => void,
  data: {
    title: string,
    message: string,
    imgSrc: any,
    buttons?: Array<{
      text: string,
      action: ()=>void,
      variant: string,
    }>,
  }
}

const GenericModal: React.FC<GenericModalProps> = (props) => {
  let {showGenericModal, setShowGenericModal, data} = props;
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [imgSrc, setImgSrc] = useState(null);
  const [buttons, setButtons] = useState([]);

  React.useEffect(()=> {
    setTitle(data?.title || "");
    setMessage(data?.message || "");
    setImgSrc(data?.imgSrc || null);
    setButtons(data?.buttons || []);
  }, [data])

  const handleCloseGenericModal = () => {
    setShowGenericModal(false)
  };

  return (
    <Dialog
      open={showGenericModal}
      onClose={handleCloseGenericModal}
    >
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: '325px',
            padding: '15px'
          }}
        >
          <img src={imgSrc} alt='generic-modal' width='50%' style={{padding: '30px 0px',}}/>
          <Typography
            fontSize={'1.1rem'}
            fontWeight={700}
            mb={1}
            align='center'
          >
            {title}
          </Typography>
          <Typography
            fontSize={'1rem'}
            fontWeight={500}
            mb={3}
            align='center'
            dangerouslySetInnerHTML={{ __html: message }}
          >
          </Typography>
          {buttons?.length ?
            buttons?.map((button) => {
              return (
                <Button
                  sx={{
                    flex: 1,
                    py: 1,
                    width: '100%',
                    mt: 1
                  }}
                  size='small'
                  onClick={button.action}
                  variant={button.variant}
                >
                  {button.text}
                </Button>
              )
            }) :
            <Button
              sx={{
                flex: 1,
                py: 1,
                width: '100%',
                mt: 1
              }}
              size='small'
              onClick={handleCloseGenericModal}
              variant='outlined'
            >
              OK
            </Button>
          }
        </Box>
      </DialogContent>
    </Dialog>
  )
};

export default GenericModal;
