import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import ChatIcon from '@mui/icons-material/Chat';
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import { useContext, useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import { VolumeContext } from '../context/VolumeContext';

interface VoiceChatToggleProps {
  toggleVoiceChat: (newSetting: boolean) => void,
  useVoiceChat: boolean,
  audioQueue?: any,
}

const VoiceChatToggle: React.FC<VoiceChatToggleProps> = (props) => {
  const {
    toggleVoiceChat,
    useVoiceChat,
    audioQueue
  } = props;

  let [visibleVolSlider, setVisibleVolSlider] = useState(false);
  const { volume } = useContext(VolumeContext);
  let [volumeSliderVal, setVolumeSliderVal] = useState(volume.current || 50);

  useEffect(() => {
    if (volume && volume.current){
      setVolumeSliderVal(volume.current*50)
    }
  }, [volume])

  let handleVolumeChange = (event: Event, newValue: number) => {
    volume.current = newValue/50.0;
    if (audioQueue && audioQueue?.current){
      audioQueue.current.setVolume(newValue/50.0);
    }
    setVolumeSliderVal(newValue);
  };

  return (
    <>
      {
        useVoiceChat ?
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'flex-end',
              position: 'absolute',
              zIndex: '2',
              left: '0',
              bottom: '0',
            }}
          >
            <IconButton
              id='button-voice-chat-toggle-off'
              size='small'
              sx={{
                width: '40px',
                height: '40px',
                borderRadius: '40px',
                ml: 1.5,
                mb: 1.5,
                border: `1px solid ${grey[400]}`,
                boxShadow: `0px 0px 4px ${grey[400]}`,
                backgroundColor: 'white',
                '&:hover': {
                  backgroundColor: 'white',
                  '& svg': {
                    color: 'mainBlue.main'
                  }
                }
              }}
              onClick={()=> {toggleVoiceChat(false)}}
            >
              <ChatIcon
                sx={{
                  color: `${grey[600]}`,
                  transition: 'all 0.25s',
                }}
              />
            </IconButton>
            <IconButton
              id='button-show-volume'
              size='small'
              sx={{
                width: '40px',
                height: '40px',
                borderRadius: '40px',
                ml: 1.5,
                mb: 1.5,
                border: `1px solid ${grey[400]}`,
                boxShadow: `0px 0px 4px ${grey[400]}`,
                backgroundColor: 'white',
                '&:hover': {
                  backgroundColor: 'white',
                  '& svg': {
                    color: 'mainBlue.main'
                  }
                }
              }}
              onClick={()=> {setVisibleVolSlider(!visibleVolSlider)}}
            >
              {
                (!volume || (volume?.current === 0)) ?
                  <VolumeOffIcon
                    sx={{
                      color: `${grey[600]}`,
                      transition: 'all 0.25s',
                    }}
                  /> :
                  volume.current < 1 ?
                  <VolumeDownIcon
                    sx={{
                      color: `${grey[600]}`,
                      transition: 'all 0.25s',
                    }}
                  /> :
                  <VolumeUpIcon
                    sx={{
                      color: `${grey[600]}`,
                      transition: 'all 0.25s',
                    }}
                  />
              }
            </IconButton>
            {
              visibleVolSlider &&
              <Box
                sx={{
                  position: 'relative',
                  height: 120,
                  left: '-45px',
                  bottom: '65px',
                  padding: '10px',
                }}
                onMouseLeave={() => {setVisibleVolSlider(false)}}
              >
                <Slider
                  id='slider-voice-volume'
                  sx={{
                    '& input[type="range"]': {
                      WebkitAppearance: 'slider-vertical',
                    },
                  }}
                  orientation="vertical"
                  value={volumeSliderVal}
                  onChange={handleVolumeChange}
                  aria-label="Temperature"
                  valueLabelDisplay="auto"
                  color="mainBlue"
                />
              </Box>
            }
          </Box> :
          <IconButton
            id='button-voice-chat-toggle-on'
            size='small'
            sx={{
              width: '40px',
              height: '40px',
              borderRadius: '40px',
              ml: 1.5,
              mr: '4px',
              border: `1px solid ${grey[400]}`,
              boxShadow: `0px 0px 4px ${grey[400]}`,
              '&:hover': {
                backgroundColor: 'transparent',
                '& svg': {
                  color: 'mainBlue.main'
                }
              }
            }}
            onClick={()=> {toggleVoiceChat(true)}}
          >
            <GraphicEqIcon
              sx={{
                color: `${grey[600]}`,
                transition: 'all 0.25s',
              }}
            />
          </IconButton>
      }
    </>
  )
}

export default VoiceChatToggle;
