import * as React from 'react';
import { SVGProps, memo } from 'react';
const OpenInNewIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.5 1.18555H6C3.23858 1.18555 1 3.42412 1 6.18555V13.1855C1 15.947 3.23858 18.1855 6 18.1855H13C15.7614 18.1855 18 15.947 18 13.1855V9.68555" stroke="black" stroke-width="2"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5001 1H18.0001C18.2763 1 18.5001 1.22386 18.5001 1.5V6C18.5001 6.27614 18.2763 6.5 18.0001 6.5C17.724 6.5 17.5001 6.27614 17.5001 6V2.70711L8.90926 11.298L8.20215 10.5909L16.793 2H13.5001C13.224 2 13.0001 1.77614 13.0001 1.5C13.0001 1.22386 13.224 1 13.5001 1Z" fill="black" stroke="black"/>
    </svg>

)


export default memo(OpenInNewIcon);