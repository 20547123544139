import * as React from 'react';
import { styled } from '@mui/material/styles';

import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

interface CustomTooltipProps extends TooltipProps {
  type?: 'error' | 'info';
}

const CustomTooltip = styled(
  ({ className, type = 'info', ...props }: CustomTooltipProps) => (
    <Tooltip
      {...props}
      //@ts-ignore
      type={type}
      classes={{ popper: className }}
      enterTouchDelay={0}
      placement='bottom-end'
    />
  )
)(({ theme, type = 'info' }) => {
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      background: type === 'error' ? 'red' : 'purple',
      color: '#fff',
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: type === 'error' ? 'red' : 'purple',
    },
  };
});

export default React.memo(CustomTooltip);
