import styles from './FAQ.module.css';
import React, { useState } from 'react';
import data from './data';
import Question from './Question';
import { Box, Container, Button, Grid } from '@mui/material';
import { Typography } from '@mui/material';

const FAQ = () => {
  const [category, setCategory] = useState('creators');
  const [questions, setQuestions] = useState(data.creators);

  const handleToggle = (type) => {
    setCategory(type);
    setQuestions(data[type]);
  };

  return (
    <>
      <Box>
        <Typography
          textAlign='center'
          variant='h2'
          sx={{ fontSize: {xs: '2.5rem', md: '2.75rem', lg: '3.25rem'}}}
          mt={1}
        >
          Frequently Asked Questions
        </Typography>
        <Box className={styles.buttonGroup} sx={{ml: {sm: 0}, mr: {sm: 0}}}>
          <Grid container spacing={2} justifyContent="center" sx={{maxWidth: '1216px'}}>
            <Grid item xs={4} md={12/5}>
              <Button
                variant={category === 'creators' ? 'contained' : 'outlined'}
                onClick={() => handleToggle('creators')}
                fullWidth
              >
                Creators
              </Button>
            </Grid>
            <Grid item xs={4} md={12/5}>
              <Button
                variant={category === 'parents' ? 'contained' : 'outlined'}
                onClick={() => handleToggle('parents')}
                fullWidth
              >
                Parents
              </Button>
            </Grid>
            <Grid item xs={4} md={12/5}>
              <Button
                variant={category === 'students' ? 'contained' : 'outlined'}
                onClick={() => handleToggle('students')}
                fullWidth
              >
                Students
              </Button>
            </Grid>
            <Grid item xs={4} md={12/5}>
              <Button
                variant={category === 'subscriptions' ? 'contained' : 'outlined'}
                onClick={() => handleToggle('subscriptions')}
                fullWidth
              >
                Subscriptions
              </Button>
            </Grid>
            <Grid item xs={4} md={12/5}>
              <Button
                variant={category === 'product' ? 'contained' : 'outlined'}
                onClick={() => handleToggle('product')}
                fullWidth
              >
                Product
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Container className={styles.container}>
          <section className={styles.info}>
            {questions.map((question) => (
              <Question key={question.id} {...question} />
            ))}
          </section>
        </Container>
      </Box>
    </>
  );
};

export default FAQ;
