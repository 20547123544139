import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '../../svg/CloseIcon';
import CreateMimioItem from './createMimioItem';
import styles from './fanCreatorList.module.css';

const CreateMimioContainer = () => {
  const [isVisible, setIsVisible] = React.useState(true);

  const handleBoxClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);

  return isVisible ? (
    <Box
      width='100%'
      display='flex'
      alignItems='center'
      textAlign='center'
      border='1px solid #D8D8D8'
      borderRadius='4px'
      p={3}
      className={styles.createMimioBox}
      position='relative'
    >
      <IconButton
        id='button-close-create-MIMIO'
        onClick={handleBoxClose}
        color='primary'
        sx={{
          position: 'absolute',
          alignSelf: 'flex-end',
          minWidth: '36px',
          minHeight: '36px',
          height: '36px',
          width: '36px',
          top: '16px',
          right: '16px',
        }}
      >
        <CloseIcon height='30px' width='30px' />
      </IconButton>
      <CreateMimioItem />
    </Box>
  ) : (
    <></>
  );
};
export default React.memo(CreateMimioContainer);
