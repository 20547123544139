import { useProfileData } from "../hooks/useProfileData";
import React, { Suspense, lazy } from "react";
import { Avatar, Box, Typography } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';

interface PremiumCreatorInfoProps {
  anchorEl: HTMLElement | (() => HTMLElement);
  requiredPremiumKey: string;
  premiumAccess: boolean;
  setShowLogin: React.Dispatch<React.SetStateAction<boolean>>;
}

const PremiumCreatorInfo: React.FC<PremiumCreatorInfoProps> = (props) => {
  const { anchorEl, requiredPremiumKey, premiumAccess, setShowLogin } = props;
  const { creatorProfileData } = useProfileData();
  const FrankSortinoNav = lazy(() => import('../views/customUIElements/FrankSortino'));

  if (creatorProfileData.userName === 'srinivasjaini' ||
    creatorProfileData.userName === 'sortino-chat'
  ) {
    return (
      <Suspense>
        <FrankSortinoNav
          anchorEl={anchorEl}
          featureKey={requiredPremiumKey}
          hasAccess={premiumAccess}
          setShowLogin={setShowLogin}
        />
      </Suspense>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {xs: 'row', sm: 'column'},
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        flexGrow: 1,
        p: 0,
        boxShadow: '0 4px 4px rgba(0,0,0,0.1)',
        maxHeight: {xs: '50px', sm: '100%'}
      }}
    >
      <Avatar
        src={creatorProfileData?.profilePhotoURL}
        alt={creatorProfileData?.fullName}
        sx={{
          m: {xs: 0, sm: 3},
          mb: {xs: 0, sm: 1},
          ml: {xs: 2, sm: 3},
          mr: {xs: 2, sm: 3},
          width: {xs: '40px', sm: 'auto'},
          height: {xs: '40px', sm: 'auto'},
          fontSize: '1.25rem',
          aspectRatio: 1,
        }}
      >
        {creatorProfileData?.fullName ? creatorProfileData?.fullName : ''}
      </Avatar>

      <Box display='flex' alignItems='center' mt={1} mb={1}>
        <Typography variant='h6' color='black'>
          {creatorProfileData?.fullName}
        </Typography>
        <VerifiedIcon sx={{ml: 1, fill: '#3EACEF'}}/>
      </Box>

      <Typography variant='body2' color='black' align={'center'} p={1}
        display={{xs: 'none', sm: 'block'}}
        sx={{
          flexGrow: 1,
          overflow: 'scroll',
        }}
      >
        {creatorProfileData?.shortBio}
      </Typography>
    </Box>
  )
}

export default PremiumCreatorInfo;
