import { Box, Button, Dialog, DialogContent, TextField } from "@mui/material";
import React, { useState } from "react";

interface TrialModalProps {
  showTrialModal: boolean;
  setShowTrialModal: React.Dispatch<React.SetStateAction<boolean>>;
  checkTrialCode: (code: string, setInputError: any) => void;
}

const TrialModal: React.FC<TrialModalProps> = (props) => {
  const { showTrialModal, setShowTrialModal, checkTrialCode } = props;
  const [ inputCode, setInputCode ] = useState<string>("");
  const [ inputError, setInputError ] = useState<boolean>(false);

  const handleCloseModal = () => {
    setInputCode("");
    setInputError(false);
    setShowTrialModal(false);
  }

  return (
    <Dialog
      open={showTrialModal}
      onClose={handleCloseModal}
    >
      <DialogContent>
        <Box
          component='form'
          noValidate
          autoComplete='off'
          onSubmit={(e) => {
            e.preventDefault();
            checkTrialCode(e.target[0].value, setInputError);
          }}
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
        >
          <TextField
            id=''
            label='Enter code:'
            variant='outlined'
            required
            error={inputError}
            helperText={inputError ? 'Invalid code' : ''}
            value={inputCode}
            onChange={(e) => setInputCode(e.target.value)}
          />
          <Box display='flex' sx={{mt: 2}}>
            <Button
              type='submit'
              variant="contained"
              sx={{
                height: '32px !important',
                padding: '6px 12px !important',
                borderRadius:'6px',
                fontSize: '14px',
                mr: 1,
              }}
              disabled={!inputCode}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              sx={{
                height: '32px !important',
                padding: '6px 12px !important',
                borderRadius:'6px',
                fontSize: '14px',
                ml: 1,
              }}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default TrialModal;
