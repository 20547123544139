import { Box, Typography } from '@mui/material'
import * as React from 'react'
import '../../assets/dashboard.css'
import { CloseRounded, DoNotDisturbAlt, FiberManualRecordRounded } from '@mui/icons-material';
import AttendWithChatIcon from '../../svg/AttendWithChatIcon';
import VideoChatIcon from '../../svg/VideoChatIcon';

interface MeethAttendanceTypeBoxProps {
    showAttendanceTypeBox: boolean,
    toggleAttendanceTypeBox: React.Dispatch<React.SetStateAction<boolean>>,
    event: any,
    handleAttendanceSelection: Function,
    attendanceType: any,
}

const MeethAttendanceTypeBox = (props: MeethAttendanceTypeBoxProps) => {
    const { toggleAttendanceTypeBox, event, handleAttendanceSelection, attendanceType } = props;
    const startTime = new Date(event.start.dateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const endTime = new Date(event.end.dateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });



    return(
        <Box
        sx={{
            position: 'absolute',
            height: '100%',
            bottom: 0,
            right: 0,
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            width: '100%',
            backgroundColor: 'white',
            borderRadius: '10px 10px 0px 0px',
          }}
        >
            <Box display={'flex'} justifyContent={'right'} sx={{cursor: 'pointer'}} onClick={() => toggleAttendanceTypeBox(false)}>
                <CloseRounded/>
            </Box>
            <Box padding={'20px'} display={'flex'} flexDirection={'column'}>
                <Typography fontWeight={600} color='#000'>{event.summary}</Typography>
                <Typography color='#000000'>{startTime} - {endTime}</Typography>
            </Box>
            <Box display={'flex'} flexDirection={'column'}>
                <Typography color='#000000' marginTop='10px' borderBottom={'1px solid rgba(234, 234, 234, 1)'} padding={'20px'}>Attend this Meeting Via:</Typography>
                <Box
                    borderBottom={'1px solid rgba(234, 234, 234, 1)'}
                    padding={'10px 20px'}
                    bgcolor={attendanceType[event.id] === 'Chat' && 'rgba(0, 163, 255, 1)'}
                    onClick={() => handleAttendanceSelection(event.id, 'Chat')}
                    color={attendanceType[event.id] === 'Chat' && 'white'}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        alignItems: 'center',
                        cursor: 'pointer',
                        '&:hover': {
                            bgcolor:attendanceType[event.id] !== 'Chat' && '#eeeeee'
                        },
                        transition: 'background-color 150ms ease',
                    }}
                >
                    <FiberManualRecordRounded sx={{width: '10px', opacity: attendanceType[event.id] === 'Chat' ? 1: 0}}/>
                    <AttendWithChatIcon color={attendanceType[event.id] === 'Chat' && 'white'}/>
                    <Typography color={attendanceType[event.id] === 'Chat' ? 'white': '#6C7072'}>Chat</Typography>
                </Box>
                <Box
                    borderBottom={'1px solid rgba(234, 234, 234, 1)'}
                    padding={'10px 20px'}
                    bgcolor={attendanceType[event.id] === 'Video' && 'rgba(0, 163, 255, 1)'}
                    onClick={() => handleAttendanceSelection(event.id, 'Video')}
                    color={attendanceType[event.id] === 'Video' && 'white'}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        alignItems: 'center',
                        cursor: 'pointer',
                        '&:hover': {
                            bgcolor:attendanceType[event.id] !== 'Video' && '#eeeeee'
                        },
                        transition: 'background-color 150ms ease',
                    }}
                >
                    <FiberManualRecordRounded sx={{width: '10px', opacity: attendanceType[event.id] === 'Video' ? 1: 0}}/>
                    <VideoChatIcon color={attendanceType[event.id] === 'Video' && 'white'}/>
                    <Typography color={attendanceType[event.id] === 'Video' ? 'white': '#6C7072'}>Video</Typography>
                </Box>
                <Box
                    borderBottom={'1px solid rgba(234, 234, 234, 1)'}
                    padding={'10px 20px'}
                    bgcolor={attendanceType[event.id] === 'Not Attending' && 'rgba(0, 163, 255, 1)'}
                    onClick={() => handleAttendanceSelection(event.id, 'Not Attending')}
                    color={attendanceType[event.id] === 'Not Attending' && 'white'}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        alignItems: 'center',
                        cursor: 'pointer',
                        '&:hover': {
                            bgcolor:attendanceType[event.id] !== 'Not Attending' && '#eeeeee'
                        },
                        transition: 'background-color 150ms ease',
                    }}
                >
                    <FiberManualRecordRounded sx={{width: '10px', opacity: attendanceType[event.id] === 'Not Attending' ? 1: 0}}/>
                    <DoNotDisturbAlt sx={{color: attendanceType[event.id] === 'Not Attending' ? 'white':'#6C7072'}}/>
                    <Typography color={attendanceType[event.id] === 'Not Attending' ? 'white': '#6C7072'}>Not Attending</Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default MeethAttendanceTypeBox
