import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import timeoutImage from '../assets/images/timeout.png';
import { ERROR_MESSAGES } from '../config/errors';

interface timeoutModalProps {
  showTimeoutModal: boolean,
  setShowTimeoutModal: (val: boolean) => void,
  errorCode: number,
}

const TimeoutModal: React.FC<timeoutModalProps> = (props) => {
  let {showTimeoutModal, setShowTimeoutModal, errorCode} = props;
  const [message, setMessage] = useState("");

  React.useEffect(()=> {
    let tmp = ERROR_MESSAGES[errorCode?.toString()];
    tmp ||= ERROR_MESSAGES['default'];
    setMessage(tmp);
  }, [errorCode])

  const handleCloseTimeoutModal = () => {
    setShowTimeoutModal(false)
  };

  return (
    <Dialog
      open={showTimeoutModal}
      onClose={handleCloseTimeoutModal}
    >
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: '300px',
            padding: '15px'
          }}
        >
          <img src={timeoutImage} alt='timeout' width='75%' />
          <Typography
            fontSize={'1.1rem'}
            fontWeight={700}
            mb={1}
            align='center'
          >
            {'A Problem Occurred'}
          </Typography>
          <Typography
            fontSize={'1rem'}
            fontWeight={500}
            mb={1}
            align='center'
          >
            {message}
          </Typography>
          <Button
            sx={{
              flex: 1,
              py: 1,
              width: '100%',
              mt: 1
            }}
            size='small'
            onClick={handleCloseTimeoutModal}
            variant='outlined'
          >
            OK
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
};

export default TimeoutModal;
