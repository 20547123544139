import React, { createContext, useRef, memo } from 'react';

interface SortByCtxType {
  sortBy: React.MutableRefObject<string>,
}

const enableCreatorSortBy = process.env.REACT_APP_ENABLE_CREATOR_SORT_BY === "true";

export const SortByContext = createContext<SortByCtxType>({sortBy: null});

export const SortByProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const sortBy = useRef<string>(enableCreatorSortBy ? 'topFans' : 'aToz');

  return (
    <SortByContext.Provider value={{ sortBy }}>
      {children}
    </SortByContext.Provider>
  );
};

export default memo(SortByProvider);
