import * as React from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

interface PublicRouteProps {
  children: React.ReactElement<any, any>;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
  const [isNavigationLoading] = React.useState(false);

  return isNavigationLoading ? (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress size={40} />
    </Box>
  ) : (
    children
  );
};

export default PublicRoute;
