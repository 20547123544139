import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { useAuth } from '../../hooks/useAuth';

export default function LogoutAuthUser() {
  const { logoutUser } = useAuth();

  React.useEffect(() => {
    logoutUser();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        padding: '1.5rem',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress size={30} />
    </Box>
  );
}
