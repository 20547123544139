import React from 'react';
import styles from './collapse-header.module.css';

interface CollapseHeaderMessageProps {
  children?: any,
  isOpen?: boolean,
  isWidget?: boolean,
}

const CollapseHeaderMessage = (props: CollapseHeaderMessageProps) => {
  const childContent = React.useRef(null);
  const [childHeight, setChildHeight] = React.useState(undefined);
  const { children, isOpen, isWidget=false } = props;

  React.useEffect(() => {
    setChildHeight(childContent?.current?.clientHeight || 0);
  }, [childContent?.current?.clientHeight]);

  return (
    <div
      className={styles.collapse}
      style={{
        maxHeight: isWidget ? isOpen ? '100%' : 0 : isOpen ? childHeight : 0,
        height: isWidget ? '100%' : 'auto',
        opacity: isOpen ? 1 : 0,
        display: isWidget ? 'flex' : 'block',
        justifyContent: 'center', 
      }}
    >
      <div className={styles.collapseDiv} ref={childContent}>
        {children}
      </div>
    </div>
  );
};

export default React.memo(CollapseHeaderMessage);
