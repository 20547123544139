import { auth } from '../../utils/firebase';
import { sendEmailVerification } from 'firebase/auth';
import { toast } from 'react-toastify';
import { FirebaseError } from 'firebase/app';

// Function to handle sending of Verification email to the user's email
export const handleVerifyEmail = async () => {
  try {
    await sendEmailVerification(auth.currentUser);
    toast.success('Verification email has been sent');
  } catch (error) {
    const firebaseError = (error as FirebaseError)?.code;
    switch (firebaseError) {
      case 'auth/too-many-requests':
        toast.error('Too many requests sent. Please try again after a minute.');
        break;
      default:
        toast.error('Sorry there was an error. Please try again later.');
        console.error(error);
    }
  }
};

export const verifyFileSize = (fileSizeInKB, maxSizeInKB) => {
  if (fileSizeInKB === 0) {
    toast.error('Selected file is empty. Please select a non-empty file.');
    return false;
  }
  if (fileSizeInKB >= maxSizeInKB) {
    toast.error(
      'File too Big, please select a file less than ' +
        Math.floor(maxSizeInKB / 1000) +
        ' MB'
    );
    return false;
  }
  return true;
};
