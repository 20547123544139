import React, { useRef, useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams, Navigate } from 'react-router-dom';

import { DocumentData, serverTimestamp, Timestamp } from 'firebase/firestore';
import { auth } from '../../utils/firebase';

import { MessageList, MessageSeparator, } from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button,
         Checkbox, Dialog, DialogActions, DialogContent, Divider, Grid,
         LinearProgress, Tooltip, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Lottie from 'lottie-react';
import { toast } from 'react-toastify';

import moment from 'moment';
import { useProfileData } from '../../hooks/useProfileData';
import { useAuth } from '../../hooks/useAuth';
import { fetchMessagesData, saveMessagesData, updateMessagesData, findAndReplaceLinks } from '../../utils/helper';
import { clearChat, editedMessageResponse, fetchResultsByStudentAndCourse, getCourseInfo, sendMessageInCourse, fetchCourseProgress, updateCourseResults, sendCourseCompletionEmail, fetchCourseIntroMessage } from '../../utils/api';
import MessageItem from './messageItem';
import { CourseMessageRequest, MessageDataType, SendMessageResponse, } from './messages.types';
import { USER_TYPE } from '../../config/const';
import UserResponseForm from './userResponseForm';
import ContactUsModal from './contactUsModal';
import SignInSignUpModal from './signSignUpModal';
import ShareMessagesSection from './shareMessagesSection';
import TimeoutModal from '../../component/timeoutModal';
import GenericModal from '../../component/GenericModal';
import { CONTACT_US_MAIL } from '../../config/const';
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";
import { Citation } from './messages.types';
import DefaultUserImage from '../../assets/images/default-avatar.png';
import CompletionImage from '../../assets/animations/coursecomplete.json';
import AssignmentCompletionImage from '../../assets/animations/assignmentComplete.json';
import TextChatInput from './textChatInput';
import TypingAnimation from './TypingAnimation';
import CustomTypingIndicator from './CustomTypingIndicator';
import { usePreloadedIndex } from '../../hooks/usePreloadedIndex';

import VoiceChat from './voiceChat';
import { useSpeechRecognition } from 'react-speech-recognition';
import VoiceChatToggle from '../../component/voiceChatToggle';
import MicGuy from '../../assets/images/micguy.png';

const enableDevTools = process.env.REACT_APP_ENABLE_DEVTOOLS === 'true';
const enableVoiceFeature = process.env.REACT_APP_ENABLE_VOICE === 'true';
const skipTypingAnimation = process.env.REACT_APP_SKIP_TYPING_ANIMATION === 'true';
const showCourses = process.env.REACT_APP_SHOW_MY_COURSE === "true";

const passingScore = 100; // percentage required to pass assessment

const TakeCourse: React.FC = () => {
  const location = useLocation();
  const [clearChatLoading, setClearChatLoading] = useState(false);
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const [showGenericModal, setShowGenericModal] = useState(false);
  const [genericModalData, setGenericModalData] = useState(null);
  const [errorCode, setErrorCode] = useState<number>();
  const eventTracker = useAnalyticsEventTracker();
  const [citations, setCitations] = useState([]);
  const [messages, setMessages] = useState<any>(null);
  const [isTyping, setIsTyping] = useState(false);
  const isLoading = useRef<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [showActionMenu, setShowActionMenu] = useState<HTMLElement>(null);
  const [formData, setFormData] = useState<Record<any, any>>(null);
  const [handleModal, setHandleModal] = useState<'edit' | 'feedback' | null>(null);
  const [handleContactModal, setHandleContactModal] = useState<boolean>(false);
  const [handleSignInModal, setHandleSignInModal] = useState<boolean>(false);
  const [selectedMessages, setSelectedMessages] = useState<MessageDataType[]>([]);
  const [showSelectMessages, setShowSelectMessages] = useState(false);
  const resultsDoc = useRef(null);
  const [progressPercent, setProgressPercent] = useState(0);
  const courseData = useRef(null);
  const isSelfChat = false;
  const sendDisabled = useRef<boolean>(true);
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const { courseId } = useParams();
  const [checkedTopics, setCheckedTopics] = useState({});
  const [isAssignment, setIsAssignment] = useState(false);
  const [assignmentQuestions, setAssignmentQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [assignmentResults, setAssignmentResults] = useState([]); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [assessmentSelected, setAssessmentSelected] = useState(false);
  const [currentModuleIndex, setCurrentModuleIndex] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [score, setScore] = useState(0);
  const [scorePercentage, setScorePercentage] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [nextTopicToHighlight, setNextTopicToHighlight] = useState(null);
  const questionListRef = useRef(null);
  const [expandedModule, setExpandedModule] = useState<string | false>(false);
  const [showCompletionDialog, setShowCompletionDialog] = useState(false);
  const [showAssignmentCompletionDialog, setShowAssignmentCompletionDialog] = useState(false);
  const [courseCompleted, setCourseCompleted] = useState(false);
  const [aiTypingMessage, setAiTypingMessage] = useState(null);
  const [useVoiceChat, setUseVoiceChat] = useState(false);
  const { browserSupportsSpeechRecognition } = useSpeechRecognition();
  const [isAwaitingMessage, setIsAwaitingMessage] = useState<boolean>(false);
  const [shouldPlayMessage, setShouldPlayMessage] = useState<boolean>(false);
  const [chips, setChips] = useState<string[]>([]);


  const initialPrompt = "";

  const { authUser, authLoading, isAnonymousUser, } = useAuth();
  const { fetchCreatorDataFromUserId, creatorProfileData, } = useProfileData();
  const [ preloadedIndex, prefetchPromise ] = usePreloadedIndex();

  interface TopicProgress {
    [key: string]: boolean;
  }

  interface ModuleProgress {
    completed: boolean;
    started: boolean;
    topics: TopicProgress;
  }

  const returnRoomId = () => {
    return `course_${courseId}.${authUser?.documentId}`;
  };


  useEffect(() => {
    if (messages?.length > 0) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage.userType === USER_TYPE.BOT) {
        const storedChips = localStorage.getItem('lastBotChips');
        if (storedChips) {
          setChips(JSON.parse(storedChips));
        }
      } else {
        setChips([]); 
      }
    }
  }, [messages]);

  useEffect(() => {
    // on mount, flip use voice chat to true if necessary
    setUseVoiceChat(location?.state?.useVoiceChat || false);
  }, [location?.state?.useVoiceChat]);

  useEffect(() => {
    if (questionListRef.current) {
      questionListRef.current.scrollTo({
        top: questionListRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [currentQuestionIndex]);

  useEffect(() => {
    if (scorePercentage === passingScore) {
      setShowAssignmentCompletionDialog(true);
    }
  }, [scorePercentage]);

  useEffect(() => {
    if (nextTopicToHighlight) {
      const moduleIndex = nextTopicToHighlight.split('-')[0].split('_')[1];
      setExpandedModule(`module-${moduleIndex}`);
    }
  }, [nextTopicToHighlight]);

  // Verify if the user is logged in and email is not verified then return to verify email
  // or username not exists then redirect to create-profile screen
  useEffect(() => {
    if (authLoading) {
      isLoading.current = true;
    } else {
      if (
        authUser?.documentId &&
        auth?.currentUser?.emailVerified !== undefined &&
        auth.currentUser.emailVerified === false
      ) {
        toast.error('Please verify your email to continue');
        navigate('/verify-email');
      } else if (authUser?.documentId && !authUser?.userName) {
        navigate('/create-profile');
      }
    }
  }, [authUser, auth?.currentUser, authLoading]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!authLoading && courseId && (showCourses || authUser?.beta_tester)) {
      if (!authUser) {
        navigate(`/courses/${courseId}`);
        toast.error('Please login to take course');
      } else {
        loadCourseData();
        // fetchAndDisplayResults(); // Fetch and display results if they exist
      }
    }
  }, [authUser, courseId, authLoading]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !authLoading &&
      !isLoading.current &&
      messages?.length === 0 &&
      !aiTypingMessage &&
      !isTyping &&
      (showCourses || authUser?.beta_tester) &&
      !useVoiceChat
    ) {
      handleMessageSend(initialPrompt, true);
    }
  }, [messages]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (courseCompleted) {
      fetchCourseProgress(returnRoomId())
        .then(progress => {
          handleProgressData(progress);
          fetchNextPage(); // Ensure the previous messages are retained
        });
    }
  }, [courseCompleted]); //eslint-disable-line react-hooks/exhaustive-deps

  if (!(showCourses || authUser?.beta_tester || authLoading)) {
    return <Navigate to="/creators"/>
  }


  const handleCheckboxChange = async (moduleIndex, topicIndex, totalTopics, checked) => {
    setCheckedTopics(prevState => {
      const newChecked = { ...prevState };

      if (checked) {
        for (let i = 0; i <= topicIndex; i++) {
          newChecked[`module_${moduleIndex}-${i}`] = true;
        }
      } else {
        for (let i = topicIndex; i < totalTopics; i++) {
          newChecked[`module_${moduleIndex}-${i}`] = false;
        }
      }

      // Check if all topics in the current module are selected
      const allTopicsSelected = Object.keys(newChecked).filter(key => key.startsWith(`module_${moduleIndex}-`) && newChecked[key]).length === totalTopics;
      // console.log(`Module ${moduleIndex + 1} all topics selected:`, allTopicsSelected);

      if (allTopicsSelected) {
        newChecked[`module_${moduleIndex}-completed`] = true;
        newChecked[`module_${moduleIndex}-assessment`] = true;
        // console.log(`Module ${moduleIndex + 1} marked as completed.`);

        // Check if the next module has not started
        const nextModuleIndex = moduleIndex + 1;
        const nextModuleStarted = newChecked[`module_${nextModuleIndex}-started`] || false;

        if (!nextModuleStarted) {
          setAssessmentSelected(true);
          setCurrentModuleIndex(moduleIndex);
          // console.log("Assessment selected and current module index set:", moduleIndex);
        }
      } else {
        newChecked[`module_${moduleIndex}-completed`] = false;
        newChecked[`module_${moduleIndex}-assessment`] = false;
      }

      return newChecked;
    });
  };

  const handleProgressData = (progressData: any) => {
    const checked: Record<string, boolean> = {};
    let totalTopics = 0;
    let completedTopics = 0;
    let nextTopicToHighlight = null;

    // Sort by module first
    let sortedModules = [];
    for (const [key, value] of Object.entries(progressData?.progress)) {
      if (key.startsWith('module_') && typeof value === 'object' && 'topics' in value) {
        sortedModules.push(key);
      }
    }

    sortedModules.sort((a, b) => {
      return parseInt(a.split("_")[1]) - parseInt(b.split("_")[1]);
    });

    for (const moduleName of sortedModules) {
      const moduleProgress = progressData.progress[moduleName] as ModuleProgress;
      const curModNum = parseInt(moduleName.split('_')[1]); // Extract module number
      totalTopics += Object.keys(moduleProgress.topics).length;
      completedTopics += Object.values(moduleProgress.topics).filter(topic => topic === true).length;

      for (const [topicKey, topicValue] of Object.entries(moduleProgress.topics)) {
        if (nextTopicToHighlight) {
          checked[`${moduleName}-${topicKey}`] = false;
        } else {
          checked[`${moduleName}-${topicKey}`] = topicValue;
          if (topicValue === false) {
            nextTopicToHighlight = `${moduleName}-${topicKey}`;
            const ind = curModNum - 1;
            setCurrentModuleIndex(ind);
          }
        }
      }

      // Check assignment completion status for the current module
      if (moduleProgress.completed && courseData.current && courseData.current.modules[curModNum - 1]) {
        const numQuestions = courseData.current.modules[curModNum - 1].questions?.length || 0;
        const assignmentCompleted = resultsDoc.current?.module?.[curModNum]?.score / numQuestions * 100 >= passingScore;

        if (!assignmentCompleted) {
          nextTopicToHighlight = `${moduleName}-assessment`;
          const ind = curModNum - 1;
          setCurrentModuleIndex(ind);
          setAssessmentSelected(true);
        } else {
          checked[`${moduleName}-assessment`] = true;
        }
      }
    }

    const calculatedProgressPercent = totalTopics > 0 ? (completedTopics / totalTopics) * 100 : 0;
    setCheckedTopics(checked);
    setProgressPercent(calculatedProgressPercent);
    setNextTopicToHighlight(nextTopicToHighlight);
    if (progressData?.course_completed) {
      setCourseCompleted(progressData.course_completed);
    }
  };

  const loadCourseData = () => {
    getCourseInfo(courseId)
      .then(resp => {
        if (resp.ok) {
          return resp.json()
        } else {
          // To-do: handle bad response from getCourseInfo
        }
      })
      .then(data => {
        courseData.current = (data)
        isLoading.current = true;

        // Check for course enrollment:
        return fetchResultsByStudentAndCourse(authUser?.documentId, courseId)
      })
      .then(results => {
        if (Object.keys(results[0]).length){
          // console.log(results[0])
          resultsDoc.current = results[0]
        } else {
          // allow instructors to take own course
          if (!(courseData.current?.creator_id === authUser?.documentId)) {
            // To-do: allow devs to bypass enrollment entirely
            // need api route for results doc creation

            // kick out users who are not enrolled
            navigate(`/courses/${courseId}`)
          } else {
            // To-do: create a room
          }
        }
        return fetchCourseProgress(returnRoomId());
      })
      .then(progress => {
        const progressData = progress.progress;
        if (progressData?.course_completed) {
          setCourseCompleted(progressData.course_completed);
      }
        handleProgressData(progress)
        return fetchCreatorDataFromUserId(courseData.current.creator_id)
      })
      .then(() => {
        isLoading.current = false;
        fetchNextPage();
      })
      .catch(err => {
        // To-do: error handling for failed course fetch
      })
  };

  const handleClearChatClick = async () => {
    try {
      //This will disable the "Clear chat" button
      setClearChatLoading(true);

      const response = await clearChat(returnRoomId());
      eventTracker(`delete-conversation ${returnRoomId()}`);
      let data = await response.json();
      console.log(data?.message)

      //This will re-enable the "Clear chat" button
      setClearChatLoading(false);
      //Refreshing window
      window.location.reload();
    } catch (error) {
      console.error('API call error when deleting chat:', error);
      eventTracker(`delete-conversation failed ${returnRoomId()}`);
      setClearChatLoading(false);
    }
  };



  const fetchNextPage = async (loadingMore = false) => {
    if (isLoading.current || isLoadingMore) {
      return;
    }

    if (loadingMore) {
      setIsLoadingMore(true);
    } else {
      isLoading.current = true;
    }
    try {
      // To-do: change this to api call
      const querySnapshot = await fetchMessagesData(returnRoomId());

      const nextPageDocuments: DocumentData[] = querySnapshot.docs.map(
        (doc: any) => ({
          id: doc.id,
          documentId: doc.id,
          ...doc.data(),
        })
      );

      setMessages((prevDocuments: any) => [
        ...nextPageDocuments.reverse(),
        // ...(prevDocuments || []),
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
      // To-do: Handle the error
    } finally {
      if (loadingMore) {
        setIsLoadingMore(false);
      } else {
        isLoading.current = false;
      }
    }
  };

  const convertMessageDataType = (
    message: string,
    userType: string,
    userId: string,
    images?: string[],
    image_url?: string,
    image_urls?: string[],
    tempUserId?: number,
    isFinalMessage: boolean = false,
    documentId?: string,
    citations?: Citation[],
  ) => {
    const currentTimeStamp = Timestamp.fromDate(new Date());
    let messageData: MessageDataType = {
      senderId: userId,
      message,
      userType,
      sentTime: currentTimeStamp,
      direction: userType === USER_TYPE.BOT ? 'incoming' : 'outgoing',
      likeDislike: 0,
      roomId: returnRoomId(),
      images: images ?? [],
      image_url: image_url ?? null,
      image_urls: image_urls ?? null,
      tempUserId: tempUserId ?? null,
      citations: citations ?? [],
    };
    if (documentId) {
      messageData.documentId = documentId;
    }
    return messageData;
  };

  const saveMessages = async (
    message: string,
    userType: string,
    userId: string,
    images?: string[],
    image_url?: string,
    image_urls?: string[],
    tempUserId?: number,
    isFinalMessage: boolean = false,
    documentId?: string,
    citations?: Citation[],
  ) => {
    const currentTimeStamp = Timestamp.fromDate(new Date());
    let messageData: MessageDataType = {
      senderId: userId,
      message,
      userType,
      sentTime: currentTimeStamp,
      direction: userType === USER_TYPE.BOT ? 'incoming' : 'outgoing',
      likeDislike: 0,
      roomId: returnRoomId(),
      images: images ?? [],
      image_url: image_url ?? null,
      image_urls: image_urls ?? null,
      tempUserId: tempUserId ?? null,
      citations: citations ?? [],
    };

    if (userType === USER_TYPE.BOT && !isFinalMessage) {
      // AI typing simulation (not the final AI message)
      setMessages(prevMessages => {
        let existingIndex = -1;
        if (prevMessages) {
          existingIndex = prevMessages.findIndex(m => m.tempUserId === tempUserId);
        }
        if (existingIndex !== -1) {
          // Update the existing AI message with new content
          const updatedMessages = [...prevMessages];
          updatedMessages[existingIndex].message = message;
          updatedMessages[existingIndex].documentId = documentId;
          return updatedMessages;
        } else {
          // Add as a new AI message (should rarely happen)
          return (prevMessages ?
            [...prevMessages, { ...messageData, isAiTyping: true }] :
            [{ ...messageData, isAiTyping: true }]);
        }
      });
    } else {
      // Save the message to the database for user messages and final AI message
      const saveMessageResponse = await saveMessagesData({
        ...messageData,
        sentTime: serverTimestamp(),
        isAiTyping: false
      });

      // Update or add the saved message in state
      setMessages(prevMessages => {
        prevMessages ||= [];
        if (tempUserId) {
          // Find and update the existing message with the saved message data
          return prevMessages.map(messageItem =>
            messageItem.tempUserId === tempUserId ? { ...messageItem, ...saveMessageResponse, documentId: saveMessageResponse.documentId, sentTime: messageItem.sentTime } : messageItem
          );
        } else {
          // Add the saved message as a new message
          return [...prevMessages, { ...saveMessageResponse, documentId: saveMessageResponse.documentId }];
        }
      });

      return saveMessageResponse.documentId;
    }
  };

  const handleMessageSend = async (
    userMessage: string,
    firstMessage: boolean = false,
    isVoiceMessage: boolean = false,
  ) => {
    setIsTyping(true);
    sendDisabled.current = true;
    const TempUserId = Date.now();  // Unique ID for the user's message
    const aiTempUserId = TempUserId + 1;  // Unique ID for the AI's typing simulation

    try {
      // Save user message
      if (!firstMessage) {
        let userMessageData: MessageDataType = {
          senderId: authUser?.documentId || '',
          message: userMessage,
          userType: authUser?.userType || '',
          sentTime: Timestamp.fromDate(new Date()), // Convert to Firestore Timestamp if needed
          direction: 'outgoing', // Assuming this is for outgoing messages
          likeDislike: 0,
          roomId: returnRoomId(),
          tempUserId: TempUserId
        };

        // Add user message to state
        setMessages(prevMessages => [...prevMessages, userMessageData]);

        // Save user message to database
        saveMessages(
          userMessage,
          authUser?.userType || '',
          authUser?.documentId || '',
          null,
          null,
          null,
          TempUserId,
          true,  // Final message from the user
          null,
          null
        );

        if (!preloadedIndex) {
          if (prefetchPromise) {
            try {
              if (enableDevTools) {
                console.log('Still awaiting prefetch...')
              }
              await prefetchPromise
              if (enableDevTools) {
                console.log('Prefetch complete, sending message now')
              }
            } catch (error) {
              console.log(error)
            }
          }
        }
      }

      let userData: CourseMessageRequest = {
        course_id: courseId,
        query: userMessage,
        room_id: returnRoomId(),
      };

      let response;
      if (firstMessage && userMessage === initialPrompt) {
        response = await fetchCourseIntroMessage(courseId, false);
      } else {
        response = await sendMessageInCourse(userData);
      }

      if (response?.status === 200) {
        eventTracker('course-message-send');
        const messageResp: SendMessageResponse = await response.json();
        if (enableDevTools) {
          console.log(messageResp)
        }

        const newChips = messageResp?.chips || [];
        setChips(newChips);

        localStorage.setItem('lastBotChips', JSON.stringify(newChips));

        if (messageResp?.citations && messageResp.citations.length > 0) {
          setCitations(messageResp.citations);
        }
        let fullMessage = findAndReplaceLinks(messageResp?.response);
        let images = messageResp?.images || [];
        let image_url = messageResp?.image_url || null;
        let image_urls = messageResp?.image_urls || null;

        let senderId = creatorProfileData?.documentId;
        let citations = messageResp.citations;

        if (messageResp.progress) {
          handleProgressData(messageResp)
        }

        if (isVoiceMessage || skipTypingAnimation) {
          let documentId = await saveMessages(fullMessage, USER_TYPE.BOT, senderId, images, image_url, image_urls, aiTempUserId, false, null, citations);
          await saveMessages(fullMessage, USER_TYPE.BOT, senderId, images, image_url, image_urls, aiTempUserId, true, documentId, citations);
          setIsTyping(false);
          sendDisabled.current = false;
          return fullMessage;
        }

        // Save the complete AI response before starting typing simulation
        let documentId = await saveMessages(fullMessage, USER_TYPE.BOT, senderId, images, image_url, image_urls, aiTempUserId, true, null, citations);

        setIsTyping(false)
        setAiTypingMessage(convertMessageDataType(fullMessage, USER_TYPE.BOT, senderId, images, image_url, image_urls, aiTempUserId, true, documentId, citations))

      } else {
        setIsTyping(false);
        sendDisabled.current = false;

        setErrorCode(response?.status || 0);
        setShowTimeoutModal(true);
        throw new Error(`HTTP error, status = ${response?.status}`);
      }
    } catch (error) {
      eventTracker('message-send failed');
      console.log(error);
      setIsTyping(false);
      sendDisabled.current = false;
      setErrorCode(0); // set as generic error
      setShowTimeoutModal(true);
    }
  };

  const handleChipClick = async (label: string) => {
    try {
      setChips([]);
      localStorage.removeItem('lastBotChips');

      const responseMessage = await handleMessageSend(label);

      if (responseMessage?.chips) {
        setChips(responseMessage.chips);
        console.log("Chips received:", responseMessage.chips);
        localStorage.setItem('lastBotChips', JSON.stringify(responseMessage.chips));
      }
    } catch (error) {
      console.error("Error handling chip click:", error);
      setChips([]);
    }
  };


  const handleLikeDislikeMessage = async (
    documentId: string,
    value: number
  ) => {
    let existingValue = 0;
    const updatedMsg = messages.map((messageItem) => {
      if (messageItem?.documentId === documentId) {
        existingValue = messageItem.likeDislike;
        if (existingValue === value) {
          messageItem.likeDislike = 0;
          value = 0;
        } else {
          messageItem.likeDislike = value;
        }
      }
      return messageItem;
    });
    setMessages(updatedMsg);
    if (!isAnonymousUser) {
      const updateMessageResp = await updateMessagesData(documentId, {
        likeDislike: value,
      });
      if (!updateMessageResp?.documentId) {
        const updatedMsg = messages.map((messageItem) =>
          messageItem?.documentId === documentId
            ? {
              ...messageItem,
              likeDislike: existingValue,
            }
            : messageItem
        );
        setMessages(updatedMsg);
      }
    }
  };

  const handleFormDialogResponse = async (values) => {
    setIsFormSubmitting(true);
    const { documentId } = formData;
    const userResponse = values?.response;
    if (documentId && userResponse) {
      let userFormData: any = {};
      if (handleModal === 'feedback') {
        userFormData.feedback = userResponse;
      } else {
        userFormData.editedMessage = findAndReplaceLinks(userResponse);
        userFormData.question = returnUserQuesForEditedMsg(documentId);
        userFormData.is_indexed = false;
      }
      if (isAnonymousUser) {
        updateExistingMessages(documentId, findAndReplaceLinks(userResponse));
      } else {
        const updateMessageResp = await updateMessagesData(
          documentId,
          userFormData
        );
        if (handleModal === 'edit') {
          try {
            eventTracker('message-edit');
            editedMessageResponse({ message_id: documentId });
          } catch (e) {
            eventTracker('message-edit failed');
            throw e;
          }
        }
        if (updateMessageResp?.documentId) {
          updateExistingMessages(documentId, findAndReplaceLinks(userResponse));
        }
      }

      setIsFormSubmitting(false);
      if (handleModal === 'feedback') {
        toast.success('Feedback submitted');
      }
      handleFormDialogClose();
    }
  };

  const toggleVoiceChat = (newSetting: boolean) => {
    setUseVoiceChat(newSetting);
  };

  const showCheckPermissionsModal = () => {
    let data = {
      title: "Check your permissions",
      message: "We noticed a problem. If your voice is not recording, please make sure you have given microphone permissions.",
      imgSrc: MicGuy,
      buttons: [{
        text: "OK",
        action: () => {setShowGenericModal(false);},
        variant: "contained",
      }, {
        text: "Email Soopra",
        action: () => {
          let link = `mailto:${CONTACT_US_MAIL}&subject=${encodeURIComponent("Voice feature isn't working")}`;
          window.location.href = link;
          setShowGenericModal(false);
        },
        variant: "outlined",
      }]
    };
    setGenericModalData(data);
    setShowGenericModal(true);
  }

  const updateExistingMessages = (documentId: string, userResponse: any) => {
    const updatedMsg = messages.map((messageItem) => {
      if (messageItem?.documentId === documentId) {
        if (handleModal === 'feedback') {
          messageItem.feedback = userResponse;
        } else {
          messageItem.editedMessage = userResponse;
        }
      }
      return messageItem;
    });
    setMessages(updatedMsg);
  };

  const returnUserQuesForEditedMsg = (documentId: string) => {
    let question = '';
    const currentMsgInd = messages.findIndex(
      (msgItem) => msgItem.documentId === documentId
    );
    if (currentMsgInd !== -1) {
      for (let i = currentMsgInd - 1; i >= 0; i--) {
        if (messages[i]?.userType === USER_TYPE.CREATOR) {
          question = messages[i].message;
          break;
        }
      }
    }
    return question;
  };

  const handleFormDialogClose = () => {
    setShowActionMenu(null);
    setHandleModal(null);
    setFormData(null);
  };

  const handleContactUsModalClose = () => {
    setHandleContactModal(false);
  };

  const handleShareButtonClick = (selMessage: any) => {
    if (selectedMessages?.length >= 1) {
      const selMsgIndex = selectedMessages?.findIndex(
        (messageItem) => messageItem.documentId === selMessage?.documentId
      );
      if (selMsgIndex !== -1) {
        const updatedMsgs = selectedMessages;
        updatedMsgs.splice(selMsgIndex, 1);
        setSelectedMessages([...updatedMsgs]);
        return;
      }
    }
    setSelectedMessages([...(selectedMessages || []), selMessage]);
  };

  const handleCurrentCheckboxChecked = (messageDocId: string) => {
    return selectedMessages?.some(
      (messageItem) => messageItem.documentId === messageDocId
    );
  };

  const resetMessageSelection = () => {
    setSelectedMessages([]);
    setShowSelectMessages(false);
  };

  const handleStartAssignment = () => {
    if (currentModuleIndex !== null) {
      const selectedModule = courseData.current.modules[currentModuleIndex];
      const shuffledQuestions = selectedModule.questions.map(question => ({
        ...question,
        options: shuffleArray(question.options)
      }));
      setAssignmentQuestions(shuffledQuestions || []);
      setIsAssignment(true);
      setCurrentQuestionIndex(0);
      setAssignmentResults([]);
    }
  };

  const shuffleArray = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };

  const handleAnswerSelect = (questionIndex, selectedOption) => {
    setSelectedAnswers(prevState => ({
        ...prevState,
        [questionIndex]: selectedOption
    }));

    // Move to the next question only if it's not already answered
  if (questionIndex === currentQuestionIndex && questionIndex < assignmentQuestions.length - 1) {
    setCurrentQuestionIndex(prevIndex => prevIndex + 1);
  }
};


  const calculateScore = (results) => {
    const correctAnswers = results.filter(result => result.isCorrect).length;
    setScorePercentage(correctAnswers/results.length*100)
    setScore(correctAnswers);
  };

  const handleEndAssignment = async () => {
    const results = assignmentQuestions.map((question, index) => {
      const selectedOption = selectedAnswers[index];
      const correctOption = question.options.find(option => option.isCorrect).option;
      return {
        question: question.question,
        selectedOption,
        correctOption,
        isCorrect: selectedOption === correctOption
      };
    });

    try {
      let newData = await updateCourseResults(courseId, authUser?.documentId, currentModuleIndex + 1, results);
      let resultsDocCopy = resultsDoc.current;
      resultsDocCopy.module = newData?.module;
      resultsDoc.current = resultsDocCopy;
    } catch (error) {
      console.error('Error saving results: ', error);
    }

    setAssignmentResults(results);
    calculateScore(results);
    let finalMessage = (score / assignmentQuestions.length * 100 >= passingScore) ? "Congratulations! You can move on the next module." : "You must retake the assignment until you recieve a passing score."
    setResultMessage(finalMessage);
    setShowResults(true);

    if (questionListRef.current) {
      questionListRef.current.scrollTop = 0;
      questionListRef.current.scrollIntoView({ top:0, behavior: 'smooth', block: 'start' });
    }

    if (currentModuleIndex === courseData.current.modules.length - 1) {
      setCourseCompleted(true); // Mark course as completed
    }
  };

  const handleRetakeAssignment = () => {
    setIsAssignment(true);
    setCurrentQuestionIndex(0);
    setAssignmentResults([]);
    setSelectedAnswers({});
    setShowResults(false);
  };

  const handleNextModule = async () => {
    if (currentModuleIndex === courseData.current.modules.length - 1) {
      try {
        await sendCourseCompletionEmail(
          authUser?.email,
          authUser?.fullName || authUser?.userName || 'Student',
          courseData.current?.name,
          creatorProfileData?.fullName || creatorProfileData?.userName || 'Teacher',
          `${window.location.origin}/courses`
        );
      } catch (error) {
        console.error('Error sending course completion email:', error);
      }

      setShowCompletionDialog(true);
    } else {
    const nextModuleIndex = currentModuleIndex + 1;

    setIsAssignment(false);
    setCurrentModuleIndex(nextModuleIndex);
    setSelectedAnswers({});
    setShowResults(false);

    // Ensure the assessmentSelected is false so the button does not appear
    setAssessmentSelected(false);

    // Send blank message via handleMessageSend
    if (useVoiceChat) {
      setIsAwaitingMessage(true);
      await handleMessageSend(`The student has taken the module ${nextModuleIndex} assessment now start from the beginning of module ${nextModuleIndex+1}`, true, useVoiceChat)
      setShouldPlayMessage(true);
    } else {
      handleMessageSend(`The student has taken the module ${nextModuleIndex} assessment now start from the beginning of module ${nextModuleIndex+1}`, true, useVoiceChat)
    }
    }
  };

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedModule(isExpanded ? panel : false);
  };

  const handleProfileClick = () => {
    const profilePath = `/${authUser?.userName}`;
    window.location.href = window.location.origin + profilePath;
  };

  // const completionAnimationOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: CompletionImage,
  //   rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice'
  //   }
  // };

  const CourseHeader = () => {
    return (
      <>
        <Avatar
          alt={courseData.current?.creator_name}
          src={courseData.current?.creator_avatar || DefaultUserImage}
          sx={{
            width: 50,
            height: 50,
          }}
        />
        <Box
          ml={1}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: '1',
            overflow: 'hidden',
          }}
        >
          <Typography
            fontSize={'0.85rem'}
            fontWeight={700}
            align='left'
            color={'black'}
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              lineHeight: '1.2em',
              maxHeight: '2.4em',
            }}
          >
            {courseData.current?.name}
          </Typography>
          <Typography
            fontSize={'0.85rem'}
            fontWeight={400}
            align='left'
            color={'black'}
            noWrap
          >
            {courseData.current?.creator_name}
          </Typography>
          <LinearProgress
            sx={{
              height: 10,
              borderRadius: 5,
              backgroundColor: grey[400],
              '& .MuiLinearProgress-bar':{backgroundColor: '#00A3FF'},
              flexGrow: 1,
              mt: 0.75,
            }}
            variant="determinate"
            value={progressPercent}
          />
        </Box>
      </>
    )
  }

  const ModuleData = () => {
    return (
      (courseData.current && courseData.current.modules) && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
            maxWidth: '100%',
            mt: 2,
          }}
        >
          {courseData.current.modules.map((mdl: any, j: number) => {
            return (
              <Accordion
                key={`module-${j + 1}`}
                expanded={expandedModule === `module-${j + 1}`}
                onChange={handleAccordionChange(`module-${j + 1}`)}
                sx={{
                  backgroundColor: "inherit",
                  border: "none",
                  boxShadow: "none",
                  width: '100%',
                  maxWidth: '100%',
                  '&:before': {
                    display: 'none',
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    '> .MuiAccordionSummary-content': {
                      width: '90%',
                    },
                    padding: '0 8px',
                  }}
                >
                  <Typography
                    fontSize={'0.9rem'}
                    fontWeight={700}
                    align='left'
                    color={'black'}
                    sx={{
                      ml: '0.5em',
                      textIndent: '-0.5em',
                    }}
                  >
                    {`Module ${j + 1}: ${mdl.title}`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    pt: 0,
                    pb: 0,
                    width: '100%',
                  }}
                >
                  {mdl.topics && (
                    mdl.topics.map((topic: any, i: number) => {
                      const isChecked = checkedTopics[`module_${j + 1}-${i}`] || false;
                      const isNextToHighlight = nextTopicToHighlight === `module_${j + 1}-${i}`;
                      return (
                        <Box
                          key={`module-${j + 1}-topic-${i + 1}`}
                          sx={{
                            ml: 1,
                            width: '90%',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Checkbox
                            checked={isChecked}
                            onChange={(e) => handleCheckboxChange(j, i, mdl.topics.length, e.target.checked)}
                            sx={{
                              '&.MuiButtonBase-root.MuiCheckbox-root': {
                                height: 'fit-content',
                                alignSelf: 'center',
                                pointerEvents: 'none',
                                borderRadius: '50%',
                              },
                            }}
                            icon={isNextToHighlight ? <RadioButtonCheckedIcon sx={{ color: '#3DA7E7' }} /> : <RadioButtonUncheckedIcon />}
                            checkedIcon={isNextToHighlight ? <RadioButtonCheckedIcon sx={{ color: '#3DA7E7' }} /> : <CheckCircleIcon sx={{ color: 'grey' }} />}
                          />
                          <Typography
                            fontSize={'0.8rem'}
                            fontWeight={isNextToHighlight ? 700 : (isChecked ? 400 : 400)}
                            align='left'
                            color={'black'}
                            sx={{
                              ml: 1,
                            }}
                          >
                            {`${i + 1}. ${topic.name}`}
                          </Typography>
                        </Box>
                      );
                    })
                  )}
                  <Box
                    sx={{
                      ml: 1,
                      width: '90%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                   <Checkbox
                      checked={(currentModuleIndex === j && assessmentSelected) || checkedTopics[`module_${j + 1}-assessment`] || false}
                      onChange={(e) => handleCheckboxChange(j, mdl.topics.length, mdl.topics.length + 1, e.target.checked)}
                      sx={{
                        '&.MuiButtonBase-root.MuiCheckbox-root': {
                          height: 'fit-content',
                          alignSelf: 'center',
                          pointerEvents: 'none',
                        },
                      }}
                      icon={(currentModuleIndex === j && assessmentSelected) ? <RadioButtonCheckedIcon sx={{ color: '#3DA7E7' }} /> : <RadioButtonUncheckedIcon />}
                      checkedIcon={(currentModuleIndex === j && assessmentSelected) ? <RadioButtonCheckedIcon sx={{ color: '#3DA7E7' }} /> : <CheckCircleIcon sx={{ color: 'grey' }} />}
                    />
                    <Typography
                      fontSize={'0.8rem'}
                      fontWeight={(currentModuleIndex === j && assessmentSelected) ? 700 : 500}
                      align='left'
                      color={'black'}
                      sx={{
                        ml: 1,
                      }}
                    >
                      Assessment
                    </Typography>
                  </Box>
                </AccordionDetails>
              </Accordion>
            )
          })}
          {courseCompleted && resultsDoc.current?.module[courseData.current.modules.length] && (
            <Button
              id={`button-review-assignments-${resultsDoc.current?.docId}`}
              variant="contained"
              sx={{
                height: '32px !important',
                padding: '6px 12px !important',
                borderRadius: '6px',
                width: '100%',
                mt: 2,
                mb: 2,
                color: 'black',
                backgroundColor: 'white !important',
                border: '1px solid black',
              }}
              fullWidth
              onClick={() => navigate(`/results/${courseId}/${authUser?.documentId}`)}
            >
              Review Assignments
            </Button>
          )}
        </Box>
      )
    )
  }

  return (
    <Box
      className='messages-section'
      sx={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: '50px',
        bottom: 0,
      }}
    >
      <Grid
        container
        alignItems={'stretch'}
        direction={'row'}
        sx={{
          height: '100%',
          maxHeight: '100%',
        }}
      >
        <Box
          component={Grid}
          display={{ xs: "none", sm: "flex"}}
          item
          lg={2}
          md={3}
          sm={4}
          sx={{
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            backgroundColor: '#f6f6f6',
            overflowY: 'scroll',
            height: '100%',
            maxHeight: '100%',
          }}
        >
          <TimeoutModal
            showTimeoutModal={showTimeoutModal}
            setShowTimeoutModal={setShowTimeoutModal}
            errorCode={errorCode}
          />
          <GenericModal
            showGenericModal={showGenericModal}
            setShowGenericModal={setShowGenericModal}
            data={genericModalData}
          />

          <Box
            p={1}
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              borderBottom: 'solid 1px lightgray',
            }}
          >
            <CourseHeader />
          </Box>

          <ModuleData />

          {enableDevTools && (
            <div
              style={{
                border: '2px solid #000',
                padding: '15px',
                textAlign: 'center',
                marginTop: 'auto',
                marginBottom: '5px',
                width: '100%',
              }}
            >
              <p style={{ fontSize: '16px' }}>
                Dev Tools for dev and testing only
              </p>
              <p
                style={{ fontSize: '14px', cursor: 'pointer' }}
                onClick={() => {
                  navigator.clipboard.writeText(courseId)
                  toast.success('course_id copied to clipboard')
                }}
              >
                course_id:<br/>{` ${courseId}`}
              </p>
              <p
                style={{ fontSize: '14px', cursor: 'pointer' }}
                onClick={() => {
                  navigator.clipboard.writeText(creatorProfileData?.documentId)
                  toast.success('creator_id copied to clipboard')
                }}
              >
                creator_id:<br/>{`${creatorProfileData?.documentId}`}
              </p>
              <p
                style={{ fontSize: '14px', cursor: 'pointer' }}
                onClick={() => {
                  navigator.clipboard.writeText(authUser?.uid)
                  toast.success('user_id copied to clipboard')
                }}
              >
                user_id:<br/>{`${authUser?.uid}`}
              </p>
              <p
                style={{ fontSize: '14px', cursor: 'pointer' }}
                onClick={() => {
                  navigator.clipboard.writeText(returnRoomId())
                  toast.success('room_id copied to clipboard')
                }}
              >
                room_id:<br/>Copy to Clipboard
              </p>
              <Button
                id='button-clear-chat'
                onClick={handleClearChatClick}
                disabled={clearChatLoading}
              >
                {clearChatLoading ? 'Deleting...' : 'Clear Chat'}
              </Button>
            </div>
          )}
        </Box>

        <Grid
          item
          lg={10}
          md={9}
          sm={8}
          xs={12}
          ref={containerRef}
          position='relative'
          overflow='hidden'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            overflowY: 'hidden'
          }}
        >
          { (courseData.current && courseData.current.modules) &&
            <Accordion >
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    display: {xs: "flex", sm: "none"},
                    justifyContent: 'space-between',
                    width: '100%',
                    '> .MuiAccordionSummary-content': {
                      width: '90%',
                    },
                    padding: '0 8px',
                    borderBottom: 'solid 1px lightgray',
                  }}
                >
                <CourseHeader />
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  p={1}
                  sx={{
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <ModuleData />
                </Box>
              </AccordionDetails>
            </Accordion>
          }

          {isAssignment ? (
            showResults ? (
              <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    borderBottom: '1px solid #ccc',
                    position: 'sticky',
                    top: 0,
                    backgroundColor: 'white',
                    zIndex: 1,
                  }}
                >
                  <Typography variant="subtitle1" sx={{ fontSize: '1rem' }}>
                    <span style={{ fontWeight: 700 }}>
                      Assignment: Module {currentModuleIndex + 1}
                    </span>
                    <span style={{ fontWeight: 400 }}>
                      &nbsp;Score: {score}/{assignmentQuestions.length}
                    </span>
                  </Typography>
                  <Box sx={{ display: 'flex'}}>
                    <Button variant="outlined" onClick={handleRetakeAssignment} sx={{
                      display: 'block',
                      textAlign: 'left',
                      mb: 1,
                      padding: '6px 12px', // Adjust the padding to make the button smaller
                      fontSize: '0.875rem', // Smaller font size for the option text
                      marginLeft: '20px',
                      borderRadius: '5px',
                      maxHeight: '40px',
                      fontWeight: 'normal !important', // Explicitly set font weight to normal
                      marginTop: '5px',
                      marginBottom: '5px'
                    }}>
                      Retake Assignment
                    </Button>
                    <Tooltip title={(scorePercentage < passingScore) ? `You must score at least ${passingScore}% to move onto the next module` : ""} arrow placement="top-end">
                      <span>
                        <Button variant="contained" onClick={handleNextModule} sx={{
                          display: 'block',
                          textAlign: 'left',
                          mb: 1,
                          padding: '6px 12px', // Adjust the padding to make the button smaller
                          fontSize: '0.875rem', // Smaller font size for the option text
                          marginLeft: '20px',
                          borderRadius: '5px',
                          maxHeight: '40px',
                          fontWeight: 'normal !important', // Explicitly set font weight to normal
                          marginTop: '5px',
                          marginBottom: '5px'
                        }}
                          disabled={scorePercentage < passingScore}
                        >
                          {currentModuleIndex === courseData.current.modules.length - 1 ? 'Complete Course' : 'Next Module'}
                        </Button>
                      </span>
                    </Tooltip>
                  </Box>
                </Box>

                <Box
                  ref={questionListRef}
                  sx={{
                    overflowY: 'auto',
                    flexGrow: 1,
                    padding: 3,
                  }}
                >
                {resultMessage && (
                  <Box sx={{ display: 'flex', alignItems: 'center', paddingBottom: '25px' }}>
                    <Avatar alt="Profile Picture" src={creatorProfileData?.profilePhotoURL} sx={{ width: 30, height: 30, marginRight: '10px' }} />
                    <Typography variant="body1" sx={{ fontSize: '1rem', color: 'black', marginTop: '15px' }}>
                      <strong>You scored {score}/{assignmentQuestions.length} correct answers.</strong>
                      <br />
                      {resultMessage}
                    </Typography>
                  </Box>
                )}
                {assignmentQuestions.slice(0, currentQuestionIndex + 1).map((questionObj, index) => (
                    <Box key={index} mb={4} display="flex" alignItems="flex-start">
                      <Avatar
                        alt={creatorProfileData?.fullName}
                        src={creatorProfileData?.profilePhotoURL || DefaultUserImage}
                        sx={{
                          width: '30px',
                          height: '30px',
                          marginRight: '10px',
                        }}
                      />
                      <Box>
                      <Typography variant="body1" sx={{ fontSize: '1rem',  color: 'black' }}>
                        {`${index + 1}. ${questionObj.question}`}
                      </Typography>
                      <Box mt={2}>
                        {questionObj.options.map((optionObj, optionIndex) => {
                          const isCorrectOption = optionObj.isCorrect;
                          const isSelectedOption = selectedAnswers[index] === optionObj.option;
                          return (
                            <Button
                              key={optionIndex}
                              variant="outlined"
                              sx={{
                                display: 'block',
                                textAlign: 'left',
                                mb: 1,
                                padding: '6px 12px',
                                fontSize: '0.875rem',
                                marginLeft:'20px',
                                borderRadius: '5px',
                                maxHeight: '40px',
                                fontWeight: 'normal !important',
                                backgroundColor: isCorrectOption ? '#3DC252' : (isSelectedOption ? '#FF6C6C' : 'inherit'),
                                color: isCorrectOption ? 'white !important' : (isSelectedOption ? 'white !important' : 'inherit'),
                                borderColor: isSelectedOption ? (isCorrectOption ? '#3DC252' : '#FF6C6C') : 'inherit',
                                '&:hover': {
                                  backgroundColor: isCorrectOption ? '#3DC252' : (isSelectedOption ? '#FF6C6C' : 'inherit'),
                                },
                              }}
                            >
                              {optionObj.option}
                            </Button>
                          );
                        })}
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>

            ) : (
              <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    borderBottom: '1px solid #ccc',
                    position: 'sticky',
                    top: 0,
                    backgroundColor: 'white',
                    zIndex: 1,
                  }}
                >
                  <Typography variant="subtitle1" sx={{ fontSize: '1rem', fontWeight: 700 }}>
                    Assignment: Module {currentModuleIndex + 1}
                  </Typography>
                  {currentQuestionIndex === assignmentQuestions.length - 1 && selectedAnswers[currentQuestionIndex] && (
                    <Button
                      variant="contained"
                      onClick={handleEndAssignment}
                      sx={{
                        display: 'block',
                        textAlign: 'left',
                        mb: 1,
                        padding: '6px 12px',
                        fontSize: '0.875rem',
                        marginLeft: '20px',
                        borderRadius: '5px',
                        maxHeight: '40px',
                        fontWeight: 'normal !important',
                        marginTop: '5px',
                        marginBottom: '5px',
                      }}
                    >
                      Submit Assignment
                    </Button>
                  )}
                </Box>
                <Box ref={questionListRef}
                  sx={{ overflowY: 'auto', flexGrow: 1, padding: 3}}>
                  {assignmentQuestions.slice(0, currentQuestionIndex + 1).map((questionObj, index) => (
                    <Box key={index} mb={4} display="flex" alignItems="flex-start">
                      <Avatar
                        alt={creatorProfileData?.fullName}
                        src={creatorProfileData?.profilePhotoURL || DefaultUserImage}
                        sx={{
                          width: '30px',
                          height: '30px',
                          marginRight: '10px',
                        }}
                      />
                      <Box>
                      <Typography variant="body1" sx={{ fontSize: '1rem',  color: 'black' }}>
                        {`${index + 1}. ${questionObj.question}`}
                      </Typography>
                      <Box mt={2}>
                        {questionObj.options.map((optionObj, optionIndex) => (
                          <Button
                            key={optionIndex}
                            variant={selectedAnswers[index] === optionObj.option ? "contained" : "outlined"}
                            onClick={() => handleAnswerSelect(index, optionObj.option)}
                            sx={{
                              display: 'block',
                              textAlign: 'left',
                              mb: 1,
                              padding: '6px 12px',
                              fontSize: '0.875rem',
                              marginLeft:'20px',
                              borderRadius: '5px',
                              maxHeight: '40px',
                              fontWeight: 'normal !important',
                            }}
                          >
                            {optionObj.option}
                          </Button>
                        ))}
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            )
            ) : (
              useVoiceChat ?
                <VoiceChat
                  handleMessageSend={handleMessageSend}
                  creatorProfileData={creatorProfileData}
                  handleLikeDislikeMessage={handleLikeDislikeMessage}
                  messageDataItem={messages ? messages[messages.length - 1] : null}
                  toggleVoiceChat={toggleVoiceChat}
                  useVoiceChat={useVoiceChat}
                  setErrorCode={setErrorCode}
                  setShowTimeoutModal={setShowTimeoutModal}
                  showCheckPermissionsModal={showCheckPermissionsModal}
                  showTranscript={true}
                  ignoreCredits={true}
                  showAssignmentButton={assessmentSelected}
                  handleStartAssignment={handleStartAssignment}
                  isAwaitingMessage={isAwaitingMessage}
                  setIsAwaitingMessage={setIsAwaitingMessage}
                  shouldPlayMessage={shouldPlayMessage}
                  setShouldPlayMessage={setShouldPlayMessage}
                /> :
                <>
                  <Box
                    className='messages-container'
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column', // Ensure items are aligned vertically
                      justifyContent: 'flex-start', // Align items to the top
                      overflowY: 'auto' // Enable scrolling
                    }}
                  >
                    <MessageList
                      loading={isLoading.current}
                      autoScrollToBottom={true}
                      scrollBehavior='auto'
                      style={{
                        height: '100%'
                      }}
                    >
                      <MessageList.Content>
                        <Box
                          display='flex'
                          flexDirection='column'
                          textAlign='left'
                          position='sticky'
                          zIndex={1}
                          alignItems='center'
                          width='100%'
                          bgcolor={'#fff'}
                          sx={{
                            top: 0,
                            overflowY: 'auto',
                            overflowX: 'hidden'
                          }}
                        >

                          <Box
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                            width='100%'
                            position='relative'
                            zIndex={1}
                            sx={{
                              top: 0,
                            }}
                          >
                            {!!creatorProfileData && (
                              <Divider
                                sx={{
                                  borderColor: '#ccc',
                                  position: 'absolute',
                                  bottom: 0,
                                  left: '-0.8em',
                                  right: '-1.2em',
                                }}
                              />
                            )}
                          </Box>
                        </Box>

                        {(!messages || messages?.length <= 0) && !aiTypingMessage && creatorProfileData ? (
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              flex: 1,
                            }}
                          >
                            <Avatar
                              alt={creatorProfileData?.fullName}
                              src={creatorProfileData?.profilePhotoURL}
                              sx={{
                                m: 1,
                                width: 100,
                                height: 100,
                                marginBottom: '1rem',
                                fontSize: '3.125rem'
                              }}
                            >
                              {creatorProfileData?.fullName ? creatorProfileData?.fullName[0] : ''}
                            </Avatar>
                            <Typography
                              fontSize={16}
                              fontWeight={400}
                            >
                              {`Start learning with ${creatorProfileData?.fullName}'s AI`}
                            </Typography>
                          </Box>
                        ) : (
                          messages?.length >= 1 &&
                          messages?.map(
                            (
                              messageDataItem: MessageDataType,
                              messageIndex: number
                            ) => (
                              <Box key={messageIndex}>
                                {(messageIndex === 0 ||
                                  moment(
                                    typeof messages[messageIndex - 1]?.sentTime ===
                                      'object'
                                      ? messages[
                                        messageIndex - 1
                                      ]?.sentTime?.toDate?.()
                                      : messages[messageIndex - 1]?.sentTime
                                  ).format('DD-MM-YYYY') !==
                                  moment(
                                    typeof messageDataItem?.sentTime === 'object'
                                      ? messageDataItem?.sentTime?.toDate?.()
                                      : messageDataItem?.sentTime
                                  ).format('DD-MM-YYYY')) && (
                                    <MessageSeparator
                                      content={moment(
                                        typeof messageDataItem?.sentTime === 'object'
                                          ? messageDataItem?.sentTime?.toDate?.()
                                          : messageDataItem?.sentTime
                                      ).format('DD MMMM YYYY')}
                                      style={{ overflowY: 'auto' }}
                                    />
                                  )}
                                <Box display='flex'>
                                  {showSelectMessages && (
                                    <Checkbox
                                      onChange={(event) =>
                                        handleShareButtonClick(messageDataItem)
                                      }
                                      checked={handleCurrentCheckboxChecked(
                                        messageDataItem?.documentId
                                      )}
                                      sx={{
                                        '&.MuiButtonBase-root.MuiCheckbox-root': {
                                          height: 'fit-content',
                                          alignSelf: 'center',
                                        },
                                      }}
                                      icon={<RadioButtonUncheckedIcon />}
                                      checkedIcon={
                                        <RadioButtonCheckedIcon
                                          sx={{ color: '#3DA7E7' }}
                                        />
                                      }
                                    />
                                  )}
                                  <MessageItem
                                    isSelfChat={isSelfChat}
                                    messageDataItem={messageDataItem}
                                    citations={citations.filter(citation => citation.messageId === messageDataItem.documentId)}
                                    creatorUserData={creatorProfileData}
                                    authUserData={authUser}
                                    handleLikeDislikeMessage={
                                      handleLikeDislikeMessage
                                    }
                                    indexKey={`message-${messageIndex}`}
                                    showActionMenu={showActionMenu}
                                    setShowActionMenu={setShowActionMenu}
                                    handleModal={handleModal}
                                    setHandleModal={setHandleModal}
                                    formData={formData}
                                    setFormData={setFormData}
                                    userName={creatorProfileData?.userName}
                                    handleShareButtonClick={handleShareButtonClick}
                                    showSelectMessages={showSelectMessages}
                                    setShowSelectMessages={setShowSelectMessages}
                                    resetMessageSelection={resetMessageSelection}
                                    isCourseChat={true}
                                  />
                                </Box>
                              </Box>
                            )
                          )
                        )}

                        <CustomTypingIndicator isTyping={isTyping} avatarImage={`${creatorProfileData?.profilePhotoURL}`} fullName={`${creatorProfileData?.fullName}`}/>

                        {/* AI Typing animation */}
                        {aiTypingMessage &&
                          <TypingAnimation
                            isSelfChat={isSelfChat}
                            messageDataItem={aiTypingMessage}
                            citations={citations.filter(citation => citation.messageId === aiTypingMessage.documentId)}
                            creatorUserData={creatorProfileData}
                            authUserData={authUser}
                            handleLikeDislikeMessage={
                              handleLikeDislikeMessage
                            }
                            indexKey={`message-typing`}
                            showActionMenu={showActionMenu}
                            setShowActionMenu={setShowActionMenu}
                            handleModal={handleModal}
                            setHandleModal={setHandleModal}
                            formData={formData}
                            setFormData={setFormData}
                            userName={creatorProfileData?.userName}
                            handleShareButtonClick={handleShareButtonClick}
                            showSelectMessages={showSelectMessages}
                            setShowSelectMessages={setShowSelectMessages}
                            resetMessageSelection={resetMessageSelection}

                            setAiTypingMessage={setAiTypingMessage}
                            setMessages={setMessages}
                            sendDisabled={sendDisabled}
                          />
                        }
                      </MessageList.Content>
                    </MessageList>

                  </Box>

                  {showSelectMessages && (
                    <ShareMessagesSection
                      containerRef={containerRef}
                      selectedMessages={selectedMessages}
                      resetMessageSelection={resetMessageSelection}
                      creatorProfileData={creatorProfileData}
                    />
                  )}

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      mb: 1,
                    }}
                  >
                    {chips && chips.length > 0 && (
                      <>
                        {chips.map((question, index) => (
                          <Button
                            key={index}
                            variant="outlined"
                            onClick={() => handleChipClick(question)}
                            sx={{
                              marginRight: 2,
                              textTransform: 'none',
                              fontSize: '0.75rem',
                              padding: '6px 10px',
                              minWidth: '120px',
                              height: '40px',
                              maxHeight: '45px',
                              borderRadius: '20px'
                            }}
                          >
                            {question}
                          </Button>
                        ))}
                      </>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column', // Align children vertically
                      justifyContent: 'flex-end', // Push content to the bottom
                      width: '100%',
                    }}
                  >
                    {assessmentSelected && (
                      <Button
                        onClick={handleStartAssignment}
                        variant="contained"
                        sx={{
                          mt: 2,
                          mb: 2,
                          ml: 2,
                          maxWidth: '200px',
                          alignSelf: 'center'
                        }}>
                        Start Assignment
                      </Button>
                    )}
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      {enableVoiceFeature &&
                        courseData.current?.enable_voice &&
                        creatorProfileData?.voiceId &&
                        browserSupportsSpeechRecognition && (
                          <VoiceChatToggle
                            toggleVoiceChat={toggleVoiceChat}
                            useVoiceChat={useVoiceChat}
                          />
                      )}
                      {!courseCompleted && (
                      <TextChatInput
                        sendDisabled={sendDisabled}
                        disabled={false}
                        isTyping={isTyping}
                        handleMessageSend={handleMessageSend}
                      />
                    )}
                    </Box>
                  </Box>

                </>
          )}
        </Grid>
      </Grid>
      <UserResponseForm
        handleModal={handleModal}
        formData={formData}
        handleFormDialogClose={handleFormDialogClose}
        handleFormDialogResponse={handleFormDialogResponse}
        isFormSubmitting={isFormSubmitting}
      />
      <ContactUsModal
        handleContactModal={handleContactModal}
        handleContactUsModalClose={handleContactUsModalClose}
      />
      <SignInSignUpModal handleSignInModal={handleSignInModal} setHandleSignInModal={setHandleSignInModal} />
      <Dialog
      open={showCompletionDialog}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          setShowCompletionDialog(false);
        }
      }}
      aria-labelledby="congratulations-dialog-title"
      aria-describedby="congratulations-dialog-description"
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.65)'
          },
        },
      }}
    >
      <DialogContent>
        <Typography variant="h3" align="center" gutterBottom>
          Congratulations!
        </Typography>
        <Typography variant="body1" align="center" gutterBottom style={{ color: 'black' }}>
          You have completed the course.
        </Typography>
        <Typography variant="body1" align="center" gutterBottom style={{ color: 'black' }}>
          You will receive a completion certificate via email.
        </Typography>
        <Box display="flex" justifyContent="center" sx={{
          borderRadius: '8px',
          width: '500px',
          height: '380px'
        }}>
          <Lottie
            animationData={CompletionImage}
            style={{ width: '100%', height: '100%' }}
          />
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', paddingBottom: '30px' }}>
      <Button
            onClick={() => navigate('/courses')}
            color="primary"
            variant="contained"
            style={{
              width: '200px',
              height: '40px',
              margin: '5px',
            }}
          >
            View Courses
          </Button>
          <Button
            onClick={handleProfileClick}
            color="primary"
            variant="outlined"
            style={{
              width: '200px',
              height: '40px',
              margin: '5px'
            }}
          >
            My Profile
          </Button>
      </DialogActions>
    </Dialog>
    <Dialog
        open={showAssignmentCompletionDialog}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setShowAssignmentCompletionDialog(false);
          }
        }}
        aria-labelledby="assignment-completion-dialog-title"
        aria-describedby="assignment-completion-dialog-description"
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.65)',
            },
          },
        }}
      >
        <DialogContent>
          <Typography variant="h3" align="center" gutterBottom>
            You're soaring!
          </Typography>
          <Typography variant="body1" align="center" gutterBottom style={{ color: 'black' }}>
            You scored 100% and have completed this module. Congratulations!
          </Typography>
          <Box display="flex" justifyContent="center" sx={{
            borderRadius: '8px',
            width: '500px',
            height: '380px',
          }}>
            <Lottie
              animationData={AssignmentCompletionImage}
              style={{ width: '100%', height: '100%' }}
            />
          </Box>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center', paddingBottom: '30px' }}>
          <Button
            onClick={() => {
              setShowAssignmentCompletionDialog(false);
              handleProfileClick();
            }}
            color="primary"
            variant="outlined"
            style={{
              width: '200px',
              height: '40px',
              margin: '5px',
            }}
          >
            My Profile
          </Button>
          <Button
            onClick={() => {
              setShowAssignmentCompletionDialog(false);
            }}
            color="primary"
            variant="contained"
            style={{
              width: '200px',
              height: '40px',
              margin: '5px',
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TakeCourse;
