import { useState, useEffect } from 'react';
import { Avatar } from '@mui/material';
import Typography from '@mui/material/Typography';
import DefaultUserImage from '../../assets/images/default-avatar.png';

import { useAuth } from '../../hooks/useAuth';
import { useProfileData } from '../../hooks/useProfileData';

const Profile = ({ handleButtonClick, isFan }) => {
  const { authUser, isAnonymousUser } = useAuth();

  const { authUserProfileData, fetchProfileDataFromUserName } =
    useProfileData();
  const [profilePhoto, setProfilePhoto] = useState<any>('');

  useEffect(() => {
    const fetchProfileData = async () => {
      if (authUser && !isAnonymousUser) {
        await fetchProfileDataFromUserName(authUser?.userName, false);
      }
    };
    fetchProfileData();
  }, [authUser]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (authUserProfileData) {
      setProfilePhoto(authUserProfileData.profilePhotoURL);
    }
  }, [authUserProfileData]);

  const navigateToProfile = () => {
    window.location.href = `${window.location.origin}/${authUser?.userName}`;
  };

  const navigateToUpdateProfile = () => {
    if (isFan){
      window.location.href = `${window.location.origin}/update-profile`;
    } else {
      window.location.href = `${window.location.origin}/${authUser?.userName}/update`;
    }
  };



  return (
    <div style={{ display: 'flex', marginBottom: '10px', marginLeft: '12px' }}>
      <div onClick={navigateToProfile} style={{ cursor: 'pointer' }}>
        <Avatar
          alt={authUser?.fullName}
          sx={{ width: 70, height: 70, marginRight: '20px', fontSize: '2.1875rem' }}
          src={profilePhoto || DefaultUserImage}
        >{authUser?.fullName ? authUser?.fullName[0] : ''}</Avatar>
      </div>
      <div style={{ flex: 2 }}>
        <Typography variant='h6' component='h6' fontWeight={600} sx={{ fontSize: '18px' }}>
          {authUser?.fullName}
        </Typography>
        <Typography component='p' variant='body2' color='text.secondary'>
          {'@' + authUser?.userName}
        </Typography>
        <Typography
          component='p'
          variant='body2'
          color='mainBlue.main'
          fontWeight={400}
          sx={{ fontSize: '13px', textDecoration: 'underline', pt: 0.5, pb: 0.5 }}
          onClick={isFan ? navigateToUpdateProfile : navigateToUpdateProfile}
          style={{ cursor: 'pointer' }}
        >
          Update Info
        </Typography>
      </div>
    </div>
  );
};

export default Profile;
