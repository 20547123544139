import React, { useState, useRef, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { Box, Typography, Link } from '@mui/material';

import FeedbackMsgIcon from '../../svg/FeedbackMsgIcon';
import ThumbsDownIcon from '../../svg/ThumbsDownIcon';
import ThumbsUpIcon from '../../svg/ThumbsUpIcon';

const FanDemo = ({ onClose }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [dialogOpen, setDialogOpen] = useState(true);
  const currentStepRef = useRef(currentStep);

  useEffect(() => {
    currentStepRef.current = currentStep;
  }, [currentStep]);

  const handleNextStep = () => {
    if (currentStep < 4) {
      setCurrentStep((prevStep) => prevStep + 1);
    } else {
      setDialogOpen(false);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    onClose();
  };

  const steps = ['Welcome', 'Description', 'Feedback', 'Contact Us'];

  return (
    <>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        {currentStep === 1 && (
          <>
            <DialogContent>
              <DialogTitle sx={{ textAlign: 'center' }}>Welcome</DialogTitle>
              <Box sx={{ marginBottom: '1rem' }}>
                <Typography variant='body2'>
                  Welcome! Soopra's AI-powered Personality Engine™ allows users
                  to create a personalized and authentic, user-centered digital
                  realm, molding the future of online presence and
                  communication.
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button id='button-next-step-1' variant='contained' onClick={handleNextStep}>
                  Next
                </Button>
              </Box>
            </DialogContent>
          </>
        )}

        {currentStep === 2 && (
          <>
            <DialogTitle></DialogTitle>
            <DialogContent>
              <Box sx={{ marginBottom: '1rem' }}>
                <Typography variant='body2'>
                  As you interact with Soopra, remember ... the AI persona is
                  still learning and may not always provide accurate info, but
                  that's part of the journey!
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button id='button-next-step-2' variant='contained' onClick={handleNextStep}>
                  Next
                </Button>
              </Box>
            </DialogContent>
          </>
        )}

        {currentStep === 3 && (
          <>
            <DialogContent>
              <DialogTitle></DialogTitle>
              <Box sx={{ marginBottom: '1rem' }}>
                <Typography variant='body2'>
                  AI personas rely on your feedback to improve and evolve.
                  Please rate each response you receive with a{' '}
                  <ThumbsUpIcon
                    height='18px'
                    width='18px'
                    style={{
                      verticalAlign: 'middle',
                    }}
                  />{' '}
                  ,{' '}
                  <ThumbsDownIcon
                    height='18px'
                    width='18px'
                    style={{
                      verticalAlign: 'middle',
                    }}
                  />{' '}
                  or click on the three dots{' '}
                  <FeedbackMsgIcon
                    height='18px'
                    width='18px'
                    style={{
                      verticalAlign: 'middle',
                    }}
                  />{' '}
                  to submit feedback. Thank you for helping your creator build a
                  great experience.
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button id='button-next-step-3' variant='contained' onClick={handleNextStep}>
                  Next
                </Button>
              </Box>
            </DialogContent>
          </>
        )}

        {currentStep === 4 && (
          <>
            <DialogTitle></DialogTitle>
            <DialogContent>
              <Box sx={{ marginBottom: '1rem' }}>
                <Typography variant='body2'>
                  Questions? Or need support? Email the Soopra team at{' '}
                  <Link id='href-mail-support-email' href='mailto:support@soopra.ai'>support@soopra.ai</Link>
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button id='button-next-step-4' variant='contained' onClick={handleCloseDialog}>
                  Finish
                </Button>
              </Box>
            </DialogContent>
          </>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1rem',
          }}
        >
          {steps.map((step, index) => (
            <Box
              key={index}
              sx={{
                width: '15%',
                height: '8px',
                backgroundColor: index + 1 < currentStep ? '#f5a142' : 'grey',
                my: '2px',
                mx: '4px',
              }}
            />
          ))}
        </Box>
      </Dialog>
    </>
  );
};

export default FanDemo;
