import * as React from 'react';
import { SVGProps, memo } from 'react';
const ThumbsDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={22}
    height={19}
    fill='none'
    viewBox='0 0 22 19'
    {...props}
  >
    <path
      stroke='currentColor'
      strokeWidth={0.51}
      d='M17.892 11.585h3.598V.969h-3.598v10.616ZM16.273 11.467V3.119a2.15 2.15 0 0 0-2.15-2.15H5.946a.72.72 0 0 0-.66.43L1.879 9.173a2.918 2.918 0 0 0 .85 3.451c.032.026.072.04.114.04h5.824a.72.72 0 0 1 .693.914l-.89 3.18c-.07.25 0 .518.184.702l.453.453a.72.72 0 0 0 1.018 0l5.938-5.938a.72.72 0 0 0 .21-.508Z'
    />
  </svg>
);
export default memo(ThumbsDownIcon);
