import React, { createContext, useRef, memo } from 'react';

interface VolumeCtxType {
  volume: React.MutableRefObject<number>,
}

export const VolumeContext = createContext<VolumeCtxType>({volume: null});

export const VolumeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const volume = useRef<number>(1);

  return (
    <VolumeContext.Provider value={{ volume }}>
      {children}
    </VolumeContext.Provider>
  );
};

export default memo(VolumeProvider);
