import * as React from 'react';
import { SVGProps, memo } from 'react';
const ChatButtonIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 20 17'
    {...props}
  >
    <path
      fill='#fff'
      fillRule='evenodd'
      d='M6.5 1a5.5 5.5 0 1 0 0 11h3.867c1.551 2.048 4.13 4.455 7.087 4.455-1.832-1.541-2.437-3.314-2.535-4.64A5.5 5.5 0 0 0 13.5 1h-7Z'
      clipRule='evenodd'
    />
    <path
      fill='currentColor'
      d='m10.367 12 .399-.302-.15-.198h-.249v.5Zm7.087 4.455v.5h1.372l-1.05-.883-.322.383Zm-2.535-4.64-.129-.483-.4.107.03.413.499-.037ZM1.5 6.5a5 5 0 0 1 5-5v-1a6 6 0 0 0-6 6h1Zm5 5a5 5 0 0 1-5-5h-1a6 6 0 0 0 6 6v-1Zm3.867 0H6.5v1h3.867v-1Zm7.087 4.455c-1.34 0-2.625-.547-3.78-1.367-1.156-.82-2.153-1.893-2.908-2.89l-.797.604c.796 1.051 1.864 2.206 3.125 3.101 1.26.895 2.745 1.552 4.36 1.552v-1Zm-3.034-4.103c.108 1.447.767 3.35 2.713 4.985l.643-.765c-1.72-1.446-2.269-3.09-2.359-4.294l-.997.074ZM18.5 6.5a5.002 5.002 0 0 1-3.71 4.832l.257.966A6.002 6.002 0 0 0 19.5 6.5h-1Zm-5-5a5 5 0 0 1 5 5h1a6 6 0 0 0-6-6v1Zm-7 0h7v-1h-7v1Z'
    />
  </svg>
);
export default memo(ChatButtonIcon);
