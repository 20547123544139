import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';


const CopyToClipboardButton = ({
  copyLink,
  buttonProps,
}: {
  copyLink: string;
  buttonProps: ButtonProps;
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
    if (copyLink) {
      navigator?.clipboard?.writeText?.(copyLink);
    }
  };

  const { sx, ...restButtonProps } = buttonProps;
  return (
    <>
      <Button
        id='button-share-persona'
        sx={{
          flex: 1,
          py: 1,
          ...sx,
        }}
        size='small'
        onClick={handleClick}
        variant='outlined'
        startIcon={<ShareOutlinedIcon />}
        {...restButtonProps}
      >
        Share My AI Persona
      </Button>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message='Copied to clipboard'
      />
    </>
  );
};

export default CopyToClipboardButton;
