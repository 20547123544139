import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { FirebaseError } from 'firebase/app';

import { useAuth } from '../../hooks/useAuth';
import Logo from '../../assets/images/logo.png';
import { COMMON_FIREBASE_ERROR_CODE } from '../../config/errors';

interface ForgotPasswordProps {
  isWidget?: boolean;
  setShowForgotPassword?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ForgotPasswordType {
  email: string;
}

export default function ForgotPassword(props: ForgotPasswordProps) {

  const {isWidget = false, setShowForgotPassword} = props

  const [formSubmitting, setFormSubmitting] = React.useState<boolean>(false);

  const { handleSendResetPasswordEmail } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (values: ForgotPasswordType) => {
    setFormSubmitting(true);
    const email = values?.email?.toLowerCase()?.trim();

    handleSendResetPasswordEmail(email)
      .then(() => {
        toast.success(
          'Reset Password link successfully send on your email. Please check'
        );
        setFormSubmitting(false);
        navigate(-1);
      })
      .catch((error) => {
        const firebaseError = (error as FirebaseError)?.code;
        toast.error(
          firebaseError && COMMON_FIREBASE_ERROR_CODE[firebaseError]
            ? COMMON_FIREBASE_ERROR_CODE[firebaseError]
            : 'Invalid details'
        );
        setFormSubmitting(false);
      });
  };
  const handleBackToLogin = () => {
    setShowForgotPassword(false)
  }

  const forgotPasswordValidationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .strict(true)
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        'Please enter valid email'
      ),
  });

  const initialValues = {
    email: '',
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: isWidget ? 0 : '1.5rem',
        width: isWidget ? 1 : 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          flex: 1,
          width: isWidget ? 1 : 'auto',
        }}
      >
        {isWidget ? <></>:<img src={Logo} alt='logo' width='30%' className='logo-img' />}

        <Formik
          initialValues={initialValues}
          validationSchema={forgotPasswordValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, handleBlur }) => (
            <Form
              id={'signin-form'}
              autoComplete='off'
              style={{ width: '100%' }}
              noValidate
            >
              <Box sx={
                isWidget ? 
                {
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '3rem'
                } 
                : 
                {
                  mt: 4
                }}
              >
                <Typography component='h1' variant='h6' fontWeight='600' className={isWidget ? 'widget-common-header' : ''}>
                  Reset Your Password
                </Typography>
                
                <Box
                  display='flex'
                  flexDirection='column'
                  gap='8px'
                >
                <TextField
                  id='text-input-email-forgot-password'
                  type='email'
                  disabled={formSubmitting}
                  margin='normal'
                  fullWidth
                  label='Email Address'
                  name='email'
                  autoComplete='off'
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
                  {isWidget ? <></> :
                  <Box
                    mt={isWidget? 0 : 1}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <Link id='href-back-to-login' to={'..'} style={{ textDecoration: 'none' }}>
                      <Typography
                        component='h5'
                        variant='h6'
                        fontWeight='600'
                        fontSize='0.8rem'
                        color='grey.700'
                      >
                        Back to Login
                      </Typography>
                    </Link>
                  </Box>
                  }
                  <Button
                    id='button-submit-password-reset'
                    type='submit'
                    variant='contained'
                    disabled={formSubmitting}
                    sx={isWidget ? {width: '100%'} :{ mt: 2, mb: 2, px: 6 }}
                    startIcon={
                      formSubmitting && (
                        <CircularProgress size={16} color='inherit' />
                      )
                    }
                  >
                    {'Send Reset Password Link'}
                  </Button>
                </Box>
                {isWidget ? 
                  <Box>
                    <Typography
                      style={{ 
                        textDecoration: 'underline',
                        cursor: 'pointer'
                      }}
                      component='h5'
                      variant='h6'
                      fontWeight= '500'
                      fontSize='1.18rem'
                      color='themeBlue.main'
                      onClick={handleBackToLogin}
                    >
                      Back to Login
                    </Typography>
                  </Box>
                :<></>}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}
