import { Box } from "@mui/material";
import React, { FC, useState, useEffect, useRef } from "react";
import MessageItem from "./messageItem";
import { MessageDataType } from "./messages.types";
import { MessageItemProps } from "./messageItem";

const TYPING_DELAY = 15; // delay between characters in ms

interface TypingAnimationInputProps extends MessageItemProps {
  setAiTypingMessage: React.Dispatch<any>;
  setMessages: React.Dispatch<any>;
  sendDisabled: React.MutableRefObject<boolean>;
}

const TypingAnimation: FC<TypingAnimationInputProps> = (props) => {
  const {
    messageDataItem,
    setAiTypingMessage,
    setMessages,
    sendDisabled
  } = props;

  const [currentMessageDataItem, setCurrentMessageDataItem] = useState<MessageDataType>(null);
  const isSaving = useRef<boolean>(false);
  const hasAddedMessage = useRef<boolean>(false);
  const anchor = useRef(null);

  useEffect(() => {
    return completeTypingAnimation
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    scrollToBottom()
    handleUpdateMessageItem()
  }, [currentMessageDataItem, messageDataItem]) //eslint-disable-line react-hooks/exhaustive-deps

  const handleUpdateMessageItem = () => {
    if (messageDataItem?.message) {
      let currentMessage = currentMessageDataItem?.message || "";
      if (currentMessage.length !== messageDataItem.message.length) {
        let nextMessage = messageDataItem.message.substring(0, currentMessage.length+1)
        let nextMessageDataItem = {...messageDataItem, message: nextMessage, image_url: null, image_urls: null, images: null}

        setTimeout(() => setCurrentMessageDataItem(nextMessageDataItem), TYPING_DELAY)
      } else {
        // typing complete, save to db, hide typing animation
        completeTypingAnimation()
      }
    }
  }

  const completeTypingAnimation = () => {
    if (!isSaving.current && messageDataItem) {
      isSaving.current = true;
      if (!hasAddedMessage.current) {
        setMessages(prevMessages => {
          return [...prevMessages, messageDataItem]
        })
        hasAddedMessage.current = true
      }
      sendDisabled.current=false;
      setAiTypingMessage(null)
      isSaving.current = false;
    }
  }

  const scrollToBottom = () => {
    if (anchor.current) {
      anchor.current.scrollIntoView({ behavior: "auto" })
    }
  }

  return (
    currentMessageDataItem ?
      <Box>
        <Box display='flex' flexDirection={'column'}>
          <MessageItem
            {...props}
            messageDataItem={currentMessageDataItem}
          />
          <Box ref={anchor} sx={{height: '1px'}}></Box>
        </Box>
      </Box> :
      <></>
  )
};

export default TypingAnimation
