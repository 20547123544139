import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';

import FlameIcon from '../../svg/FlameIcon';
import { UserStreaksRequest } from '../userStreaks/userStreaks.types';

interface CreatorFanListType {
  userStreaksData: UserStreaksRequest[];
  mode: 'profile' | 'list';
}

function CreatorFanList(props: CreatorFanListType) {
  const { userStreaksData, mode } = props;
  const returnClassName = () => {
    return userStreaksData?.length === 1
      ? 'user-streaks-single'
      : mode === 'list'
      ? 'user-streaks-list'
      : 'user-streaks-profile';
  };

  return (
    <Box
      display='flex'
      textAlign='center'
      justifyContent='center'
      maxWidth={'700px'}
      width={mode === 'list' ? '100%' : undefined}
      flexWrap='wrap'
    >
      {userStreaksData?.length >= 1 &&
        userStreaksData.map((userStreaks, index) => (
          <Box
            key={userStreaks?.fanId}
            flex={1}
            m={1}
            flexDirection='column'
            display='flex'
            alignItems='center'
            className={returnClassName()}
          >
            <Badge
              overlap='circular'
              invisible={!userStreaks?.streaksCount}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              badgeContent={
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  marginBottom='10px'
                  position='relative'
                >
                  <FlameIcon fontSize={mode === 'list' ? '28px' : '36px'} />
                  <Box
                    position='absolute'
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    bottom={mode === 'list' ? 5 : 6}
                    left={0}
                    right={0}
                  >
                    <Typography
                      color='#fff'
                      fontSize='10px'
                      lineHeight='normal'
                    >
                      {userStreaks?.streaksCount || 0}
                    </Typography>
                  </Box>
                </Box>
              }
            >
              <Avatar
                src={userStreaks?.fanProfile?.profilePhotoURL}
                alt={userStreaks?.fanProfile?.fullName}
                sx={{
                  mb: 0.8,
                  width: mode === 'list' ? 56 : 70,
                  height: mode === 'list' ? 56 : 70,
                  fontSize: mode === 'list' ? '1.75rem' : '2.1875rem',
                }}
              >{userStreaks?.fanProfile?.fullName ? userStreaks?.fanProfile?.fullName[0] : ''}</Avatar>
            </Badge>

            <Typography
              variant={mode === 'list' ? 'body2' : 'subtitle2'}
              fontWeight={400}
              color={'#000'}
              textOverflow='ellipsis'
              whiteSpace='nowrap'
              overflow='hidden'
              maxWidth='100%'
              title={`@${userStreaks?.fanProfile?.userName}`}
            >
              {userStreaks?.fanProfile?.userName
                ? `@${userStreaks?.fanProfile?.userName}`
                : ''}
            </Typography>
          </Box>
        ))}
    </Box>
  );
}

export default React.memo(CreatorFanList);
