import React from 'react';
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';

const LinearProgressWithLabel = (
  props: LinearProgressProps & {
    value: number;
    prefixLabel?: string;
    postFixLabel?: string;
  }
) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant='body2' color='text.secondary'>{`${
          props?.prefixLabel ? props?.prefixLabel : ''
        } ${Math.round(props.value)}% ${
          props?.postFixLabel ? props?.postFixLabel : ''
        }`}</Typography>
      </Box>
      <Box sx={{ width: '67%', ml: 1 }}>
        <LinearProgress
          sx={{
            backgroundColor: grey[400],
            '& .MuiLinearProgress-bar':{backgroundColor: 'mainBlue.light'},
          }}
          variant='determinate' {...props} />
      </Box>
    </Box>
  );
};

export default React.memo(LinearProgressWithLabel);
