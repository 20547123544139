import { SVGProps, memo } from 'react';
const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={16}
    fill='none'
    viewBox='0 0 16 16'
    {...props}
  >
    <path stroke='#000' d='m1 1 7 7m7 7L8 8m0 0-7 7m7-7 7-7' />
  </svg>
);
export default memo(CloseIcon);
