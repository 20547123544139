import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import { UserFormType } from '../creatorOnBoarding/signup.types';
import { USER_TYPE } from '../../config/const';
import CreatorFanList from './creatorFanList';

interface UserStreaksDataType {
  authUser: UserFormType;
  profileData: UserFormType;
  mode: 'profile' | 'list';
  isTopStreaksLoading?: boolean;
  isAllStreaksLoading?: boolean;
  streaksData: any;
}

export default function UserStreaksData(props: UserStreaksDataType) {
  const {
    isTopStreaksLoading,
    isAllStreaksLoading,
    streaksData,
    profileData,
    authUser,
    mode,
  } = props;
  const { topStreaksData = [], allStreaksData = [] } = streaksData;
  const { userName = '' } = profileData || {};

  const { userType = '', userName: authUserName = '' } = authUser || {};

  return (
    <>
      {isTopStreaksLoading || isAllStreaksLoading ? (
        <Box
          display='flex'
          flex={1}
          alignItems='center'
          flexDirection='column'
          justifyContent='center'
        >
          <CircularProgress size={mode === 'list' ? 20 : 30} />
        </Box>
      ) : (
        <>
          <Box
            mt={2}
            mb={1}
            display='flex'
            width='100%'
            // maxWidth={'500px'}
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            px={2.5}
          >
            <Typography
              component='h6'
              variant='h5'
              fontSize={18}
              fontWeight={600}
              mb={1}
              width='100%'
            >
              {authUserName === userName && userType === USER_TYPE.CREATOR
                ? 'My Top Fans'
                : 'Top Fans'}
            </Typography>
            {topStreaksData?.length >= 1 && (
              <CreatorFanList userStreaksData={topStreaksData} mode={mode} />
            )}
          </Box>

          {mode === 'profile' && allStreaksData?.length >= 1 && (
            <Box
              mt={2}
              mb={6}
              // maxWidth={'700px'}
              display='flex'
              width='100%'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              px={2.5}
            >
              <Typography
                component='h6'
                variant='h5'
                fontSize={18}
                fontWeight={600}
                mb={1}
                width='100%'
              >
                {'All Fans'}
              </Typography>
              <CreatorFanList userStreaksData={allStreaksData} mode={mode} />
            </Box>
          )}
        </>
      )}
    </>
  );
}
