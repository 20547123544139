import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FanCreatorProfileItem from './fanCreatorProfileItem';
import useMediaQuery from '@mui/material/useMediaQuery';

interface FanCreatorItemType {
  creatorItem: any;
}

function FanCreatorItem(props: FanCreatorItemType) {
  const { creatorItem } = props || {};
  const matches = useMediaQuery('(max-width:900px)');

  return (
    // Adjust the spacing here to ensure equal distance between rows and columns
    <Grid item
      md={matches ? 12 : 6}
      sm={matches ? 12 : 6}
      xs={12}
      key={creatorItem?.creatorId}
      sx={{
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          minWidth: '320px',
          maxWidth: '600px',
          width: '100%',
          borderRadius: '4px',
          boxShadow: '0px 0px 4px 0px #bbb',
          padding: '5px'
        }}
      >
        <FanCreatorProfileItem profileData={creatorItem} />
        {/* Additional content */}
      </Box>
    </Grid>
  );
}

export default React.memo(FanCreatorItem);
