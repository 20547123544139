import React, { createContext, useState, memo } from 'react';

interface SimpleDialogCtxType {
  data: any;
  setData: (dialogData: any) => void;
  isOpen: any;
  setIsOpen: (open: boolean) => void;
}

export const SimpleDialogContext = createContext<SimpleDialogCtxType>({
  data: null,
  setData: () => {
    return {} as any;
  },
  isOpen: null,
  setIsOpen: () => {
    return {} as any;
  },
});

export const SimpleDialogProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [data, setData] = useState<Record<any, any>>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <SimpleDialogContext.Provider
      value={{
        data,
        setData,
        isOpen,
        setIsOpen,
      }}
    >
      {children}
    </SimpleDialogContext.Provider>
  );
};

export default memo(SimpleDialogProvider);
