import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import { updateCurriculum } from "../../utils/api";
import Box from '@mui/material/Box';
import { Button } from "@mui/material";
import CourseItem from "../coursesList/courseItem";
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from "../../hooks/useAuth";
import { handleDeleteCourse } from "../../utils/helper";

const CoursePreview = () => {
  let [courseId, setCourseId] = useState<string>("");
  let [courseData, setCourseData] = useState<any>(null);
  let [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const shouldDeleteCourseRef = useRef<boolean>(true);
  const { authUser } = useAuth();

  useEffect(() => {
    if (location?.state?.courseId && location?.state?.courseData) {
      setCourseId(location.state.courseId)
      setCourseData(location.state.courseData)
    } else {
      // navigate(-1);
    }
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      if (shouldDeleteCourseRef.current) {
        handleDeleteCourse(courseData, authUser)
      }
    };
  }, [courseData]) //eslint-disable-line react-hooks/exhaustive-deps

  const handleSave = async () => {
    setLoading(true);
    shouldDeleteCourseRef.current = false; // Set the flag to false to prevent deletion

    if (courseData) {
      const updatedCourseInfo = {
        ...courseData,
        creation_done: true,
      };
      delete updatedCourseInfo.creator_avatar

      try {
          await updateCurriculum(courseId, updatedCourseInfo, true);
          if (authUser && authUser.userName) {
              navigate(`/${authUser.userName}`);
          } else {
              console.error('Username not available');
          }
      } catch (error) {
          console.error('Error finalizing course creation:', error);
      } finally {
          setLoading(false);
      }
    }
  }

  return (
    <Box p={2} pt={0}>
      <div style={{ width: '100%'}}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ backgroundColor: '#EFEFEF', py: 1, width: '100%'}}
        >
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Create New Course
          </Typography>
        </Box>
      </div>
      <Box pt={2}>
        <Typography fontSize={'1rem'} fontWeight={700} align='left' color={'black'} >
          Preview Course Listing
        </Typography>
        <Typography fontSize={'1rem'} fontWeight={400} align='left' color={'black'} sx={{mt: 2, mb: 2}}>
          This is how your course will appear to students on the search page.
        </Typography>
      </Box>
      <Box
        sx={{display: 'flex', justifyContent: 'center', pt: 2, pb: 2}}
      >
        {courseData && <CourseItem courseItem={courseData} handleEnrollCourse={() => {}} isEnrolled={false} disableButtons={true}/>}
      </Box>
      <Box
        sx={{display: 'flex', justifyContent: 'flex-end'}}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSave}
          disabled={loading}
          startIcon={loading ? <CircularProgress size={22}/> : <></>}
          sx={{
            width: {xs: '100%', md: 'auto'},
            '&.Mui-disabled': {
              backgroundColor: '#8ad5f8 !important',
            },
          }}
        >
          {loading? "Saving..." : "Save Course"}
        </Button>
      </Box>
    </Box>
  )
}

export default CoursePreview;
