import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { FirebaseError } from 'firebase/app';
import { getAuth } from 'firebase/auth';

import { useAuth } from '../../hooks/useAuth';
import { COMMON_FIREBASE_ERROR_CODE } from '../../config/errors';
import TopImageSection from '../../component/TopImageSection';
import SignUpImage from '../../assets/images/signup.svg';
import VerifyEmail from '../verifyEmail';

interface SignUpFormProps {
  isWidget?: boolean,
  email?: string,
  showVerifyEmail?:boolean,
  setShowVerifyEmail?: React.Dispatch<React.SetStateAction<boolean>>;
}


interface SignUpFormType {
  email: string;
  password: string;
  confirmPassword?: string;
}

export default function SignUp(props: SignUpFormProps) {

  const { isWidget = false, email, showVerifyEmail=false, setShowVerifyEmail} = props

  const [formSubmitting, setFormSubmitting] = React.useState<boolean>(false);
  const [isRedirect, setIsRedirect] = React.useState<boolean>(false);
  const {
    authUser,
    createUserAccountWithEmailPassword,
    checkUserExistWithEmail,
  } = useAuth();

  const navigate = useNavigate();
  const { state } = useLocation();
  const auth = getAuth();

  React.useEffect(() => {
    if (isRedirect && authUser?.documentId) {
      //if user email is unverified redirect user to verify email page
      if (
        auth?.currentUser?.emailVerified !== undefined &&
        auth.currentUser.emailVerified === false
      ) {
        if (isWidget) {
          setShowVerifyEmail(true)
        }
        else {
          navigate('/verify-email', { state: { email: authUser?.email } });
        }
        return;
      } else {
        if (!isWidget) {
          //else user email is verified redirect user to creator page
          let redirectToPath = '/creators';
          if (!authUser?.userName || authUser?.userName === undefined) {
            // redirect user to create profile page if user is new register
            redirectToPath = `/create-profile`;
          }
          navigate(redirectToPath);
        }
      }
    }
  }, [authUser, auth.currentUser, isRedirect]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (values: SignUpFormType) => {
    setFormSubmitting(true);
    const isEmailExists = await checkUserExistWithEmail(values?.email);

    if (isEmailExists) {
      setFormSubmitting(false);
      toast.error('Account with this email already exists');
      return;
    }

    createUserAccountWithEmailPassword(values)
      .then(() => {
        toast.success(
          'User registered successfully. Please check your email to verify your account and login again.'
        );
        setIsRedirect(true);
      })
      .catch((error) => {
        console.log('sign up error', error);
        setFormSubmitting(false);
        setIsRedirect(false);
        const firebaseError = (error as FirebaseError)?.code;
        toast.error(
          firebaseError && COMMON_FIREBASE_ERROR_CODE[firebaseError]
            ? COMMON_FIREBASE_ERROR_CODE[firebaseError]
            : 'There is some error with the user registration. Please try again'
        );
      });
  };

  const signinValidationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .strict(true)
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        'Please enter valid email'
      ),
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string()
      .required('Password is required')
      .oneOf(
        [Yup.ref('password'), null],
        'Password and Re-enter password does not match'
      ),
  });

  const initialValues = {
    email: (state?.email as string) || email as string ||  '',
    password: '',
    confirmPassword: '',
  };

  return (
    <>
    {showVerifyEmail ? 
      <VerifyEmail isWidget email={email} />
    :  
      <Box
      display='flex'
      flex={1}
      flexDirection='column'
      alignItems='center'
      justifyContent='flex-start'
      justifyItems='center'
      textAlign='center'
      width='100%'
      mt={isWidget ? 0 : 6}
      sx={{
        px: isWidget ? 0 : '2rem',
      }}
    >
      <Box
        display='flex'
        justifyContent='flex-start'
        alignItems='center'
        flexDirection='column'
        flex={0.5}
        width='100%'
        gap={isWidget ? 3 : ''}
        sx={{
          '@media (min-width: 768px)': {
            width: isWidget? '100%':'50%',
          },
          '@media (min-width: 1440px)': {
            px: isWidget ? 0:'5rem',
          },
        }}
      >
        {isWidget ? <Box className='widget-common-header'>Create Account</Box>:<TopImageSection image={SignUpImage} title='Create Account' />}
        <Formik
          initialValues={initialValues}
          validationSchema={signinValidationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isValid,
            dirty,
          }) => (
            <Form
              id={'form-signin-email'}
              autoComplete='off'
              style={{ width: '100%' }}
              noValidate
            >
              <Box display='flex' flexDirection='column' gap={3}>
                <Box display='flex' flexDirection='column' gap={isWidget ? 1.5 : ''}>
                  <TextField
                    type='email'
                    margin={isWidget ? 'none':'normal'}
                    fullWidth
                    disabled={true}
                    id='text-input-email'
                    label='Email Address'
                    name='email'
                    autoComplete='off'
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched?.email && errors?.email}
                  />
                  <TextField
                    type='password'
                    margin= {isWidget ? 'none':'normal'}
                    fullWidth
                    id='text-input-password'
                    label='Choose a Password'
                    name='password'
                    autoComplete='off'
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                  />
                  <TextField
                    type='password'
                    margin={isWidget ? 'none':'normal'}
                    fullWidth
                    id='text-input-confirmPassword'
                    label='Re-enter Password'
                    name='confirmPassword'
                    autoComplete='off'
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.confirmPassword && Boolean(errors.confirmPassword)
                    }
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                  />
                  <Button
                    id='button-next'
                    sx={{
                      mt: isWidget ? 0 : 3,
                    }}
                    type='submit'
                    variant='contained'
                    fullWidth
                    disabled={!(isValid && dirty) || formSubmitting}
                    startIcon={
                      formSubmitting && (
                        <CircularProgress size={16} color='inherit' />
                      )
                    }
                  >
                    {'Next'}
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
    }
    </>
  );
}
