import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import Credit from '../../assets/images/credit.png';
import StripeCheckoutForm from '../StripeCheckoutForm';

const enableMonetization = process.env.REACT_APP_ENABLE_MONETIZATION === 'true';

interface SubscribeButtonProps {
  redirectURL?: string,
}

const SubscribeButton: React.FC<SubscribeButtonProps> = (props) => {
  const [ stripeOpen, setStripeOpen ] = useState(false);
  const [ stripeItem, setStripeItem ] = useState(null);
  const { redirectURL } = props;

  let handlePurchaseSubscription = (event: any) => {
    event.preventDefault()
    setStripeItem("basic_soopra_subscription")
    setStripeOpen(true)
  }

  if (!enableMonetization) return <></>

  return (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: '#f6f6f6',
      border: '1px solid lightgray',
      borderRadius: '5px',
      width: '100%',
    }}
  >
    <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      padding: '1rem',
      paddingBottom: '0.75rem',
      '& img': {
        marginRight: '0.5rem',
        height: '24px',
      }
    }}>
      <img src={Credit} alt='soopra-credit' />
      <Typography
        component='h6'
        variant='h6'
        fontWeight={600}
        sx={{
          fontSize: '16px', // Change the font family
          color: 'black',
          textAlign: 'center',
        }}
      >Subscribe to Continue</Typography>
    </Box>
    <Box
      sx={{
        pl: '1rem',
        pr: '1rem',
      }}
    >
      <Typography
        component='h6'
        variant='h6'
        fontWeight={400}
        sx={{
          fontSize: '14px', // Change the font family
          color: 'black',
          marginBottom: '0.75rem',
          textAlign: 'center',
        }}
      >
        Unlock access to all AI personas. 25 credits a month for $1.99. Cancel subscription at any time.
      </Typography>
    </Box>
    <Button
      id={`button-purchase-subscription`}
      variant="contained"
      sx={{
        height: '32px !important',
        padding: '6px 12px !important',
        borderRadius:'6px',
        width: '50%',
        fontSize: '14px',
        marginBottom: '1.5rem'
      }}
      fullWidth
      onClick={handlePurchaseSubscription}
    >
      Subscribe
    </Button>
    <StripeCheckoutForm item={stripeItem} isOpen={stripeOpen} setIsOpen={setStripeOpen} redirectURL={redirectURL || ""}/>
  </Box>
  )
}

export default SubscribeButton;
