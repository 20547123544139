import React from 'react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

interface UserResponseFormType {
  handleModal: 'edit' | 'feedback' | null;
  formData: Record<any, any>;
  handleFormDialogClose: () => void;
  handleFormDialogResponse: (values) => void;
  isFormSubmitting: boolean;
}

const UserResponseForm = (props: UserResponseFormType) => {
  const {
    handleModal,
    formData,
    handleFormDialogClose,
    handleFormDialogResponse,
    isFormSubmitting,
  } = props;

  return (
    <Dialog
      open={Boolean(handleModal)}
      onClose={handleFormDialogClose}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            // maxWidth: '340px',
          },
        },
      }}
    >
      <Formik
        initialValues={{
          response:
            (handleModal === 'feedback' && formData?.feedback) ?
              formData?.feedback :
              (handleModal === 'edit' && formData?.message) ?
                formData?.message :
                '',
        }}
        validationSchema={Yup.object().shape({
          response: Yup.string().required('Please enter a response'),
        })}
        onSubmit={handleFormDialogResponse}
      >
        {({ values, errors, touched, handleChange, handleBlur, isValid }) => (
          <Form id={'response-form'} autoComplete='off'>
            <DialogContent>
              <Typography fontSize={'1.1rem'} fontWeight={500} mb={1}>
                {handleModal === 'feedback' ? 'Feedback' : 'Edit response'}
              </Typography>
              <TextField
                multiline
                margin='normal'
                disabled={isFormSubmitting}
                id={"text-input-response"}
                label={
                  handleModal === 'feedback' ? 'Feedback' : 'Edit response'
                }
                name='response'
                type='text'
                fullWidth
                rows={3}
                variant='outlined'
                value={values.response}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.response && Boolean(errors.response)}
                helperText={touched?.response && (errors?.response as string)}
                sx={{
                  '& textarea': {padding: 0}
                }}
              />

              <Box
                mt={1.2}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
                columnGap={1}
              >
                <Button
                  size='small'
                  id='button-cancel-user-response'
                  // variant='outlined'
                  disabled={isFormSubmitting}
                  onClick={() => handleFormDialogClose()}
                >
                  Cancel
                </Button>
                <Button
                  size='small'
                  id='button-submit-user-response'
                  color='primary'
                  variant='contained'
                  type='submit'
                  disabled={isFormSubmitting}
                  startIcon={
                    isFormSubmitting && (
                      <CircularProgress size={16} color='inherit' />
                    )
                  }
                >
                  Submit
                </Button>
              </Box>
            </DialogContent>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default UserResponseForm;
