import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useNavigate } from 'react-router-dom';
import TopImageSection from '../../component/TopImageSection';
import ProfileCreatedImage from '../../assets/images/profile-created.svg';
import { UserFormType } from '../creatorOnBoarding/signup.types';

interface ProfileCreatedDialogType {
  authUser: UserFormType;
  showProfileCreated: boolean;
  setShowProfileCreated: (value: boolean) => void;
}

export default function ProfileCreatedDialog(props: ProfileCreatedDialogType) {
  const { authUser, showProfileCreated, setShowProfileCreated } = props;
  const { userName = '' } = authUser || {};
  const navigate = useNavigate();

  const handleClose = () => {
    setShowProfileCreated(false);
  };

  const handleLinkClick = (route) => {
    setShowProfileCreated(false);
    navigate?.(`/${route}`);
  };

  return (
    <Dialog
      open={showProfileCreated}
      onClose={handleClose}
      aria-labelledby='created-profile-dialog'
      aria-describedby='created-profile-dialog-content'
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '400px',
          },
        },
      }}
    >
      <DialogContent>
        <Box
          display='flex'
          justifyContent='center'
          flexDirection='column'
          alignItems='center'
          width='100%'
        >
          <TopImageSection image={ProfileCreatedImage} />
          <Box display='flex' flexDirection='column' gap={2} width='100%'>
            <Typography
              component='h6'
              variant='body1'
              fontWeight={600}
              fontSize='1.3rem'
              sx={{
                textAlign: 'center',
              }}
            >
              {'Congratulations!'}
            </Typography>
            <Typography
              component='h6'
              variant='body1'
              fontSize='1.1rem'
              whiteSpace='pre-wrap'
              lineHeight='normal'
              sx={{
                textAlign: 'center',
                mx: 2,
              }}
            >
              {`Your AI Persona is ready! \n Chat with your AI to help it learn and evolve.`}
            </Typography>
          </Box>
          <Box
            display='flex'
            flexDirection='column'
            gap={2}
            mt={4}
            width='100%'
          >
            {/* <Link to={`/${userName}/chat`}> */}
            <Button
              onClick={() => handleLinkClick(`${userName}/chat`)}
              variant='contained'
              fullWidth
              sx={{
                '&.MuiButton-contained': {},
                fontSize: '1.1rem',
                fontWeight: 600,
              }}
            >
              Chat with my AI Persona
            </Button>
            <Button
              onClick={() => handleLinkClick(userName)}
              variant='outlined'
              fullWidth
              sx={{
                '&.MuiButton-contained': {},
                fontSize: '1.1rem',
                fontWeight: 600,
                color: '#000',
              }}
            >
              Go to my profile
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
