const TermsAndConditions =
  {
    title: 'Terms and Conditions',
    description:
      'These Terms of Use are entered into by and between you and Soopra ("Company," "we,", “our”,or "us"). They govern your access to and use of our online platform, including any content, functionality, and services offered on or through soopra.ai ("Soopra"), whether as a guest or a registered user. \n\nPlease read the Terms of Use carefully before using Soopra. By accessing or using Soopra, you agree to be bound by these Terms of Use and our Privacy Policy, which is incorporated herein by reference. If you do not agree to these Terms of Use or the Privacy Policy, you must not access or use Soopra.\n\nNo one under 13 is allowed to create an account or use Soopra. If you are under 18, you may only use Soopra with the prior consent of your parent or legal guardian. Please ensure your parent or legal guardian has reviewed and discussed these Terms with you before you start using Soopra.\n\n',
    listItems:
      [
        {title: 'AI Personas: ',
          description: 'Soopra allows users to create AI personas that represent their expertise and engage with others. You understand and acknowledge that AI personas may not always accurately represent the expertise they are intended to simulate, and their actions and statements are for informational purposes only. You should not rely solely on any statements, representations, or information provided by AI personas for decision-making in personal, financial, medical, or legal matters. Soopra disclaims any responsibility or liability arising from your reliance on AI personas or their actions or statements, and from any inaccuracies in the expertise AI personas are intended or not intended to simulate.',
        },
        {title: 'Changes to the Terms of Use: ',
          description: 'We may revise and update these Terms of Use from time to time at our sole discretion. We will notify you of any significant changes by stating the effective date of the Terms of Use at the beginning. All changes are effective immediately when posted and apply to all access to and use of Soopra thereafter.\n\nYour continued use of Soopra following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page each time you access Soopra so you are aware of any changes, as they are binding on you.\n\n'
        },
        {title: 'Accessing Soopra and Account Security: ',
          description: '\n\nWe reserve the right to discontinue or alter Soopra, and any content we provide, at our sole discretion without notice. We will not be liable if any part of Soopra is unavailable at any time or for any period. From time to time, we may restrict access to some parts of Soopra, or the entire platform.\n\nTo access Soopra or some of the resources it offers, you may need to create an account ("Account"). You agree to provide accurate, current, and complete information during the registration process and to update such information as necessary. You are responsible for safeguarding your account and agree to notify us immediately of any unauthorized use or security breach. We have the right to disable any user name, password, or other identifier if we believe you have violated any provision of these Terms of Use.\n\n'
        },
        {title: 'Ownership of AI Personas: ',
          description: '\n\nAs between the Company and you, you retain full ownership of the AI personas you create on Soopra, unless otherwise specified. This ownership includes:\n\n',
          listItems: [
            {
              title: 'Intellectual Property Rights: ',
              description: 'You hold all intellectual property rights associated with your AI personas. Soopra will not use your AI personas or content provided for persona development to train or enhance our or any third-party models without your consent.',
            },
            {
              title: 'Access Permissions: ',
              description: 'You control how your AI persona is accessed and can grant, modify, or revoke access permissions at your discretion.'
            },
            {
              title: 'Persona Permissions: ',
              description: 'You can only create an AI persona of yourself or someone you have written permission from to create their Soopra. You must provide Soopra with a copy of the signed and written permission before you create an AI persona of someone other than yourself. Failure to comply with this rule will result in immediate suspension from the Soopra platform.\n\n'
            }
          ]
        },
        {title: 'Acceptable Use Policy',
          description: '',
          listItems: [
            {title: 'Introduction: ',
              description: '',
              listItems: [
                {
                  title: '',
                  description: 'Welcome to Soopra! We are committed to creating a positive, safe, and productive environment for all users who interact with AI personas on our platform. This Acceptable Use Policy ("Policy") outlines the rules and guidelines for interacting with AI personas to ensure a respectful and constructive experience for everyone. By using Soopra, you agree to abide by this Policy.',
                }
              ]
            },
            {title: 'Purpose: ',
              description: '',
              listItems: [
                {
                  title: '',
                  description: 'The purpose of this Policy is to:',
                  listItems: [
                    {
                      title: '',
                      description: 'Promote a safe and respectful environment for all users interacting with AI personas.',
                    },
                    {
                      title: '',
                      description: 'Prevent misuse or abuse of AI personas and the Soopra platform.',
                    },
                    {
                      title: '',
                      description: "Protect the integrity of AI personas and ensure they are used in a manner consistent with Soopra's mission to provide valuable, educational, and professional interactions.",
                    },
                  ]
                }
              ]
            },
            {title: 'Scope: ',
              description: '',
              listItems: [
                {
                  title: '',
                  description: 'This Policy applies to all users who interact with AI personas on the Soopra platform, including but not limited to:',
                  listItems: [
                    {
                      title: '',
                      description: 'Individuals seeking information, advice, or support from AI personas.',
                    },
                    {
                      title: '',
                      description: 'Experts managing their AI personas and their interactions with users.',
                    },
                  ]
                }
              ]
            },
            {title: 'Acceptable Use Guidelines: ',
              description: '',
              listItems: [
                {
                  title: '',
                  description: 'Users interacting with AI personas on Soopra are expected to:',
                  listItems: [
                    {
                      title: 'Respectful Communication: ',
                      description: '',
                      listItems: [
                        {
                          title: '',
                          description: 'Engage with AI personas in a respectful and courteous manner.'
                        },
                        {
                          title: '',
                          description: 'Refrain from using offensive, abusive, or harassing language.'
                        },
                        {
                          title: '',
                          description: 'Avoid making discriminatory, hateful, or defamatory remarks.'
                        },
                      ]
                    },
                    {
                      title: 'Responsible Content Sharing: ',
                      description: '',
                      listItems: [
                        {
                          title: '',
                          description: 'Do not share or request sensitive personal information such as credit card details, passwords, or social security numbers.'
                        },
                        {
                          title: '',
                          description: 'Avoid uploading or sharing content that is illegal, harmful, or violates the rights of others.'
                        },
                      ]
                    },
                    {
                      title: 'Appropriate Use of AI Personas: ',
                      description: '',
                      listItems: [
                        {
                          title: '',
                          description: 'Use AI personas for their intended purposes, such as seeking information, advice, or support.'
                        },
                        {
                          title: '',
                          description: 'Do not exploit AI personas for malicious activities, such as attempting to manipulate or deceive other users.'
                        },
                        {
                          title: '',
                          description: 'Avoid spamming, flooding, or overloading AI personas with excessive or repetitive queries.'
                        },
                      ]
                    },
                    {
                      title: 'Compliance with Laws and Regulations: ',
                      description: '',
                      listItems: [
                        {
                          title: '',
                          description: 'Adhere to all applicable local, state, national, and international laws and regulations while using Soopra.'
                        },
                        {
                          title: '',
                          description: 'Do not use AI personas to promote or engage in illegal activities, including but not limited to fraud, phishing, or distribution of malware.'
                        },
                      ]
                    },
                    {
                      title: 'Intellectual Property: ',
                      description: '',
                      listItems: [
                        {
                          title: '',
                          description: 'Respect the intellectual property rights of Soopra and others.'
                        },
                        {
                          title: '',
                          description: 'Do not use AI personas to distribute, share, or promote content that infringes on the copyrights, trademarks, or other intellectual property rights of others.'
                        },
                      ]
                    }
                  ]
                },
              ]
            }
          ]
        },
        {title: 'Prohibited Activities: ',
          description: '',
          listItems: [
            {
              title: '',
              description: 'Users are strictly prohibited from engaging in the following activities while interacting with AI personas on Soopra:',
              listItems: [
                {
                  title: 'Harassment and Abuse: ',
                  description: '',
                  listItems: [
                    {
                      title: '',
                      description: 'Engaging in any form of harassment, abuse, or threats directed at the AI persona, other users, or Soopra employees.'
                    },
                    {
                      title: '',
                      description: 'Using language or content that promotes violence, hatred, or discrimination.'
                    },
                  ]
                },
                {
                  title: 'Malicious Use: ',
                  description: '',
                  listItems: [
                    {
                      title: '',
                      description: 'Attempting to exploit vulnerabilities or flaws in the AI persona or Soopra platform.'
                    },
                    {
                      title: '',
                      description: 'Engaging in activities that could harm the AI persona, Soopra platform, or its users.'
                    },
                  ]
                },
                {
                  title: 'Inappropriate Content: ',
                  description: '',
                  listItems: [
                    {
                      title: '',
                      description: 'Sharing or soliciting sexually explicit, violent, or otherwise inappropriate content.'
                    },
                    {
                      title: '',
                      description: 'Using AI personas to engage in discussions or activities that violate community standards or norms.'
                    },
                  ]
                },
                {
                  title: 'Misrepresentation: ',
                  description: '',
                  listItems: [
                    {
                      title: '',
                      description: 'Impersonating other users or Soopra employees.'
                    },
                    {
                      title: '',
                      description: "Falsely representing the AI persona’s identity, qualifications, or expertise."
                    },
                  ]
                },
                {
                  title: 'Data Mining and Automated Interactions: ',
                  description: '',
                  listItems: [
                    {
                      title: '',
                      description: 'Using bots, scripts, or automated tools to interact with AI personas.'
                    },
                    {
                      title: '',
                      description: 'Attempting to extract data or information from AI personas for unauthorized purposes.'
                    },
                  ]
                }
              ]
            },
          ]
        },
        {title: 'Enforcement and Consequences: ',
          description: 'Soopra takes violations of this Policy seriously and will enforce the following measures for any breaches:',
          listItems: [
            {
              title: 'Warnings:',
              description: '',
              listItems: [
                {
                  title: '',
                  description: 'Issuing warnings to users who violate the Policy, providing an opportunity to correct their behavior.',
                },
              ]
            },
            {
              title: 'Account Suspension or Termination:',
              description: '',
              listItems: [
                {
                  title: '',
                  description: 'Temporarily suspending or permanently terminating user accounts that engage in repeated or severe violations of this Policy.',
                },
                {
                  title: '',
                  description: 'Users whose accounts are terminated due to Policy violations will be permanently removed from the Soopra platform and will no longer have access to its services.',
                },
              ]
            },
            {
              title: 'Access Restrictions:',
              description: '',
              listItems: [
                {
                  title: '',
                  description: "Restricting or limiting a user's ability to interact with AI personas or access certain features of the Soopra platform.",
                },
              ]
            },
            {
              title: 'Legal Action:',
              description: '',
              listItems: [
                {
                  title: '',
                  description: "Reporting illegal activities to law enforcement authorities and pursuing legal action where appropriate.\n\n",
                },
              ]
            },
          ],
          footerTitle: 'Note: ',
          footerDescription: 'Any user found to be in severe violation of this Policy may be subject to removal from the Soopra platform. Users will be notified and given an opportunity to appeal such decisions. Repeat offenders or those who commit severe violations may be permanently barred from accessing the platform in the future.\n\n'
        },
        {title: 'Reporting Violations: ',
          description: [
            "Users who encounter or witness any behavior that violates this Policy are encouraged to report it to Soopra's support team at ",
            {text: "support@soopra.ai", link: "mailto:support@soopra.ai" },
            ". All reports will be reviewed and addressed promptly and confidentially.",
          ]
        },
        {title: 'Modifications to the Policy: ',
          description: "Soopra reserves the right to modify this Policy at any time. Any changes will be communicated to users through the Soopra platform or via email of the effective date of any changes. Continued use of the platform after modifications have been made constitutes acceptance of the updated Policy."
        },
        {title: 'Contact Information: ',
          description: [
            "For questions or concerns about this Policy, please contact Soopra support at ",
            {text: "support@soopra.ai", link: "mailto:support@soopra.ai" },
          ]
        },
        {title: '',
          description: 'By interacting with AI personas on Soopra, you acknowledge that you have read, understood, and agree to abide by this Acceptable Use Policy.'
        },
        {title: 'Payment and Fees: ',
          description: 'Certain services may require payment. By using paid services, you agree to the pricing and payment terms made available on Soopra, which are subject to change. Subscriptions renew automatically unless canceled.'
        },
        {title: 'Termination: ',
          description: 'We may terminate or suspend your access for violating these terms or for any reason at our discretion. You will be notified of the termination, and your data will be handled according to our Privacy Policy.'
        },
        {
          title: 'Limitation of Liability: ',
          description: 'Soopra is provided "as is" without warranties, to the fullest extent permissible under applicable law. We are not liable for any damages arising from your use of the platform, except where such limitation of liability is not permitted by applicable law.'
        },
        {
          title: 'Governing Law: ',
          description: '',
          listItems: [
            {
              title: '',
              description: 'These terms are governed by the laws of California, USA, without giving effect to principles of conflicts of law and regardless of where Soopra operates, and any disputes will be resolved in the courts of California, USA.'
            }
          ]
        },
      ],
    footer: 'By using Soopra, you acknowledge that you have read, understood, and agree to these Terms of Use.'
  };

export default TermsAndConditions;
