import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FirebaseError } from 'firebase/app';
import { doc, setDoc, updateDoc, collection, getDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useAuth } from '../../hooks/useAuth';
import { COMMON_FIREBASE_ERROR_CODE } from '../../config/errors';
import { firestore } from '../../utils/firebase';
import TopImageSection from '../../component/TopImageSection';
import SignInEmailImage from '../../assets/images/signin-email.svg';
import ForgotPassword from '../forgotPassword';

interface SignInEmailFormProps {
  isDialog?: boolean;
  isWidget?: boolean;
  email?: string;
  showVerifyEmail?: boolean,
  setShowVerifyEmail?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface SignInEmailFormType {
  email: string;
  password: string;
}

export default function SignInEmail(props: SignInEmailFormProps) {
  const { isDialog = false, isWidget = false, email, setShowVerifyEmail } = props;

  const [formSubmitting, setFormSubmitting] = React.useState<boolean>(false);
  const [newsletterSubscription] = React.useState(true);

  const [showForgotPassword, setShowForgotPassword] = React.useState<boolean>(false)

  const { authUser, signInWithEmailPassword, checkUserExistWithEmail } =
    useAuth();

  const navigate = useNavigate();
  const { state } = useLocation();
  const auth = getAuth();

  React.useEffect(() => {
    if (authUser?.documentId) {
      //if user email is unverified redirect user to verify email page
      if (
        auth?.currentUser?.emailVerified !== undefined &&
        auth.currentUser.emailVerified === false
      ) {
        if (isWidget) {
          setShowVerifyEmail(true)
        }
        else {
          navigate('/verify-email', { state: { email: authUser?.email } });
        }
        return;
      } else {
        //else user email is verified redirect user to creator page
        if (!isWidget) {
          let redirectToPath = '/creators';
          if (!authUser?.userName || authUser?.userName === undefined) {
            // redirect user to create profile page if user is new register
            redirectToPath = `/create-profile`;
          }
          navigate(redirectToPath);
        }
      }
    }
  }, [authUser]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (values: SignInEmailFormType) => {
    setFormSubmitting(true);

    const email = values?.email?.toLowerCase()?.trim();
    const password = values.password;

    const isEmailExists = await checkUserExistWithEmail(email);
    if (isEmailExists) {
      handleUserSignIn(email, password);
    } else {
      toast.success('Invalid login details');
    }
  };

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // const userId = user.uid;
      setEmailOptInTrueForCurrentUser();
    } else {
      // The user is signed out
      // console.log('User is signed out');
    }
  });

  const setEmailOptInTrueForCurrentUser = async () => {
    try {
      const currentUser = auth.currentUser;
      const usersCollection = collection(firestore, 'users');
      const userDocRef = doc(usersCollection, currentUser.uid);

      const docSnapshot = await getDoc(userDocRef);

      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        if (!userData.hasOwnProperty('emailOptIn')) {
          await updateDoc(userDocRef, { emailOptIn: newsletterSubscription });
          console.log('EmailOptIn field set to false for the current user.');
        } else {
          console.log('EmailOptIn field already exists for the current user.');
        }
      } else {
        await setDoc(userDocRef, { emailOptIn: newsletterSubscription });
        console.log(
          'User document created with EmailOptIn field set to false.'
        );
      }
    } catch (error) {
      console.error('Error setting EmailOptIn field:', error);
    }
  };

  const handleUserSignIn = (email: string, password: string) => {
    signInWithEmailPassword(email, password)
      .then(() => {
        toast.success('Signed in successfully');
        setFormSubmitting(false);
        setEmailOptInTrueForCurrentUser();
      })
      .catch((error) => {
        const firebaseError = (error as FirebaseError)?.code;
        toast.error(
          firebaseError && COMMON_FIREBASE_ERROR_CODE[firebaseError]
            ? COMMON_FIREBASE_ERROR_CODE[firebaseError]
            : 'Invalid login details'
        );
        setFormSubmitting(false);
      });
  };

  const signinValidationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .strict(true)
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        'Please enter valid email'
      ),
    password: Yup.string().required('Password is required'),
  });

  const initialValues = {
    email: (state?.email as string) || (email as string) || '',
    password: '',
  };

  const handleForgotPassWord = () => {
    setShowForgotPassword(true)
  }

  return (
    <>
    {showForgotPassword ?
      <ForgotPassword isWidget setShowForgotPassword={setShowForgotPassword}/>
    :
    <Box
      display='flex'
      flex={1}
      flexDirection='column'
      alignItems='center'
      justifyContent={isWidget ? 'center':'flex-start'}
      justifyItems='center'
      textAlign='center'
      width='100%'
      mt={isWidget ? 0 : 8}
      sx={{
        px: isDialog ? '0' : '2rem',
      }}
    >
      <Box
        display='flex'
        justifyContent='flex-start'
        alignItems='center'
        flexDirection='column'
        flex={0.5}
        gap={isWidget ? '40px' : ''}
        width='100%'
        sx={{
          '@media (min-width: 768px)': {
            width: isWidget ? '100%' :'50%',
          },
          '@media (min-width: 1440px)': {
            px: isDialog ? '0' : '5rem',
          },
        }}
      >
        {isWidget ? <Box className='widget-common-header'>Enter Password</Box>:<TopImageSection image={SignInEmailImage} title='Sign In' />}

        <Formik
          initialValues={initialValues}
          validationSchema={signinValidationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isValid,
            dirty,
          }) => (
            <Form
              id={'signin-email-form'}
              autoComplete='off'
              style={{ width: '100%' }}
              noValidate
            >
              <Box display='flex' flexDirection='column' gap={3}>
                <Box display='flex' gap={isWidget ? 1.5 : 2} flexDirection='column'>
                  <TextField
                    type='email'
                    margin={isWidget?'none':'normal'}
                    fullWidth
                    disabled={true}
                    id='email'
                    label='Email Address'
                    name='email'
                    autoComplete='off'
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched?.email && errors?.email}
                  />
                  <TextField
                    type='password'
                    margin={isWidget?'none':'normal'}
                    fullWidth
                    id='password'
                    label='Password'
                    name='password'
                    autoComplete='off'
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                  />
                  <Button
                    type='submit'
                    variant='contained'
                    fullWidth
                    disabled={!(isValid && dirty) || formSubmitting}
                    startIcon={
                      formSubmitting && (
                        <CircularProgress size={16} color='inherit' />
                      )
                    }
                  >
                    {'Next'}
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
        <Box
          display='flex'
          flexDirection='column'
          maxWidth='280px'
          mt={isWidget ? 0 : 6}
          gap={2}
        >
          {isWidget ?
          <Typography
            style={{ textDecoration: 'underline', cursor:'pointer'}}
            component='h5'
            variant='h6'
            fontWeight={isWidget ? '500' : '600'}
            fontSize={isWidget ? '1.18rem' : '0.8rem'}
            color='themeBlue.main'
            onClick={handleForgotPassWord}
          >
            Forgot password
          </Typography>
          :
          <Link to={`/forgot-password`}>
            <Typography
              style={{ textDecoration: 'underline' }}
              component='h5'
              variant='h6'
              fontWeight={isWidget ? '500' : '600'}
              fontSize={isWidget ? '1.18rem' : '0.8rem'}
              color='themeBlue.main'
            >
              Forgot password
            </Typography>
          </Link>
          }
        </Box>
      </Box>
    </Box>
    }
    </>
  );
}
