import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import SignInLanding from '../signinLanding';

interface SignInSignUpModalType {
  handleSignInModal: boolean,
  setHandleSignInModal: React.Dispatch<React.SetStateAction<boolean>>,
}

const SignInSignUpModal = (props: SignInSignUpModalType) => {
  const { handleSignInModal, setHandleSignInModal } = props;

  return (
    <Dialog
      open={Boolean(handleSignInModal)}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '750px',
          },
        },
      }}
    >
      <DialogContent>
        <SignInLanding isDialog setHandleSignInModal={setHandleSignInModal}/>
      </DialogContent>
    </Dialog>
  );
};

export default SignInSignUpModal;
