import React, { useState } from 'react';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import styles from './FAQ.module.css';

interface QuestionProps {
  id: number;
  title: string;
  info: string | Array<string | { text: string; link: string }>;
  image?: string;
}

const Question = ({ id, title, info, image }: QuestionProps) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <article className={styles.question} style={{ whiteSpace: 'pre-line'}}>
      <header>
        <h4
          onClick={() => setExpanded(!expanded)}
          className={styles.question + `${title}`}
        >
          {title}
        </h4>
        <button id={`button-expand-question-${id}`} className={styles.btn} onClick={() => setExpanded(!expanded)}>
          {expanded ? <AiOutlineMinus /> : <AiOutlinePlus />}
        </button>
      </header>
      {expanded && (
        <>
          {Array.isArray(info) ? (
            <p>
              {info.map((part, index) =>
                typeof part === 'string' ? (
                  part
                ) : (
                  <a
                    id={`href-question-${id}`}
                    key={index}
                    href={part.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {part.text}
                  </a>
                )
              )}
            </p>
          ) : (
            <p>{info}</p>
          )}
          <p className={styles.imageIcon}>
            {image && <img src={image} alt="" />}
          </p>
        </>
      )}
    </article>
  );
};

export default Question;
