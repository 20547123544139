export const COMMON_FIREBASE_ERROR_CODE: Record<string, string> = {
  'auth/user-not-found': 'User not found',
  'auth/missing-email': 'Incorrect Email',
  'auth/wrong-password': 'Incorrect Password',
  'auth/too-many-requests':
    'Access to this account has been temporarily disabled due to many failed login attempts. Please try after sometime or contact administrator.',
  'auth/user-disabled':
    'The account has been temporarily disabled. Please contact your administrator.',
  'auth/user-token-expired': 'Session expired. Please login again.',
  'auth/web-storage-unsupported': 'Browser not supported.',
  'custom-error/user-auth-not-allowed-reset-password':
    'Your account is signed up with Google and cannot reset your password. Please sign in with your Google account.',
  'auth/weak-password': 'Password should be at least 6 characters.',
};

export const ERROR_MESSAGES: Record<string, string> = {
  '400': "Something went wrong with your request. Please try again.",
  '401': "Unauthorized request. Please login and try again.",
  '403': "Unauthorized request. Please login and try again.",
  '404': "The requested resource could not be found. Please try again later.",
  '500': "An internal server error occurred. Please try again later.",
  '504': "We are experiencing delays due to increased demand. Please try again.",
  'default': "An unknown error occurred. Please try again.",
}
