import React, { createContext, memo } from 'react';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

interface StripeContextType {
  stripePromise: any;
}

export const StripeContext = createContext<StripeContextType>({
  stripePromise: null,
});

export const StripeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {

  return (
    <StripeContext.Provider
      value={{ stripePromise, }}
    >
      {children}
    </StripeContext.Provider>
  );
};

export default memo(StripeProvider);
