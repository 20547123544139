import * as React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../../hooks/useAuth';
import {
  FormMode,
  UserCreateProfileFormType,
} from '../creatorOnBoarding/signup.types';

interface CreateUpdateProfileFormPropsType {
  formMode: FormMode;
}

export default function CreateUpdateProfileForm(
  props: CreateUpdateProfileFormPropsType
) {
  const { formMode } = props;
  const [formSubmitting, setFormSubmitting] = React.useState<boolean>(false);
  const [ redirectURL, setRedirectURL ] = React.useState('/creators')
  const [ redirectState, setRedirectState ] = React.useState(null)
  const navigate = useNavigate();
  const location = useLocation();

  const { authUser, checkUserExistWithUsername, updateCreatedProfileData } =
    useAuth();

  React.useEffect(() => {
    // store redirect information
    if (location?.state) {
      if (location.state.redirectURL) setRedirectURL(location.state.redirectURL)
      if (location.state.redirectState) setRedirectState(location.state.redirectState)
    } else if (window.location.hostname === 'sambatv.soopra.ai' || window.location.hostname === 'sambatv.mimio.ai'){
      setRedirectURL('/sambatv/chat');
    } else if (window.location.hostname === 'stanford.soopra.ai') {
      setRedirectURL('/creators');
    }
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (authUser?.documentId) {
      setInitialUserData(authUser);
    }
  }, [authUser]); //eslint-disable-line react-hooks/exhaustive-deps

  const setInitialUserData = (userData: any) => {
    let userNameVal =
      authUser?.fullName?.toLowerCase().replace(/ /g, '_') || '';
    if (formMode === 'update') {
      userNameVal = authUser?.userName;
    }
    formRef?.current?.setValues({
      documentId: userData.documentId,
      profilePhoto: authUser?.profilePicUrl || '',
      fullName: authUser?.fullName || '',
      userName: userNameVal,
    });
  };

  const formRef: any = React.useRef();

  const formValidationSchema = Yup.object().shape({
    fullName: Yup.string()
      .required('Full name is required')
      .trim('Please enter valid full name'),
    userName: Yup.string()
      .required('Username is required')
      .matches(/^(\S+$)/g, 'Please enter valid username'),
    profilePhoto: Yup.mixed().notRequired(),
  });

  const initialValues = {
    documentId: '',
    fullName: '',
    userName: '',
    profilePhoto: null,
  };

  const handleSubmit = async (values: UserCreateProfileFormType) => {
    setFormSubmitting(true);
    if (formMode === 'create') {
      const isUserNameExists = await checkUserExistWithUsername(
        values?.userName
      );
      if (isUserNameExists) {
        toast.error('Account with this username already exists');
        setFormSubmitting(false);
        return;
      }
    }

    updateCreatedProfileData(values, formMode)
      .then(() => {
        // Conditionally set the navigation path based on the hostname
        navigate(redirectURL, {state: redirectState});

        if (!(window.location.hostname === 'sambatv.soopra.ai' || window.location.hostname === 'sambatv.mimio.ai' || window.location.hostname === 'stanford.soopra.ai')) {
          let msg = 'Profile created successfully';
          if (formMode === 'update') {
            msg = 'Profile updated successfully';
          }
          toast.success(msg);
        }
      })
      .catch(() => {
        toast.error('Sorry there was an error. Please try again later');
      });
  };
  const avatarInput = React.useCallback(
    (values, touched, setTouched, setFieldValue) => {
      const profileUploaded =
        values?.profilePhoto &&
          typeof values?.profilePhoto === 'object' &&
          values?.profilePhoto?.name
          ? URL.createObjectURL(values?.profilePhoto)
          : values?.profilePhoto && typeof values?.profilePhoto === 'string'
            ? values?.profilePhoto
            : '';
      return (
        <Box
          border='1px solid #B3B3B3;'
          borderRadius='12px'
          display='flex'
          gap={1}
          flexDirection='column'
          alignItems='center'
          py={2}
        >
          <input
            type='file'
            accept='image/*,image/heif,image/heic'
            id='file-input-profile-photo'
            name='profilePhoto'
            hidden
            onChange={(event) => {
              setTouched({ ...touched, profilePhoto: true });
              setFieldValue('profilePhoto', event?.currentTarget?.files?.[0]);
            }}
          />
          <label htmlFor='file-input-profile-photo' style={{ cursor: 'pointer' }}>
            <Badge
              overlap='circular'
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              badgeContent={
                <Box display='flex' alignItems='center' justifyContent='center'>
                  {profileUploaded ? (
                    <EditOutlinedIcon
                      sx={{
                        height: '16px',
                        width: '16px',
                      }}
                    />
                  ) : (
                    <AddIcon
                      sx={{
                        height: '20px',
                        width: '20px',
                      }}
                    />
                  )}
                </Box>
              }
              color='primary'
              sx={{
                '& .MuiBadge-badge': {
                  border: '2px solid #fff',
                  height: '28px',
                  width: '28px',
                  padding: '4px',
                  borderRadius: '50px',
                  backgroundColor: profileUploaded
                    ? '#A8A8A8'
                    : 'themeBlue.main',
                },
              }}
            >
              <Avatar
                alt={authUser?.fullName}
                src={profileUploaded}
                sx={{
                  width: 76,
                  height: 76,
                }}
              />
            </Badge>
          </label>
          <Typography component='h5' variant='body2' color='#818181'>
            {profileUploaded ? 'Edit Avatar' : 'Add Avatar'}
          </Typography>
        </Box>
      );
    },
    [] //eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formValidationSchema}
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isValid,
        dirty,
        setTouched,
        setFieldValue,
      }) => (
        <Form
          id={'form-create-account'}
          autoComplete='off'
          style={{ width: '100%' }}
          noValidate
        >
          <Box display='flex' flexDirection='column' gap={3}>
            <Box display='flex' gap={2} flexDirection='column'>
              {avatarInput(values, touched, setTouched, setFieldValue)}
              {touched?.profilePhoto && Boolean(errors?.profilePhoto) && (
                <Typography
                  variant='body1'
                  color={'error'}
                  fontSize={'0.75rem'}
                  mt={1}
                >
                  {errors?.profilePhoto as string}
                </Typography>
              )}
              <TextField
                margin='normal'
                fullWidth
                id='text-input-fullName'
                label='Full Name'
                name='fullName'
                autoComplete='off'
                value={values.fullName}
                disabled={formSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.fullName && Boolean(errors.fullName)}
                helperText={
                  (touched?.fullName as boolean) && (errors?.fullName as string)
                }
              />
              <TextField
                margin='normal'
                fullWidth
                id='text-input-userName'
                label='Username'
                name='userName'
                autoComplete='off'
                disabled={formSubmitting || formMode === 'update'}
                value={values.userName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.userName && Boolean(errors.userName)}
                helperText={
                  (touched.userName as boolean) && (errors.userName as string)
                }
              />
              <Button
                id='button-next'
                type='submit'
                variant='contained'
                fullWidth
                disabled={!(isValid && dirty) || formSubmitting}
                startIcon={
                  formSubmitting && (
                    <CircularProgress size={16} color='inherit' />
                  )
                }
              >
                {'Next'}
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
