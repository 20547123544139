import { Box, Typography, IconButton, Select, MenuItem, Button } from '@mui/material'
import * as React from 'react'
import { gapi } from 'gapi-script';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { ArrowBackRounded, DoNotDisturbAlt } from '@mui/icons-material';
import VideoChatIcon from '../../svg/VideoChatIcon';
import AttendWithChatIcon from '../../svg/AttendWithChatIcon';
import MeethAttendanceTypeBox from './meethAttendanceTypeBox';
import OpenInNewIcon from '../../svg/OpenInNewIcon';
import { event } from 'react-ga';

interface MeethMeetingProps { 
  accessToken: String,
  user_id: String,
  meethFullScreen: boolean,
  handleMeethFullScreen: Function,
}


const MeethMeetings = (props: MeethMeetingProps) =>  {

    const {accessToken, user_id, meethFullScreen, handleMeethFullScreen} = props;
    const apiEndpoint = process.env.REACT_APP_API_END_POINT;
    const [events, setEvents] = React.useState([]);
    const [dateToShow, setDateToShow] = React.useState(dayjs());
    const [isDatePickerOpen, toggleDatePickerState] = React.useState(false);
    const [attendanceType, setAttendanceType] = React.useState({})
    const [showAttendanceTypeBox, toggleAttendanceTypeBox] = React.useState(false);
    const [eventData, setEventData] = React.useState<boolean>(null)
    const [userEmail, setUserEmail] = React.useState<String>('')

    React.useEffect(() => {

      const fetchEvents = async (startDate) => {
        try {
          const response = await gapi.client.calendar.events.list({
            calendarId: 'primary',
            timeMin: startDate.toISOString(),
            showDeleted: false,
            singleEvents: true,
            maxResults: 10,
            orderBy: 'startTime',
          });
          
            // Wait for the response and then set events
            setEvents(response.result.items);

            // get events and set event attendance type
            events.map((event) => {
              event.attendees?.map((attendees) => {
                attendees.self && attendees.responseStatus == 'declined' && handleAttendanceSelection(event.id, 'Not Attending')
              })
            })

          } catch (error) {
            console.error('Error fetching events:', error);
          }
      };

      if (accessToken) {
        gapi.load('client:auth2', async () => {
          try {
            await gapi.client.init({
              // apiEndpoint: process.env.REACT_APP_GOOGLE_API_KEY,
              clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
              discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
              scope: "email profile openid https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
            })
  
            gapi.client.setToken({ access_token: accessToken });
            fetchEvents(dateToShow.hour(0))
          } catch (error) {
            window.alert(`Error: ${error}`);
          }
        });
      }
    }, [accessToken, dateToShow]);

    const handleAttendanceSelection = (eventId, type) => {
      setAttendanceType((prevState) => ({
        ...prevState,
        [eventId]: type,  // Store attendance type for each event
      }));
    };

    const handleJoinMeet = async (link) => {
      window.alert(link)
      const payload = {
        meeting_id: link,
        user_ids: [user_id],
      };
  
      try {
        const response = await fetch(`${apiEndpoint}/meetings/configure?token=a4f9e23b8c16d5a072e7b3f4d9c81e5b`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });
  
        if (response.ok) {
          window.alert('Data submitted successfully');
        } else {
          window.alert('Failed to submit data');
        }
      } catch (error) {
          window.alert(`Error: ${error}`);
      }
    }

    const groupByDate = (events) => {
      return events.reduce((grouped, event) => {
        const eventDate = new Date(event.start.dateTime || event.start.date).toLocaleDateString(undefined, {
          weekday: 'long',
          month: 'short',
          day: 'numeric',
        });
        if (!grouped[eventDate]) {
          grouped[eventDate] = [];
        }
        grouped[eventDate].push(event);
        return grouped;
      }, {});
    };

    const handlePickerIcon = (props) => {
      return isDatePickerOpen ?
        <ExpandLessRoundedIcon {...props} sx={{ color: 'black' }} />
        :
        <ExpandMoreRoundedIcon {...props} sx={{ color: 'black' }} />
    }

    const GetWordToDisplay = () => {
      const today = dayjs();  // Get today's date
      const yesterday = today.subtract(1, 'day');  // Get yesterday's date
      const tomorrow = today.add(1, 'day');  // Get tomorrow's date

      // Determine the display text based on dateToShow
      const displayText = dateToShow.isSame(today, 'day') 
        ? "Today" 
        : dateToShow.isSame(tomorrow, 'day')
        ? "Tom"
        : dateToShow.isSame(yesterday, 'day')
        ? "Yest"
        : (dateToShow.diff(today, 'day') < 6 && dateToShow.diff(today, 'day') >= 0)
        ? dateToShow.format('ddd')
        : dateToShow.format("MMM DD");
      return(
        <Box
        sx={{
          padding: '2px 15px',
          border: '1px solid #C2C2C2',
          borderRadius: '5px',
          display: 'inline-block',
          width: '90px',
          textAlign: 'center',
          overflow: 'hidden',
        }}
        >
          <Typography fontWeight={400} color="#000">
            {displayText}
          </Typography>
        </Box>
      )
    }

    const handleShowAttendanceTypeBox =(event) => {
      toggleAttendanceTypeBox(true)
      setEventData(event)
    }

    const groupedEvents = groupByDate(events);
    return(
        <Box display='flex' flexDirection='column' width='100%' gap='10px' height={'100vh'} overflow={'hidden'}>
          <Box display='flex' flexDirection={meethFullScreen ? 'row': 'column'} alignItems={meethFullScreen && 'center'} gap='20px' padding='10px'>
            <Box display='flex' flexDirection='row' width='100%' gap={meethFullScreen && '20px'} justifyContent={!meethFullScreen && 'space-between'}>
                {meethFullScreen && <Box onClick={() => handleMeethFullScreen(!meethFullScreen)} sx={{cursor: 'pointer'}}>
                    <ArrowBackRounded/>
                </Box>}
                <Typography fontWeight={600} color='#000000'>
                    Meetings
                </Typography>
                {!meethFullScreen && <Box onClick={() => handleMeethFullScreen(!meethFullScreen)} sx={{cursor: 'pointer'}}>
                    <OpenInNewIcon/>
                </Box>}
            </Box>
            <Box display='flex' flexDirection='row' justifyContent={'space-between'} >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker 
                  value={dateToShow}
                  onChange={(newDate) => setDateToShow(newDate.hour(0))}
                  onOpen={() => toggleDatePickerState(true)}  // Set focus state to true when open
                  onClose={() => toggleDatePickerState(false)} 
                  format='ddd, MMM DD'
                  className='date-picker'
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused fieldset": { borderColor: "transparent" }, //styles the label
                    "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: "transparent" },
                    "&:hover > fieldset": { borderColor: "transparent"},
                    },
                  }}
                  slots={{openPickerIcon: (props) => handlePickerIcon(props)}}
                />
              </LocalizationProvider>
              <Box display='flex' flexDirection='row' gap='5px' justifyContent='center' alignItems='center'>
                <KeyboardArrowLeftRoundedIcon onClick={() => setDateToShow(dateToShow.subtract(1, 'day'))} sx={{cursor: 'pointer','&:hover': {color: '#575757'}}}/>
                <GetWordToDisplay/>
                <KeyboardArrowRightRoundedIcon onClick={() => setDateToShow(dateToShow.add(1, 'day'))} sx={{cursor: 'pointer','&:hover': {color: '#575757'}}}/>
              </Box>
            </Box>     
          </Box>
          {!meethFullScreen && <Box width={'100%'} height={'1vh'} sx={{background: 'linear-gradient(to bottom, rgb(0,0,0,0.05) , rgb(255,255,255))'}}>
          </Box>}
          <Box
            sx={{
              width: '100%',  // Restrict width to 30% of the parent container
              marginTop: '10px',  // Center the content
              position: 'relative',  // Make it the parent for the absolute-positioned drawer
              overflow: showAttendanceTypeBox ? 'none':'scroll',
            }}
            padding='10px'
          >
            {showAttendanceTypeBox ? 
            <MeethAttendanceTypeBox showAttendanceTypeBox={showAttendanceTypeBox} toggleAttendanceTypeBox={toggleAttendanceTypeBox} event={eventData} handleAttendanceSelection={handleAttendanceSelection} attendanceType={attendanceType}/>
          
          :
          Object.keys(groupedEvents).map((date) => {
            const isToday = new Date().toLocaleDateString(undefined, { 
              weekday: 'long', month: 'short', day: 'numeric' 
            }) === date;
            return(
            <Box key={date} className="meeting-date-group">
              <Typography fontWeight={600} color={isToday?"#00A3FF":"#000000"} bgcolor={ meethFullScreen && '#F7F7F7'} padding={meethFullScreen && '8px'} borderRadius={meethFullScreen && '8px'}>{date}</Typography>
              <Box>
                {groupedEvents[date].map((event) => {
                    const isNotAttending = event.attendees?.some(att => att.responseStatus === 'declined');
                    const isLastElement = event == groupedEvents[date][groupedEvents[date].length - 1];
                    // Check if the event has already finished
                    const now = new Date();
                    const eventEndTime = new Date(event.end.dateTime || event.end.date);
                    const isCompleted = now > eventEndTime; // Event is completed if it's today and current time is after end time
                    
                    // Format start and end times
                    const startTime = new Date(event.start.dateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                    const endTime = new Date(event.end.dateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                  return(
                    <>
                    {meethFullScreen ?
                      <Box key={event.id} className={`meeting-fullscreen-card ${isNotAttending || attendanceType[event.id] == 'Not Attending' ? "not-attending" : ""} ${isCompleted ? 'disabled-event' : ''}`} padding={'10px'} borderBottom={!isLastElement && '1px solid #DBDBDB'}>
                        <Box className="meeting-full-screen-details" display={'grid'} gridTemplateColumns={'15% 40% 15% 20% 10%'} width={'100%'} alignItems={'center'}>
                          
                          <Typography color='#000000'>{startTime} - {endTime}</Typography>
                          <Typography fontWeight={'400'} color='#000000'>{event.summary}</Typography>
                          <Box>
                          {event.hangoutLink && (
                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'10px'} sx={{cursor:'pointer'}}>
                              {attendanceType[event.id] == 'Chat' ? 
                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'5px'} sx={{cursor:'pointer'}}>
                                  <AttendWithChatIcon color={'#6C7072'}/> 
                                  <Typography color={'#6C7072'}>Chat</Typography>
                                </Box> : 
                                attendanceType[event.id] == 'Video' ?
                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'5px'} sx={{cursor:'pointer'}}>
                                  <VideoChatIcon />
                                  <Typography color={'#6C7072'}>Video</Typography>
                                </Box> :
                                isNotAttending || attendanceType[event.id] == 'Not Attending' ?
                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'5px'} sx={{cursor:'pointer'}}>
                                  <DoNotDisturbAlt/>
                                  <Typography color={'#6C7072'}>Not Attending</Typography>
                                </Box>
                                :
                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'5px'} sx={{cursor:'pointer'}}>
                                  Select
                                </Box>
                              }
                              <ExpandMoreRoundedIcon onClick={() => handleShowAttendanceTypeBox(event)}/>
                            </Box>
                          )}
                          </Box>
                          <Typography color={'#000000'}>Meeting ID: {event.conferenceData?.conferenceId || 'Not a Google Meet'}</Typography>
                          {attendanceType[event.id] && 
                          <Button 
                            disabled={attendanceType[event.id] == 'Not Attending'}
                            onClick={() => handleJoinMeet(event.hangoutLink)}
                            size='small'
                            sx={{
                              bgcolor: '#00A3FF',
                              color: 'white',
                              "&:hover": {
                                bgcolor: '#24afff'
                              }
                          }}>
                          Join Meeting
                          </Button>}
                        </Box>
                      </Box>
                    :
                      <Box key={event.id} className={`meeting-card ${isNotAttending || attendanceType[event.id] == 'Not Attending' ? "not-attending" : ""} ${isCompleted ? 'disabled-event' : ''}`} width={'100%'}>
                        <Box className="meeting-details" width={'100%'}>
                          <Typography fontWeight={'600'} color='#000000'>{event.summary}</Typography>
                          <Typography color='#000000'>{startTime} - {endTime}</Typography>
                          <Box display={'flex'} flexDirection={'row'} gap={'5px'} justifyContent={'space-between'} width={'100%'}>
                          {event.hangoutLink && (
                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'10px'} sx={{cursor:'pointer'}}>
                              {attendanceType[event.id] == 'Chat' ? 
                                <Box  display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'5px'} sx={{cursor:'pointer'}}>
                                  <AttendWithChatIcon color={'#6C7072'}/> 
                                  <Typography color={'#6C7072'}>Chat</Typography>
                                </Box> : 
                                attendanceType[event.id] == 'Video' ?
                                <Box  display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'5px'} sx={{cursor:'pointer'}}>
                                  <VideoChatIcon />
                                  <Typography color={'#6C7072'}>Video</Typography>
                                </Box>
                                :
                                isNotAttending || attendanceType[event.id] == 'Not Attending'?
                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'5px'} sx={{cursor:'pointer'}}>
                                  <DoNotDisturbAlt/>
                                  <Typography color={'#6C7072'}>Not Attending</Typography>
                                </Box>
                                :
                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'5px'} sx={{cursor:'pointer'}}>
                                  Select
                                </Box>
                              }
                              <ExpandMoreRoundedIcon onClick={() => handleShowAttendanceTypeBox(event)}/>
                            </Box>
                          )}
                          {(attendanceType[event.id] == 'Video' || attendanceType[event.id] == 'Chat') && 
                          <Button 
                            onClick={() => handleJoinMeet(event.hangoutLink)}
                            size='small'
                            sx={{
                              bgcolor: '#00A3FF',
                              color: 'white',
                              "&:hover": {
                                bgcolor: '#24afff'
                              }
                          }}>
                          Join
                          </Button>}
                          </Box>
                        </Box>
                      </Box>
                      }

                    </>
                )})}
              </Box>
            </Box>
          )})}
          </Box>
        </Box>
    )
}

export default MeethMeetings;