import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import CreatorListImage from '../../assets/images/creator-list.svg';
import StarsIcon from '../../svg/StarsIcon';
import { Link } from 'react-router-dom';

const CreateMimioItem = () => {
  return (
    <>
      <img
        src={CreatorListImage}
        alt='logo'
        width='60%'
        style={{
          maxWidth: '300px',
        }}
      />
      <Box
        display='flex'
        flexDirection='column'
        gap={2}
        flex={1}
        mt={-2}
        width='100%'
        justifyContent='center'
        alignItems='center'
      >
        <Typography component='h5' fontSize='1.1rem' fontWeight='600'>
          Create Your AI
        </Typography>
        <Typography
          color='#000'
          fontWeight='400'
          fontSize='0.9rem'
          lineHeight='normal'
          sx={{
            width: '100%',
            maxWidth: '380px',
          }}
        >
          Your AI can be available 24/7 help you scale your support.
        </Typography>
        <Link
          id='href-creator-onboarding'
          to={`/creator-onboarding`}
          style={{
            width: '100%',
          }}
        >
          <Button
            id='button-create-MIMIO'
            variant='contained'
            fullWidth
            startIcon={<StarsIcon />}
            sx={{
              '&.MuiButton-contained': {
                fontWeight: 500,
              },
            }}
          >
            Create My AI Persona
          </Button>
        </Link>
      </Box>
    </>
  );
};
export default React.memo(CreateMimioItem);
