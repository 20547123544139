import React, { useState, useRef, useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { Link } from 'react-router-dom';
import ContactUsModal from '../userChat/contactUsModal';
import Profile from './profile';
import { LIMITED_RELEASE_USERS, USER_TYPE } from '../../config/const';
import { useAuth } from '../../hooks/useAuth';
import FanDemo from '../fanTutorial';
import { auth } from '../../utils/firebase';
import { Button, Dialog, DialogContent, Typography } from '@mui/material';
import TiersCard from '../Purchases/TiersCard';
import CloseIcon from '../../svg/CloseIcon';

const enableMonetization = process.env.REACT_APP_ENABLE_MONETIZATION === 'true';
const enableCourses = process.env.REACT_APP_SHOW_MY_COURSE === 'true';

const HamburgerMenu = () => {
  const [open, setOpen] = useState(false);
  const [isFan, setIsFan] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [ showTiersCard, setShowTiersCard ] = useState<boolean>(false);
  const [handleContactModal, setHandleContactModal] =
    React.useState<boolean>(false);
  const { authUser, isAnonymousUser } = useAuth();
  const buttonRef = useRef(null);
  const userType = authUser?.userType;

  const DashboardCondition = () => {
    return (
      <Link id='href-dashboard' to='/dashboard'>
        <ListItem >
          <ListItemButton id='button-dashboard'>
            <ListItemText primary='Dashboard' />
          </ListItemButton>
        </ListItem>
      </Link>
    );
  };

  const ProfileCondition = () => {
    const handleProfileClick = () => {
      const profilePath = `/${authUser?.userName}`;
      // Using window.location to force a hard refresh
      window.location.href = window.location.origin + profilePath;
    };

    return (
      <ListItem onClick={handleProfileClick}>
        <ListItemButton id='button-profile'>
          <ListItemText primary='Profile' />
        </ListItemButton>
      </ListItem>
    );
  };

  const PurchasesCondition = () => {
    const isStanfordUrl = window.location.hostname === 'stanford.soopra.ai';

    if (isStanfordUrl) {
      return null;
    }

    return (
      <Link id='href-purchases' to={`/${authUser?.userName}/purchases`}>
        <ListItem>
          <ListItemButton id='button-dashboard'>
            <ListItemText primary='Purchases' />
          </ListItemButton>
        </ListItem>
      </Link>
    );
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleButtonClick = () => {
    setOpen(!open);
  };

  const handleContactUsModalClose = () => {
    setHandleContactModal(false);
  };

  const checkIfFan = async () => {
    try {
      if (auth.currentUser) {
        if (isFan !== (authUser?.userType === 'fan')) {
          setIsFan(authUser?.userType === 'fan')
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (open) {
      checkIfFan();
    }
  }, [open]); //eslint-disable-line react-hooks/exhaustive-deps

  const isStanfordUrl = window.location.hostname === 'stanford.soopra.ai';

  return (
    <>
      <div style={{ marginLeft: '16px' }}>
        <IconButton
          id='button-toggle-hamburger-menu'
          ref={buttonRef}
          edge='start'
          color='inherit'
          aria-label='menu'
          onClick={handleButtonClick}
        >
          <MenuIcon />
        </IconButton>
      </div>
      <Drawer
        anchor='right'
        open={open}
        onClose={toggleDrawer}
        sx={{
          width: '350px',
        }}
        PaperProps={{
          style: {
            width: '350px',
          },
        }}
      >
        <div role='presentation'>
          <List>
            {!isAnonymousUser && auth.currentUser && (
              <ListItem
                sx={{
                  marginTop: '10px',
                  borderBottom: isFan ? 'thin solid #ebecf0' : 'none',
                }}
              >
                <Profile handleButtonClick={handleButtonClick} isFan={isFan} />
              </ListItem>
            )}

            {
              !window.location.hostname.includes('stanford.soopra.ai') &&
              authUser &&
              LIMITED_RELEASE_USERS.includes(authUser?.userName) &&
              <ListItem>
                <Dialog
                  open={showTiersCard}
                  onClose={() => setShowTiersCard(false)}
                  fullWidth
                  maxWidth='lg'
                >
                  <DialogContent>
                    <IconButton
                      id='button-close-tiers-card'
                      onClick={() => setShowTiersCard(false)}
                      color='primary'
                      sx={{
                        position: 'absolute',
                        alignSelf: 'flex-end',
                        minWidth: '36px',
                        minHeight: '36px',
                        height: '36px',
                        width: '36px',
                        top: '16px',
                        right: '16px',
                      }}
                    >
                      <CloseIcon height='30px' width='30px' />
                    </IconButton>
                    <TiersCard/>
                  </DialogContent>
                </Dialog>
                <Button
                  variant='contained'
                  size='small'
                  onClick={() => setShowTiersCard(true)}
                  sx={{
                    ml: 2,
                    pl: 2,
                    pr: 2
                  }}
                >
                  Manage My Subscription
                </Button>
              </ListItem>
            }

            <span onClick={toggleDrawer}>
              {userType === USER_TYPE.CREATOR && <ProfileCondition />}
              {userType === USER_TYPE.CREATOR && <DashboardCondition />}
              {( enableMonetization || (enableCourses || authUser?.beta_tester) ) && <PurchasesCondition />}

              <Link id='href-creators' to='/creators'>
                <ListItem style={{borderTop: 'thin solid #ebecf0',}}>
                  <ListItemButton id='button-creators'>
                    <ListItemText primary='Creators' />
                  </ListItemButton>
                </ListItem>
              </Link>

              {(!isStanfordUrl && (enableCourses || authUser?.beta_tester)) &&
                <Link id='href-courses' to='/courses'>
                  <ListItem>
                    <ListItemButton id='button-courses'>
                      <Typography sx={{mr: 1, color: '#3EACEF'}}>*New*</Typography>
                      <ListItemText primary='Courses' />
                    </ListItemButton>
                  </ListItem>
                </Link>
              }

              <Link id='href-faq' to='/faq'>
                <ListItem>
                  <ListItemButton id='button-FAQs'>
                    <ListItemText primary='FAQs' />
                  </ListItemButton>
                </ListItem>
              </Link>

              <Link id='href-terms' to='/terms'>
                <ListItem>
                  <ListItemButton id='button-terms' >
                    <ListItemText primary='Terms and Conditions' />
                  </ListItemButton>
                </ListItem>
              </Link>

              <ListItem onClick={() => setHandleContactModal(true)}>
                <ListItemButton id='button-contact' >
                  <ListItemText primary='Contact Us' />
                </ListItemButton>
              </ListItem>

              {isFan && (
                <ListItem onClick={() => setDialogOpen(true)}>
                  <ListItemButton id='button-tutorial' >
                    <ListItemText primary='Tutorial' />
                  </ListItemButton>
                </ListItem>
              )}

              {!isAnonymousUser && auth.currentUser && (
                <Link id='href-settings' to='/logout-user'>
                  <ListItem>
                    <ListItemButton id='button-settings' >
                      <ListItemText primary='Sign Out' />
                    </ListItemButton>
                  </ListItem>
                </Link>
              )}
            </span>
          </List>
        </div>
      </Drawer>
      {dialogOpen && <FanDemo onClose={() => setDialogOpen(false)} />}
      <ContactUsModal
        handleContactModal={handleContactModal}
        handleContactUsModalClose={handleContactUsModalClose}
      />
    </>
  );
};

export default HamburgerMenu;
