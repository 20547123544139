import { Box, Button, Dialog, DialogContent, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import EditCustomPriceCard from "./editCustomPriceCard";
import CloseIcon from '../../svg/CloseIcon';
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

const EditCustomPriceCardModal: React.FC = () => {
  const [ showModal, setShowModal ] = useState<boolean>(false);
  const eventTracker = useAnalyticsEventTracker();

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      pt={2}
    >
      <Typography
        variant='subtitle2'
        fontSize='1.1rem'
        color='#000'
        fontWeight={600}
        pb={0.5}
      >
        Price Card
      </Typography>

      <Typography variant='subtitle2' fontSize='0.8rem' pb={1}>
      Monetize your AI persona by charging subscribers a daily, monthly, or yearly fee for access.
      </Typography>

      <Button
        fullWidth
        variant="outlined"
        onClick={() => {
          setShowModal(true)
          eventTracker('edit-price-card open')
        }}
      >
        Edit Price Card
      </Button>
      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        fullWidth
        maxWidth='md'
      >
        <DialogContent>
          <IconButton
            id='button-close-edit-price-card'
            onClick={() => setShowModal(false)}
            color='primary'
            sx={{
              position: 'absolute',
              alignSelf: 'flex-end',
              minWidth: '36px',
              minHeight: '36px',
              height: '36px',
              width: '36px',
              top: '16px',
              right: '16px',
            }}
          >
            <CloseIcon height='30px' width='30px' />
          </IconButton>
          <EditCustomPriceCard onSaveFunction={() => {
            eventTracker('edit-price-card save-success')
            setShowModal(false)
          }}/>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default EditCustomPriceCardModal;
