import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSimpleDialog } from '../hooks/useSimpleDialog';
import { TRAIN_SECTION_LEVELS } from '../config/const';

export default function SimpleDialog() {
  const { data, isOpen, setIsOpen } = useSimpleDialog();

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{data?.title}</DialogTitle>
        {data?.content && (
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {data?.content}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button id='button-training-dialog' onClick={handleClose} autoFocus>
            {data?.levelId === TRAIN_SECTION_LEVELS?.LEVEL3?.value
              ? 'Done'
              : 'Continue'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
