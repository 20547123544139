import * as React from 'react';

const TikTokIcon = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={'0.8em'}
      height={'0.8em'}
      viewBox='0 0 800 800'
      {...props}
    >
      <defs>
        <clipPath id='tiktok-icon' clipPathUnits='userSpaceOnUse'>
          <path d='M0 600h600V0H0Z' />
        </clipPath>
      </defs>
      <path
        d='M0 0h600v600H0Z'
        style={{
          fill: '#000',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform='matrix(1.33333 0 0 -1.33333 0 800)'
      />
      <g
        clipPath='url(#tiktok-icon)'
        transform='matrix(1.33333 0 0 -1.33333 0 800)'
      >
        <path
          d='M0 0v16.286c-5.453.742-10.993 1.206-16.646 1.206-68.117 0-123.532-55.417-123.532-123.533 0-41.794 20.881-78.778 52.737-101.144-20.56 22.073-32.763 51.64-32.763 84.114C-120.204-55.934-66.77-1.56 0 0'
          style={{
            fill: '#25f4ee',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform='translate(260.178 342.897)'
        />
        <path
          d='M0 0c30.391 0 55.537 24.522 56.665 54.644l.256 268.587 48.708-.018-.075.404a93.354 93.354 0 0 0-1.585 17.03H36.854l-.108-268.973c-1.129-30.12-25.918-54.295-56.309-54.295-9.445 0-18.34 2.361-26.17 6.482C-35.479 9.65-18.823 0 0 0'
          style={{
            fill: '#25f4ee',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform='translate(263.095 163.06)'
        />
        <path
          d='M0 0v15.757c-18.745 0-36.205 5.573-50.848 15.12C-37.811 15.903-20.101 4.303 0 0'
          style={{
            fill: '#25f4ee',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform='translate(460.437 394.578)'
        />
        <path
          d='M0 0c-14.284 16.405-22.963 37.813-22.963 61.221h-18.285C-36.514 35.649-21.029 13.711 0 0'
          style={{
            fill: '#fe2c55',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform='translate(409.589 425.455)'
        />
        <path
          d='M0 0c-31.107 0-56.791-25.82-56.791-56.93 0-21.662 12.664-40.484 30.621-49.934-6.692 9.274-10.684 20.619-10.684 32.904 0 31.108 25.31 56.418 56.417 56.418 5.807 0 11.378-.958 16.647-2.608v68.517c-5.453.741-10.993 1.206-16.647 1.206-.978 0-1.944-.051-2.917-.074l-.258-.013-.182-51.864C10.937-.728 5.807 0 0 0'
          style={{
            fill: '#fe2c55',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform='translate(243.532 293.785)'
        />
        <path
          d='m0 0-.312.061-.016-51.822c-34.8 0-67.135 11.454-93.458 30.348l.021-137.114c0-68.118-55.024-123.19-123.14-123.19-26.323 0-50.73 7.96-70.795 22.046 22.569-24.226 54.715-39.419 90.358-39.419 68.116 0 123.531 55.415 123.531 123.532v136.392c26.324-18.894 58.574-30.027 93.374-30.027v67.115C12.854-2.078 6.312-1.352 0 0'
          style={{
            fill: '#fe2c55',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform='translate(460.437 395.383)'
        />
        <path
          d='M0 0v136.392c26.323-18.894 58.573-30.027 93.373-30.027v52.162c-20.101 4.304-37.81 15.098-50.848 30.073-21.029 13.711-36.206 35.649-40.94 61.221h-49.137l-.108-268.973c-1.128-30.121-25.918-54.295-56.309-54.295-18.823 0-35.479 9.301-45.733 23.512-17.957 9.451-30.247 28.273-30.247 49.935 0 31.109 25.31 56.418 56.417 56.418 5.807 0 11.377-.957 16.647-2.607v52.618C-173.655 104.869-227.5 50.107-227.5-17.03c0-32.474 12.613-62.041 33.173-84.114 20.065-14.085 44.472-22.388 70.795-22.388C-55.416-123.532 0-68.117 0 0'
          style={{
            fill: '#fff',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform='translate(367.063 236.856)'
        />
      </g>
    </svg>
  );
};
export default React.memo(TikTokIcon);
