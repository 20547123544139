import React, { useEffect, useState } from 'react';
import { handleTwitterCallback } from '../../utils/api';
import { useAuth } from '../../hooks/useAuth';

const TwitterCallback = () => {
    const [isProcessing, setIsProcessing] = useState(false);
    const { authUser } = useAuth();
    const [isAuthUserReady, setIsAuthUserReady] = useState(false);

    useEffect(() => {
        if (authUser) {
            setIsAuthUserReady(true);
        }
    }, [authUser]);

    useEffect(() => {
        const handleCallback = async () => {
            if (isAuthUserReady) {
                const urlParams = new URLSearchParams(window.location.search);
                const code = urlParams.get('code');

                if (code && authUser?.documentId && !isProcessing) {
                    setIsProcessing(true);
                    try {
                        const response = await handleTwitterCallback(code, authUser.documentId);

                        const [data, status] = response;
                        if (!data || status !== 200) {
                            throw new Error('Failed to fetch valid Twitter data');
                        }

                        const websiteUsername = authUser?.userName;
                        if (websiteUsername) {
                            localStorage.setItem('twitterConnected', 'true');
                            window.location.href = `/${websiteUsername}/update`;
                        } else {
                            console.error('Website username not found.');
                        }
                    } catch (error) {
                        console.error('Error during Twitter callback handling:', error);
                    }
                }
            }
        };

        handleCallback();
    }, [isAuthUserReady, isProcessing, authUser]);

    return <div>Processing Twitter Login...</div>;
};

export default TwitterCallback;
