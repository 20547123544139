import * as React from 'react';
import { SVGProps, memo } from 'react';
const AttendWithChatIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1_24_5791" fill="white">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8987 10.9295C16.7788 10.75 16.7891 10.515 16.9127 10.3381C17.6061 9.34587 18 8.20871 18 7C18 3.13401 13.9706 0 9 0C4.02944 0 0 3.13401 0 7C0 10.866 4.02944 14 9 14C10.1697 14 11.2874 13.8264 12.3126 13.5106C12.4136 13.4795 12.5217 13.4792 12.6222 13.5119L18.9213 15.5613C19.3726 15.7081 19.7554 15.2024 19.4917 14.8079L16.8987 10.9295Z"/>
        </mask>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8987 10.9295C16.7788 10.75 16.7891 10.515 16.9127 10.3381C17.6061 9.34587 18 8.20871 18 7C18 3.13401 13.9706 0 9 0C4.02944 0 0 3.13401 0 7C0 10.866 4.02944 14 9 14C10.1697 14 11.2874 13.8264 12.3126 13.5106C12.4136 13.4795 12.5217 13.4792 12.6222 13.5119L18.9213 15.5613C19.3726 15.7081 19.7554 15.2024 19.4917 14.8079L16.8987 10.9295Z" fill={props.color || '#6C7072'}/>
        <path d="M12.3126 13.5106L12.607 14.4663L12.3126 13.5106ZM12.6222 13.5119L12.9316 12.5609L12.6222 13.5119ZM18.9213 15.5613L18.6119 16.5122L18.9213 15.5613ZM19.4917 14.8079L18.6603 15.3637L19.4917 14.8079ZM16.9127 10.3381L17.7324 10.9109L16.9127 10.3381ZM16.8987 10.9295L17.7301 10.3737L16.8987 10.9295ZM17 7C17 7.98381 16.6811 8.92383 16.0931 9.76524L17.7324 10.9109C18.5312 9.7679 19 8.43361 19 7H17ZM9 1C13.6667 1 17 3.90561 17 7H19C19 2.3624 14.2744 -1 9 -1V1ZM1 7C1 3.90561 4.33328 1 9 1V-1C3.7256 -1 -1 2.3624 -1 7H1ZM9 13C4.33328 13 1 10.0944 1 7H-1C-1 11.6376 3.7256 15 9 15V13ZM12.0182 12.5549C11.0895 12.841 10.0711 13 9 13V15C10.2684 15 11.4853 14.8118 12.607 14.4663L12.0182 12.5549ZM19.2307 14.6103L12.9316 12.5609L12.3128 14.4628L18.6119 16.5122L19.2307 14.6103ZM16.0674 11.4852L18.6603 15.3637L20.323 14.2521L17.7301 10.3737L16.0674 11.4852ZM12.607 14.4663C12.5187 14.4935 12.4158 14.4963 12.3128 14.4628L12.9316 12.5609C12.6277 12.462 12.3085 12.4655 12.0182 12.5549L12.607 14.4663ZM18.6119 16.5122C19.9658 16.9527 21.1143 15.4358 20.323 14.2521L18.6603 15.3637C18.3966 14.9691 18.7794 14.4635 19.2307 14.6103L18.6119 16.5122ZM16.0931 9.76524C15.7512 10.2544 15.7015 10.9379 16.0674 11.4852L17.7301 10.3737C17.8561 10.5622 17.827 10.7755 17.7324 10.9109L16.0931 9.76524Z" fill="#F3F3F3" mask="url(#path-1-inside-1_24_5791)"/>
    </svg>
);

export default memo(AttendWithChatIcon);
