import React from "react";
import { getTextToSpeech } from "../../utils/api";
import AudioChunk from "./audioChunk";
import { TTSRequest } from "./messages.types";
import { removeEmojis, processAcronyms } from "../../utils/helper";

class AudioChunkQueue {
  queue: Array<any>;
  isPlaying: boolean;
  completed: boolean;
  awaitingResp: React.MutableRefObject<boolean>;
  volumeControl: React.MutableRefObject<any>;
  audioAnalyzer: React.MutableRefObject<any>;
  setDisableMicrophone: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPlayingAudio: (val: boolean) => void;
  setErrorCode: React.Dispatch<React.SetStateAction<number>>;
  setShowTimeoutModal: React.Dispatch<React.SetStateAction<boolean>>;
  volume: number;
  gainNode: GainNode;

  constructor(stringChunks: Array<string>,
      voice_id: any,
      awaitingResp: React.MutableRefObject<boolean>,
      volumeControl: React.MutableRefObject<any>,
      audioAnalyzer: React.MutableRefObject<any>,
      setDisableMicrophone: React.Dispatch<React.SetStateAction<boolean>>,
      setIsPlayingAudio: (val: boolean) => void,
      volume: number,
      setErrorCode: React.Dispatch<React.SetStateAction<number>>,
      setShowTimeoutModal: React.Dispatch<React.SetStateAction<boolean>>,
      ){
    this.playQueue = this.playQueue.bind(this);
    this.handleStopPlayingChunk = this.handleStopPlayingChunk.bind(this);
    this.handleQueueFinish = this.handleQueueFinish.bind(this);
    this.playChunk = this.playChunk.bind(this);
    this.stopChunk = this.stopChunk.bind(this);
    this.setVolume = this.setVolume.bind(this);
    this.forceStop = this.forceStop.bind(this);
    this.handleErrors = this.handleErrors.bind(this);
    this.queue = [];
    this.isPlaying = false;
    this.completed = false;
    this.awaitingResp = awaitingResp;
    this.volumeControl = volumeControl;
    this.audioAnalyzer = audioAnalyzer;
    this.setDisableMicrophone = setDisableMicrophone;
    this.setIsPlayingAudio = setIsPlayingAudio;
    this.setErrorCode = setErrorCode;
    this.setShowTimeoutModal = setShowTimeoutModal;
    this.volume = volume;

    for (let i = 0; i < stringChunks.length; i++){
      let str = processAcronyms(removeEmojis(stringChunks[i]));
      if (str.length > 0){
        let ttsData: TTSRequest = {
          voice_id: voice_id,
          text: str,
        };

        this.queue.push(
          getTextToSpeech(ttsData)
            .then((resp: any) => {
              let [source, analyzer, gainNode] = resp;
              return new AudioChunk(source, analyzer, gainNode);
            })
            .catch(this.handleErrors)
        );
      }
    }

    if (!this.queue.length){
      this.handleQueueFinish();
    } else {
      setTimeout(this.playQueue, 100);
    }
  }

  async playQueue(){
    if (!this.isPlaying && this.queue.length){
      this.queue[0].then(this.playChunk)
    }

    if (!this.queue.length){
      this.handleQueueFinish();
    }
  }

  playChunk(chunk: AudioChunk){
    if (!this.completed && chunk && chunk.isValid()){
      this.awaitingResp.current = false;
      this.isPlaying = true;
      chunk.gainNode.gain.value = this.volume;
      this.gainNode = chunk.gainNode;
      this.audioAnalyzer.current = chunk.analyzer;
      this.volumeControl.current = chunk.gainNode;
      chunk.source.addEventListener("ended", this.handleStopPlayingChunk);
      chunk.play();
      this.setIsPlayingAudio(true);
    } else {
      // just remove from queue if invalid chunk
      this.handleStopPlayingChunk();
    }
  }

  setVolume(volume: number){
    this.volume = volume;
    if (this.gainNode){
      this.gainNode.gain.value = volume;
    }
  }

  handleStopPlayingChunk(){
    if (this.queue.length > 0 && this.queue[0]?.source){
      this.queue[0].source.removeEventListener("ended", this.handleStopPlayingChunk);
    }
    this.queue.shift();
    this.isPlaying = false;
    this.playQueue();
  };

  handleQueueFinish(){
    if (!this.completed){
      this.completed = true;
      this.awaitingResp.current = false;
      this.setIsPlayingAudio(false);
      this.setDisableMicrophone(false);
    }
  }

  handleErrors(err: any){
    if (!this.completed){
      this.handleQueueFinish(); // not good if one chunk fails but the rest dont?
      console.log('Failed to fetch TTS')
      console.log(err);
      this.setErrorCode(0); // generic error
      this.setShowTimeoutModal(true); // show error modal
    }
  }

  // Unused code, but could be handy in the future:
  forceStop(){
    if (this.queue.length){
      this.queue = [this.queue[0]];
      this.queue[0].then(this.stopChunk)
    }
  }

  stopChunk(chunk: AudioChunk){
    if (chunk && chunk.isValid()){
      chunk.stop();
    }
  }
}

export default AudioChunkQueue;
