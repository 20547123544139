import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

const ChatWidgetNavbar = () => {
    const [credits, setCredits] = useState<number>(0);
    const [maxCredits, setMaxCredits] = useState<number>(0);
    const { authUser } = useAuth();

    useEffect( () => {
      if (authUser && authUser.credits){
        setCredits(authUser.credits.reduce((acc, curr) => acc + curr["remaining"], 0))
        setMaxCredits(authUser.credits.reduce((acc, curr) => acc + curr["maxAmount"], 0))
      }
    }, [authUser])

    return (
      <>
        <Outlet context={{credits, setCredits, maxCredits, setMaxCredits}}/>
      </>
    )
  }

export default ChatWidgetNavbar;
