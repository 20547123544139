import { SVGProps, memo } from 'react';
const StarsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={21}
    height={20}
    viewBox='0 0 21 20'
    fill='none'
    {...props}
  >
    <path
      fill='#fff'
      d='m13.42 0 1.864 5.036L20.32 6.9l-5.036 1.864L13.42 13.8l-1.864-5.036L6.52 6.9l5.036-1.864L13.42 0ZM4.14 11.04l1.118 3.022L8.28 15.18l-3.022 1.118L4.14 19.32l-1.118-3.022L0 15.18l3.022-1.118L4.14 11.04Z'
    />
  </svg>
);
export default memo(StarsIcon);
