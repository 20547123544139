import * as React from 'react';
import Box from '@mui/material/Box';

import { UserFormType } from '../creatorOnBoarding/signup.types';
import { USER_TYPE } from '../../config/const';
import CreateMimioContainer from './createMimioContainer';
import styles from './fanCreatorList.module.css';

interface AuthUserProfileProps {
  authUserData: UserFormType;
}

//create mimio feature to show/hide create my mimio section
const createMIMIOFeature =
  process.env.REACT_APP_ENABLE_CREATE_MIMIO_FEATURE === "true";

function AuthUserProfile(props: AuthUserProfileProps) {
  const { userType } =
    props?.authUserData;
  return (
    <Box>
      {createMIMIOFeature && userType === USER_TYPE.FOLLOWER && (
        <Box className={styles.createContainerDesk}>
          <CreateMimioContainer />
        </Box>
      )}
    </Box>
  );
}

export default React.memo(AuthUserProfile);
