import React, { createContext, useState, memo } from 'react';
import { firestore } from '../utils/firebase';
import { doc, getDoc } from 'firebase/firestore';

// Initialize Firebase Authentication and get a reference to the service
interface TrainSectionCtxType {
  currentLevel: Record<any, any>;
  setCurrentLevel: (value: Record<any, any>) => void;
  fetchAndSetCurrentLevelData: (userData: any) => void;
}

export const TrainSectionContext = createContext<TrainSectionCtxType>({
  currentLevel: null,
  setCurrentLevel: () => {
    return {} as any;
  },
  fetchAndSetCurrentLevelData: () => {
    return {} as any;
  },
});

export const TrainSectionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [currentLevel, setCurrentLevel] = useState<Record<any, any>>();

  const fetchAndSetCurrentLevelData = async (levelId: string) => {
    if (levelId) {
      const docRef = doc(firestore, 'train_levels', levelId);
      await getDoc(docRef);
    }
  };

  return (
    <TrainSectionContext.Provider
      value={{
        currentLevel,
        setCurrentLevel,
        fetchAndSetCurrentLevelData,
      }}
    >
      {children}
    </TrainSectionContext.Provider>
  );
};

export default memo(TrainSectionProvider);
