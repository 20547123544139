import { ImageList, ImageListItem, Modal, Box, IconButton, useMediaQuery, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

interface CustomImageListProps {
  image_url?: string;
  documentId?: string;
  images?: string[];
  image_urls?: string[];
  scrollToBottom?: () => void;
}

interface CustomImageItemProps {
  ind: number;
}

const CustomImageList: React.FC<CustomImageListProps> = (props) => {
  const { image_url, documentId, images, image_urls, scrollToBottom } = props;
  const [ allImages, setAllImages ] = useState<string[]>([]);
  const [ isEncodedImage, setIsEncodedImage ] = useState<boolean>(false);
  const [ modalVisible, setModalVisible ] = useState<boolean>(false);
  const [ activeStep, setActiveStep ] = useState<number>(0);
  const isMobile = !useMediaQuery('(min-width:600px)');

  useEffect(() => {
    if (image_urls && image_urls.length > 0){
      setAllImages(image_urls);
    } else if ((images && images.length > 0)) {
      setAllImages(images);
      setIsEncodedImage(true);
    } else if ((image_url && image_url.length > 0)) {
      setAllImages([image_url]);
    }
  }, [image_url, images, image_urls])

  const CustomImageItem: React.FC<CustomImageItemProps> = ({ind}) => {
    return (
      <ImageListItem
        onClick={() => {handleOpen(ind)}}
        sx={{
          cursor: 'pointer',
          maxHeight: '250px',
        }}
      >
        <img
          src={isEncodedImage ? `data:image/jpeg;base64,${allImages[ind]}` : `${allImages[ind]}`}
          alt={documentId + "-image-" + ind}
          loading="lazy"
          style={{objectFit: 'contain', height: '100%', width: '100%', maxHeight: '250px'}}
          onLoad={() => {
            if (scrollToBottom && !modalVisible) { scrollToBottom() }
          }}
        />
      </ImageListItem>
    )
  }

  const ImageModal = () => {
    return (
      <Modal
        open={modalVisible}
        onClose={handleClose}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0 , 0, 0.8)',
          transition: 'none',
          " div":{
            outline: 'none !important'
          }
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          sx={{
            width: {xs: '100%', md:'80%'},
            height: {xs: '100%', md: '90%'},
          }}
          onKeyDown={(e) => {
            if (e?.key === 'ArrowRight') {
              handleChangeImage(activeStep+1)
            } else if (e?.key === 'ArrowLeft') {
              handleChangeImage(activeStep-1)
            }
          }}
        >
          <IconButton
            id='button-close-stripe-checkout'
            onClick={handleClose}
            color='primary'
            sx={{
              position: 'absolute',
              alignSelf: 'flex-end',
              minWidth: '36px',
              minHeight: '36px',
              height: '36px',
              width: '36px',
              top: isMobile ? '16px' : '5%',
              right: isMobile ? '16px' : '10%',
              color: 'white',
              backgroundColor: '#505050',
              ":hover": {backgroundColor: '#303030'},
            }}
          >
            <CloseIcon height='30px' width='30px' />
          </IconButton>
          <img
            src={isEncodedImage ? `data:image/jpeg;base64,${allImages[activeStep]}` : `${allImages[activeStep]}`}
            alt={documentId + "-image-" + activeStep}
            loading="lazy"
            style={{objectFit: 'contain', width: '100%', height: '75%', padding: '8px'}}
          />
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems='center'
            sx={{height: '25%', width: '100%'}}>
            <IconButton
              onClick={() => { handleChangeImage(activeStep-1) }}
              sx={{
                backgroundColor: '#505050',
                ":hover": {backgroundColor: '#303030'},
                p: '2px',
              }}
            >
              <KeyboardArrowLeft
                sx={{
                  width: '40px',
                  height: '40px',
                  color: 'white'
                }}
              />
            </IconButton>
            <Grid
              container
              display={'flex'}
              justifyContent={'center'}
              alignItems='center'
              sx={{
                height: '100%',
                width: '80%',
                maxWidth: 'calc(100% - 100px)',
              }}
            >
              {
                allImages.map((img, idx) => {
                  return (
                    <Grid
                      item
                      key={`carousel-img-${idx}`}
                      xs={allImages.length ? 12/allImages.length : 12}
                      sm={'auto'}
                      sx={{
                        height: '100%',
                        maxHeight: '100%',
                        maxWidth: `${100/allImages.length}% !important`,
                        p: {xs: 0, sm: 1},
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        src={isEncodedImage ? `data:image/jpeg;base64,${img}` : `${img}`}
                        alt={documentId + "-image-" + idx}
                        loading="lazy"
                        style={{
                          objectFit: 'contain',
                          height: 'auto',
                          width: 'auto',
                          maxWidth: '100%',
                          maxHeight: '100%',
                          border: idx === activeStep ? "3px #3EACEF solid" : "3px transparent solid",
                          borderRadius: '6px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {handleChangeImage(idx)}}
                      />
                    </Grid>
                  )
                })
              }
            </Grid>
            <IconButton
              onClick={() => { handleChangeImage(activeStep + 1) }}
              sx={{
                backgroundColor: '#505050',
                ":hover": {backgroundColor: '#303030'},
                p: '2px',
              }}
            >
            <KeyboardArrowRight
              sx={{
                width: '40px',
                height: '40px',
                color: 'white'
              }}
            />
            </IconButton>
          </Box>
        </Box>
      </Modal>
    )
  }

  const handleOpen = (ind: number) => {
    setActiveStep(ind)
    setModalVisible(true);
  }

  const handleClose = () => {
    setModalVisible(false);
  }

  const handleChangeImage = (img_idx: number) => {
    if (img_idx < 0) {
      if (activeStep !== 0) setActiveStep(0)
    } else if (img_idx > allImages.length-1) {
      if (activeStep !== allImages.length-1) setActiveStep(allImages.length-1)
    } else {
      setActiveStep(img_idx)
    }
  };

  return (
    (allImages.length) ?
      <>
        <ImageList sx={{ width: '100%'}} variant="masonry" cols={isMobile ? 2 : allImages.length < 5 ? allImages.length : 5} gap={8}>
          {
            allImages.map((_, ind) => (
              <CustomImageItem key={`image-${documentId}-${ind}`} ind={ind}/>
            ))
          }
        </ImageList>
        <ImageModal />
      </>:
      <></>
    )
}

export default CustomImageList;
