import creatorActions from '../../assets/images/creator-actions-mobile.png';

const questions = {
  creators: [
    {
      id: 1,
      title: 'What does Soopra.ai do?',
      info: 'Soopra.ai is building an AI-powered Personality Engine™, on which users can create a personalized, authentic, and verified digital persona, enabling new ways people can engage with technology and shape the future.',
    },
    {
      id: 2,
      title: 'What is an AI persona?',
      info: 'An AI persona is a verified AI-powered representation of an individual which will embody their communication style, voice, image, and narrative.',
    },
    {
      id: 3,
      title: 'How can I train my AI persona?',
      info: 'You can train your AI persona by interacting with your AI persona regularly, which allows it to learn and adapt to your voice, tone, and style.',
    },
    {
      id: 4,
      title: 'How long does it take to train my AI persona?',
      info: 'The training time can vary depending on the complexity of your persona and the amount of interaction you have with it. The more you interact, the more your AI persona can learn and improve.',
    },
    {
      id: 5,
      title: 'How can I ensure my AI persona accurately represents me?',
      info: 'Regular interaction with your persona is key. The more you engage with your AI persona, the better it can understand and mimic your communication style. The more you train it with the content you generated, the more the AI persona will use learnings from you to represent you more accurately.',
    },
    {
      id: 6,
      title: 'Who is a creator?',
      info: "A creator is a user who used Soopra to build their AI persona. Users can interact with a creator's AI persona.",
    },
    {
      id: 7,
      title: 'How do I create a digital persona?',
      info: [
        { text: 'Click here to create an AI persona.', link: 'https://app.soopra.ai/' },
        ' Once you sign in, you will be directed to create a profile. Follow the directions and interact with your new digital persona!',
      ],
    },
    {
      id: 8,
      title: 'What does sharing my social links do?',
      info: 'Sharing your social links allows users to find you on all your channels. In the near future, we will integrate your social media content to help train your AI persona.',
    },
    {
      id: 9,
      title: 'What document formats are supported?',
      info: 'The document formats supported are .txt, .docx, .pdf, .pptx. You can upload as many documents as you like, but each document cannot exceed 100MB.',
    },
    {
      id: 10,
      title: 'Will data from my social media links and websites be used to build my AI persona?',
      info: 'We are working to integrate data from your social media links and websites to build your AI persona. In the meantime, if you include these links upon signing up, your AI persona will share them with your fans.',
    },
    {
      id: 11,
      title: 'How long does it take for Soopra.ai to generate my AI persona?',
      info: 'Depending on the data uploaded, it may take up to a few minutes to generate your AI persona.',
    },
    {
      id: 12,
      title: 'How do I chat with my AI persona?',
      info: 'To chat with your AI persona, go to your profile and click on "Chat" to chat with your digital persona. If you have uploaded a voice sample, you can also "Talk" with your digital persona.',
    },
    {
      id: 13,
      title: 'How do I update my information?',
      info: 'On your profile screen, click the "Update" button. You will see information fields available to update your information, upload documents, add links and websites, and add a voice sample.',
    },
    {
      id: 14,
      title: 'What can I do while I chat with my AI persona?',
      info: 'Click on the Chat button from your home screen to chat with your AI persona. As you chat, you can rate each response with a thumbs up or a thumbs down, leave free-form text feedback, and edit the response with accurate or new information.',
      image: creatorActions,
    },
    {
      id: 15,
      title: 'How do you qualify to be a Top Fan?',
      info: 'Top fans are the 3 users who chat or talk with a creator for the most consecutive days. Chat or talk with a creator every day for your chance to be a Top Fan.',
    },
    {
      id: 16,
      title: 'What is a streak?',
      info: 'A streak is a count of how many consecutive days you have chatted with the same creator’s AI persona. Streaks are represented by a flame (🔥) next to the username on the creator’s profile page. The number next to the flame indicates how many days the streak has been maintained. The streak will continue as long as you continue to chat or talk with the same digital persona every 24 hours. If you miss a day, the streak will end.',
    },
    {
      id: 17,
      title: 'How can I contact Soopra.ai?',
      info: [
        'Please contact the Soopra.ai team at ',
        { text: 'support@soopra.ai.', link: 'mailto:support@soopra.ai'},
      ],
    },
    {
      id: 18,
      title: 'Having Trouble With Voice in Safari?',
      info:
        [
          'If you’re experiencing issues with voice recognition or audio while using Safari, it might be due to the browser’s auto-play settings. Here’s how to fix it: 1. Open Safari and go to "Preferences". 2. Click on the "Websites" tab and navigate to the "Auto-Play" section. 3. Find app.soopra.ai in the list of websites, and change the setting to "Allow All Auto-Play". 4. Reload the Soopra.ai webpage for the changes to take effect. If the problem persists, please contact ',
          { text: 'support@soopra.ai', link: 'mailto:support@soopra.ai'},
          ' for further assistance.'
        ],

    }
  ],
  parents: [
    {
      id: 1,
      title: 'What is Soopra Learning(TM)?',
      info: 'Soopra Learning(TM) is a unique educational program that uses AI to provide a personalized learning experience. Your child can learn from experts in a conversational, byte-sized format, at their own pace.',
    },
    {
      id: 2,
      title: 'How can I monitor my student’s progress?',
      info: "Upon completion of each module, your child progresses to the next. You'll receive updates on their progress and, at the end of the course, a certificate of completion from the actual teacher.",
    },
    {
      id: 3,
      title: 'How can I cancel my enrollment?',
      info: "You can cancel your enrollment by going to your profile and click on enrolled courses, select courses to cancel by clicking cancel enrollment. You can also send email to courses@soopra.ai. Cancellations are only accepted within 48 hours of registration. Once a user starts the course there is no cancellation.",
    },
    {
      id: 4,
      title: 'How can my kid take the course once I enroll in the course?',
      info: "You should provide an email of the participating student if you are registering on behalf of your kid. If you forgot to list the email during the registration please send email to courses@soopra.ai.",
    },
  ],
  students: [
    {
      id: 1,
      title: 'How does Soopra Learning(TM) work?',
      info: "Once you enroll in a course, you'll engage in a 1:1 chat experience with the AI persona of the expert. You can learn at your own pace, with byte-sized sessions of 10-20 minutes a day.",
    },
    {
      id: 2,
      title: 'What happens when I complete a course?',
      info: "You'll receive a certificate of completion from the actual teacher, who has reviewed and verified your learning through the chat transcripts.",
    },
    {
      id: 3,
      title: 'I want to ask a question to the teacher, can I send a message to the teacher/instructor?',
      info: "Yes, you can send a message to the instructor/teacher to the provided email address by the instructor.",
    },
  ],
  subscriptions: [
    {
      id: 1,
      title: 'What is Soopra Subscriptions?',
      info: 'Soopra Subscriptions is a new feature which allows users to subscribe for just $1.99/month to chat with AI personas of experts and educators on the platform. This subscription includes 25 questions per month.',
    },
    {
      id: 2,
      title: 'Can I ask more than 25 questions in a month?',
      info: 'Yes, you can purchase as many additional question bundles "Token Bundle", as you please, for $0.99, which includes 10 more questions.',
    },
    {
      id: 3,
      title: 'How can I subscribe to Soopra Subscriptions?',
      info: 'You can subscribe by clicking the Subscribe button in an expert’s Soopra chat window after you run out of free tokens or via the Purchase page.',
    },
    {
      id: 4,
      title: 'What benefits do I get from Soopra Subscriptions?',
      info: 'With Soopra Subscriptions, you get 24/7 access to chat with AI personas of experts and educators. This allows you to learn at your own pace, in your own style, anytime, anywhere.',
    },
    {
      id: 5,
      title: 'Where can I see the details of my purchases?',
      info: 'You can view the details of your purchases on the Purchase page, which is accessible within the hamburger menu on the top right of the Soopra app.',
    },
    {
      id: 6,
      title: 'Who can I contact if I have questions or need assistance?',
      info: [
        'You can contact us at ',
        {text: 'support@soopra.ai', link: 'mailto:support@soopra.ai'},
        ' for any questions or assistance.'
      ],
    },
  ],
  product: [
    {
      id: 1,
      title: 'What is the new product that Soopra is releasing?',
      info: 'Soopra is releasing an innovative app for online rapid learning courses where students of all grades can interact with AI personas of real teachers. The web app is designed to facilitate learning of various subjects and topics via conversational AI.',
    },
    {
      id: 2,
      title: 'What makes this product unique?',
      info: 'The unique aspect of this product is the use of AI personas of real teachers, which allows students to learn at their own pace. It also provides access to world-class teachers from universities such as Stanford.',
    },
    {
      id: 3,
      title: 'How much does it cost to use the web app?',
      info: 'The app is incredibly affordable, costing $60 or $40 per course. This makes world-class education at affordable prices.',
    },
    {
      id: 4,
      title: 'How can I get more information about the app?',
      info: 'For more information about the app, you can visit our website at https://www.soopra.ai/courses or reach out to us at courses@soopra.ai. We\'re always here to help.',
    },
  ],
};

export default questions;
