import { Box, Button, Typography } from '@mui/material'
import * as React from 'react'
import {useGoogleLogin} from '@react-oauth/google'
import GoogleMeethIcon from '../../svg/GoogleMeethIcon';
import WidgetLoading from '../../assets/animations/ProcessingPayment.json'
import Lottie from 'lottie-react';
import MeethMeetings from './meethMeetings';
import '../../assets/dashboard.css'

interface MeethContainerProps { 
    user_id: String,
    meethFullScreen: boolean, 
    setMeethFullScreen: React.Dispatch<React.SetStateAction<boolean>>,
}

const MeethContainer = (props: MeethContainerProps) => {
    const [accessToken, setAccessToken] = React.useState(null)
    const [calendarConnected, setCalendarConnected] = React.useState(false)
    const [installingExension, setInstallingExtension] = React.useState(false)
    const [loadingAnimation, setLoadingAnimation] = React.useState(WidgetLoading)
    const [installedExtension, setInstalledExtension] = React.useState(false)


    const {user_id,meethFullScreen, setMeethFullScreen} = props;

    React.useEffect (() => {
        const loginStr = localStorage.getItem('GoogleLoginToken')
        if (loginStr) {
            const payload = JSON.parse(loginStr)
            if (new Date().getTime() > payload.expiry) {
                localStorage.removeItem('GoogleLoginToken');
                return null;
            }
            setAccessToken(payload.value)
            setCalendarConnected(true)
        }
    },[])
    
    const handleConnectCalendar = useGoogleLogin({
        onSuccess: (tokenResponse) => {
            console.log(tokenResponse)
            setAccessToken(tokenResponse.access_token)
            setCalendarConnected(true)
            const payload = {
                value: tokenResponse.access_token,
                expiry: new Date().getTime() + 7*24*60*60
            }
            localStorage.setItem('GoogleLoginToken', JSON.stringify(payload));
        },
        onError:   () => {
            window.alert('Login Error')
        },
        scope: 'https://www.googleapis.com/auth/calendar.readonly',
    })

    const handleInstallExtension = () => {
        setInstallingExtension(true)

        // Replace with function to install extension
        setTimeout(() => {
            setInstallingExtension(false)
            setInstalledExtension(true)
        }, 5000);


    }

    const handleMeethFullScreen = (value) => {
        console.log(`called: ${value}`)
        setMeethFullScreen(value)
    }

  return (
    
    <>
        {
        calendarConnected ? 
        <Box width='100%'>
            <MeethMeetings accessToken={accessToken} user_id={user_id} handleMeethFullScreen={handleMeethFullScreen} meethFullScreen={meethFullScreen}/>
        </Box>
        :
        <Box display='flex' flexDirection='column' padding='10px' gap='10px'>
            <Typography fontWeight={600} color='#000000'>
                Set Up MEETH - Meetings
            </Typography>
            <Box display='flex' flexDirection='column' gap='10px' width='100%'>
                <Typography fontWeight={600} color='#000000' sx={{opacity: calendarConnected? 0.2 : 1}}>
                    Step 1
                </Typography>
                <Box padding='20px' sx={{
                    background: '#F6F6F6'
                }}>
                    <Box display='flex' flexDirection='row' gap='10px'>
                        <Box width='30px' sx={{opacity: calendarConnected? 0.2 : 1}}>
                            <GoogleMeethIcon height='30px' width='30px' />
                        </Box>
                        <Box display='flex' flexDirection='column'>
                            <Typography fontWeight={600} color='#000000' sx={{opacity: calendarConnected? 0.2 : 1}}>
                                Connect to Google Calendar
                            </Typography>
                            <Typography fontWeight={400} color='#000000' sx={{opacity: calendarConnected? 0.2 : 1}}>
                                *Required to use MEETH
                            </Typography>
                            <Button sx={{
                                background: calendarConnected ? '#ADADAD' : '#00A3FF',
                                color: '#ffffff',
                                marginTop: '10px',
                                width: '50%',
                                height:'40px !important',
                                "&:hover": {
                                    background: calendarConnected ? '#ADADAD' : '#34bbff'}
                            }}

                            onClick={calendarConnected ? () => {} : () => handleConnectCalendar()}
                            >
                                <Box display='flex' flexDirection='row' gap='5px'> 
                                    {calendarConnected ? 'Connected' : "Connect"} 
                                </Box>
                            </Button> 
                        </Box>
                        
                    </Box>
                </Box>
            </Box>
            {/* <Box display='flex' flexDirection='column' gap='10px'>
                <Typography fontWeight={600} color='#000000' sx={{
                    opacity: calendarConnected && !installingExension ? 1: 0.2
                }}>
                    Step 2
                </Typography>
                <Box padding='20px' sx={{
                    background: '#F6F6F6'
                }}>
                    <Box display='flex' flexDirection='row'  gap='10px'>
                        <Box width='30px' sx={{opacity: calendarConnected && !installingExension ? 1: 0.2}}>
                            <GoogleMeethIcon height='30px' width='30px' />
                        </Box>
                        <Box display='flex' flexDirection='column'>
                            <Typography fontWeight={600} sx={{
                                color:'#000000',
                                opacity: calendarConnected && !installingExension ? 1: 0.2
                            }}>
                                Install Chrome Extension
                            </Typography>
                            <Typography fontWeight={400} sx={{
                                color:'#000000',
                                opacity: calendarConnected && !installingExension? 1: 0.2
                            }}>
                                Companion app for those who use Google Meet
                            </Typography>
                            <Button sx={{
                                opacity: installingExension? 0.2: 1,
                                background: calendarConnected && !installedExtension ? '#00A3FF' : '#DBDBDB',
                                color: calendarConnected && !installedExtension ? '#FFFFFF': '#B7B7B7',
                                marginTop: '10px',
                                width: '50%',
                                height:'40px !important'
                            }}
                            onClick={handleInstallExtension}
                            >
                                <Box display='flex' flexDirection='row' gap='5px' alignItems='center'> 
                                    {installedExtension ? 'Installed' : installingExension ? 'Installing' : 'Install'}
                                    <svg width="18" height="18" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="9.5" cy="9.5" r="9" stroke="currentColor"/>
                                        <path d="M9.49992 5.7002V13.3002M9.49992 13.3002L12.6666 10.1335M9.49992 13.3002L6.33325 10.1335" stroke="currentColor"/>
                                    </svg>
                                </Box>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box >
            {installingExension ? 
                <Box display='flex' flexDirection='column' width='100%'>
                    <Lottie
                    animationData={loadingAnimation}
                    loop={true}
                    />
                </Box>
                :
                <></>
            } */}
        </Box>
    }
    </> 
  )
}


export default MeethContainer;