import * as React from 'react';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { USER_TYPE } from '../../config/const';
import CreatorProfile from './creatorProfile';
import FanProfile from './fanProfile';

export default function UserProfile() {
  const { authUser } = useAuth();
  const { userName: userNameParam } = useParams();
  const userName = userNameParam?.toLowerCase();

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }}
    >
      {authUser?.userType === USER_TYPE.FOLLOWER &&
      authUser?.normalizedUserName === userName ? (
        <FanProfile />
      ) : (
        <CreatorProfile userName={userName} />
      )}
    </Box>
  );
}
