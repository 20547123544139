import * as React from 'react';
import { SVGProps, memo } from 'react';
const FillBulbIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 21 21'
    {...props}
  >
    <path
      fill='#fff'
      stroke='#fff'
      d='M9.73 16.646h1.958a1 1 0 0 0 1-1v-.98c.396-1.187 2.375-1.583 2.375-4.75 0-3.166-2.375-4.354-4.354-4.354-1.98 0-4.75 1.188-4.75 4.355 0 3.166 2.375 3.562 2.77 4.75v.979a1 1 0 0 0 1 1Z'
    />
    <path
      fill='#fff'
      fillRule='evenodd'
      d='M11 0v3h-1V0h1Z'
      clipRule='evenodd'
    />
    <path
      stroke='#fff'
      d='M3 11H0M4.74 5.2 2.686 3.004M18 11h3M16.716 5.2l2.054-2.196'
    />
    <rect
      width={3.75}
      height={2.167}
      x={8.834}
      y={18.333}
      stroke='#fff'
      rx={0.5}
    />
  </svg>
);

export default memo(FillBulbIcon);
