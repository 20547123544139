import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ReplyIcon from '@mui/icons-material/Reply';

import Slide from '@mui/material/Slide';
import { UserFormType } from '../creatorOnBoarding/signup.types';
import { MessageDataType } from './messages.types';
import { USER_TYPE } from '../../config/const';
import { toast } from 'react-toastify';

interface ShareMessageSectionProps {
  containerRef: React.MutableRefObject<any>;
  selectedMessages: MessageDataType[];
  resetMessageSelection: () => void;
  creatorProfileData: UserFormType;
}

const ShareMessageSection: React.FC<ShareMessageSectionProps> = (props) => {
  const {
    containerRef,
    resetMessageSelection,
    selectedMessages,
    creatorProfileData,
  } = props;

  const handleShareMessages = async () => {
    if (selectedMessages?.length >= 1) {
      const shareMessagesData = selectedMessages;
      let shareMessageContent = `Hey! Just had a chat with ${creatorProfileData?.fullName}'s AI persona on Soopra.ai. Check this out:\n\n`;

      shareMessagesData
        .sort((a, b) => (a?.sentTime?.seconds < b.sentTime?.seconds ? -1 : 1))
        .forEach((messageItem) => {
          let messageContent = messageItem?.message;
          const messageLink = messageContent?.match(/<a[^>]*>([^<]+)<\/a>/);
          if (messageLink && messageLink?.[0] && messageLink?.[1]) {
            messageContent = messageContent?.replace(
              messageLink?.[0],
              messageLink?.[1]
            );
          }
          if (messageItem?.userType === USER_TYPE?.BOT) {
            shareMessageContent += `${creatorProfileData?.fullName}'s AI persona: ${messageContent}\n`;
          } else {
            shareMessageContent += `\nMe: ${messageContent}\n`;
          }
        });
      shareMessageContent += `\n\nPretty cool, right? Try it for yourself here: ${window.location.origin}/${creatorProfileData?.userName}/chat`;

      const shareData = {
        title: 'Soopra.ai',
        text: shareMessageContent,
      };

      try {
        if (!navigator?.canShare) {
          navigator?.clipboard?.writeText?.(shareMessageContent);
          toast.success('Chat data copied to clipboard');
        } else if (navigator.canShare(shareData)) {
          await navigator?.share(shareData);
          toast.success('Chat shared successfully');
        } else {
          navigator?.clipboard?.writeText?.(shareMessageContent);
          toast.success('Chat data copied to clipboard');
        }
        resetMessageSelection();
      } catch (err) {
        toast.error('Chat data cannot be shared. Please try again later');
      } finally {
        resetMessageSelection();
      }
    }
  };

  return (
    <Box
      position='absolute'
      bottom={0}
      left={0}
      right={0}
      zIndex={10}
      bgcolor='white'
    >
      <Slide direction='up' in={true} container={containerRef.current}>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          height={'56px'}
          px={2}
          style={{
            border: '1px solid #3DA7E7',
            borderBottomWidth: 0,
            borderTopLeftRadius: '14px',
            borderTopRightRadius: '14px',
          }}
        >
          <Button
            id='button-cancel-share'
            size='small'
            onClick={resetMessageSelection}
            sx={{
              color: '#3DA7E7',
            }}
          >
            Cancel
          </Button>
          <IconButton
            id='button-share-messages'
            onClick={handleShareMessages}
            disabled={selectedMessages?.length <= 0}
            sx={{
              color: '#3DA7E7',
            }}
          >
            <ReplyIcon style={{ transform: 'scaleX(-1)' }} />
          </IconButton>
        </Box>
      </Slide>
    </Box>
  );
};

export default React.memo(ShareMessageSection);
