import * as React from 'react';
import { SVGProps, memo } from 'react';
const FeedbackMsgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20}
    height={17}
    fill='none'
    viewBox='0 0 20 17'
    {...props}
  >
    <path
      fill='#fff'
      fillRule='evenodd'
      d='M6.678.84a5.443 5.443 0 0 0 0 10.886h3.827c1.535 2.027 4.088 4.409 7.014 4.409-1.814-1.525-2.412-3.28-2.51-4.592A5.443 5.443 0 0 0 13.606.84H6.678Z'
      clipRule='evenodd'
    />
    <path
      fill='#7D7D7D'
      d='m10.505 11.726.204-.154-.077-.1h-.127v.254Zm7.014 4.409v.255h.7l-.536-.45-.164.195Zm-2.51-4.592-.065-.246-.204.054.015.211.255-.019ZM1.49 6.283a5.188 5.188 0 0 1 5.188-5.188v-.51A5.698 5.698 0 0 0 .98 6.284h.51Zm5.188 5.188A5.188 5.188 0 0 1 1.49 6.283H.98a5.698 5.698 0 0 0 5.698 5.698v-.51Zm3.827 0H6.678v.51h3.827v-.51Zm7.014 4.409c-1.393 0-2.712-.568-3.881-1.397-1.169-.83-2.173-1.911-2.93-2.91l-.406.307c.778 1.027 1.818 2.15 3.04 3.018 1.223.868 2.644 1.492 4.177 1.492v-.51Zm-2.764-4.318c.103 1.374.729 3.195 2.6 4.768l.328-.39c-1.756-1.477-2.326-3.165-2.42-4.415l-.508.037Zm4.039-5.279a5.19 5.19 0 0 1-3.85 5.014l.131.493a5.7 5.7 0 0 0 4.228-5.507h-.51Zm-5.189-5.188a5.188 5.188 0 0 1 5.189 5.188h.51A5.698 5.698 0 0 0 13.605.586v.51Zm-6.927 0h6.927v-.51H6.678v.51Z'
    />
    <path
      fill='#A9A9A9'
      d='M10.425 7.27c-.117-.022-.35-.033-.7-.033l.01-.639-.086-4.133.87-.071.185.175c-.013.116-.04.386-.08.808-.039.423-.075.918-.11 1.486a25.051 25.051 0 0 0-.051 1.523 12.933 12.933 0 0 0 .028.822l-.066.062Zm-.294 2.194c.183 0 .324-.056.421-.17.101-.116.152-.27.152-.459a.651.651 0 0 0-.152-.449c-.097-.113-.238-.17-.42-.17-.18 0-.32.057-.421.17a.651.651 0 0 0-.152.45c0 .198.05.353.152.463.1.11.24.165.42.165Z'
    />
  </svg>
);

export default memo(FeedbackMsgIcon);
