import { useEffect, useRef, useState } from "react";
import { useProfileData } from "./useProfileData";
import { indexPrefetch } from "../utils/api";

const enableDevTools = process.env.REACT_APP_ENABLE_DEVTOOLS === 'true';

export const usePreloadedIndex = () => {
  const [ preloaded, setPreloaded ] = useState(true);
  const isMounting = useRef<boolean>(false);
  const prefetchPromise = useRef<Promise<any>>(null);
  const { creatorProfileData } = useProfileData();

  useEffect(() => {
    isMounting.current = true;
  }, [])

  useEffect(() => {
    if (!isMounting.current) {
      if (creatorProfileData?.documentId) {
        if (enableDevTools) {
          console.log(`Starting prefetch process for ${creatorProfileData.documentId}`)
        }
        prefetchPromise.current = indexPrefetch(creatorProfileData.documentId)
          .then((res) => {
            if (res.ok) {
              return res.json()
            } else {
              throw res
            }
          })
          .then((data) => {
            if (enableDevTools) {
              console.log(data?.message, data?.status)
            }
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            prefetchPromise.current = null;
            setPreloaded(true)
          })
        setPreloaded(false);
      }
    } else {
      isMounting.current = false;
    }
  }, [creatorProfileData])

  return [preloaded, prefetchPromise.current];
}
