import * as React from 'react';
import { SVGProps, memo } from 'react';
const ThumbsUpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={22}
    height={18}
    viewBox='0 0 22 18'
    fill='none'
    {...props}
  >
    <path
      stroke='currentColor'
      strokeWidth={0.51}
      d='M4.599 6.837H1v10.616h3.599V6.837ZM6.218 6.955v8.348a2.15 2.15 0 0 0 2.15 2.15h8.175a.72.72 0 0 0 .66-.43l3.41-7.774a2.918 2.918 0 0 0-.85-3.451.182.182 0 0 0-.114-.04h-5.825a.72.72 0 0 1-.693-.914l.89-3.18a.72.72 0 0 0-.184-.702l-.453-.453a.72.72 0 0 0-1.018 0L6.43 6.447a.72.72 0 0 0-.211.508Z'
    />
  </svg>
);

export default memo(ThumbsUpIcon);
