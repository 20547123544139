import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import red from '@mui/material/colors/red';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import styles from './train.module.css';
import FormControl from '@mui/material/FormControl';

interface LevelQuestionItemType {
  levelQuestionItem: Record<string, any>;
  keyIndex: string;
  formErrors: Record<string, any>;
  formSubmitting: boolean;
  isLastItem?: boolean;
  saveUserQuestionAnswerData: (
    question: string,
    optionItem: string,
    isCustomAnswer?: boolean
  ) => void;
}

function LevelQuestionItem(props: LevelQuestionItemType) {
  const {
    keyIndex,
    levelQuestionItem,
    saveUserQuestionAnswerData,
    isLastItem,
    formSubmitting,
  } = props;
  const { question, options, userAnswers } = levelQuestionItem || {};

  // Handler for radio change
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    saveUserQuestionAnswerData(question, (event.target as HTMLInputElement).value);
  };

  return (
    <Box
      key={keyIndex}
      display='flex'
      flexDirection='column'
      flex={1}
      width='100%'
      px={6}
      py={2}
      className={!isLastItem && styles.levelQuestionItem}
    >
      <FormControl component="fieldset">
          <Typography variant={'subtitle1'} fontWeight={500} mb={1}>
            {question} <span style={{ color: red[700] }}>*</span>
          </Typography>
        <RadioGroup
          aria-label={question}
          name={`radio-buttons-group-${keyIndex}`}
          value={userAnswers}
          onChange={handleRadioChange}
        >
          {options?.map((optionItem, optionIndex) => (
            <FormControlLabel
              key={`ans-${optionIndex}`}
              value={optionItem}
              control={<Radio color="primary" disabled={formSubmitting} />}
              label={optionItem}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
}

export default React.memo(LevelQuestionItem);
